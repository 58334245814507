import Vue from 'vue'
import _ from 'lodash'
import { http, permits } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {
    amenities: []
  },
  totals: [],
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //get all locations (params: {_q: search name, page})
  ['locations.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('locations', {
      params
    })).then ((res) => {
      commit('GET_ALL_LOCATIONS', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  //get single location by id
  ['locations.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')

    // return;
    return (Promise.all([
      http.get(`locations/${id}`),
      // http.get(`locations/${id}/channels?_all=true`),
      // http.get(`locations/${id}/room-types?_all=true`),
      // http.get(`locations/${id}/parking-types?_all=true`),
      // http.get(`locations/${id}/blackout-dates?_all=true`),
      // http.get(`locations/${id}/fees?_all=true`),
    ])).then(([
      location/*, channels, roomTypes, parkingTypes, blackouts, fees*/
    ]) => {
      commit('GET_LOCATION', {
        location/*,
        channels,
        roomTypes,
        parkingTypes,
        blackouts,
        fees*/
      });
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    });
  },

  ['locations.getAmenities'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.get(`locations/${id}/amenities?_all=true`)).then ((res) => {
      commit('GET_LOCATION_AMENITIES', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.getTypes'] ({commit}, {id, params}) {
    commit('CLEAR_ERRORS');
    return (http.get(`locations/${id}/types`, {params})).then ((res) => {
      commit('LOCATIONS_GET_TYPES', res);
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },
  //update location
  ['locations.edit'] ({dispatch, commit, state}, {id, data, lang}) {
    commit('CLEAR_ERRORS');
    
    return (http.put(`locations/${id}?lang=${lang}`, data/*, config*/)).then ((res) => {
      commit('EDIT_LOCATION', {res, lang});
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },
  //sync locations
  ['locations.sync'] ({dispatch, commit}, params = {}) {
    commit('CLEAR_ERRORS');

    return (http.post(`locations/sync`)).then (() => {
      dispatch('locations.getAll', params).then((res) => {
        commit('GET_ALL_LOCATIONS', res);
      }).catch ((res) => {
        commit('LOCATIONS_ERROR', res);
      })
    }).catch ((res) => {
      commit('LOCATIONS_ERROR', res);
    })
  },

  ['locations.getMinGrandTotal'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS');
    return (http.post(`locations/${id}/min-price`, data)).then ((res) => {
      commit('LOCATIONS_GET_MIN_TOTAL', {res, id});
    })
    .catch ((error) => {      
      commit('LOCATIONS_GET_MIN_TOTAL_ERROR', {id, error: error.response});
    })
  },

  ['locations.clearCosts'] ({commit}) {
    commit('CLEAR_LOCATION_COSTS');
  },

  //fees
  ['locations.newFee']({commit}, data) {
    commit('CLEAR_ERRORS')
    return (http.post(`fees`, data)).then((res) => {
      commit('NEW_FEE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.updateFee'] ({commit}, {id, data, lang}) {
    commit('CLEAR_ERRORS')
    return (http.put(`fees/${id}?lang=${lang}`, data)).then((res) => {
      commit('UPDATE_LOCATION_FEE', {res, lang});
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  ['locations.removeFee']({commit}, {locId, id}) {
    commit('CLEAR_ERRORS')
    return (http.delete(`fees/${id}`)).then((res) => {
      commit('REMOVE_LOCATION_FEE', res);
    })
    .catch((err) => {
      commit('LOCATIONS_ERROR', err);
    });
  },

  // ['locations.remove'] ({commit}, id) {
  //   commit('CLEAR_ERRORS');
  //
  //   return (http.delete(`locations/${id}`)).then ((res) => {
  //     commit('REMOVE_LOCATION', res);
  //   }).catch ((res) => {
  //     commit('LOCATIONS_ERROR', res);
  //   })
  // },
  //
  // ['locations.undoRemove'] ({commit}, id) {
  //   commit('CLEAR_ERRORS');
  //
  //   return (http.delete(`locations/${id}?_undelete=true`)).then ((res) => {
  //     commit('UNDO_REMOVE_LOCATION', res);
  //   }).catch ((res) => {
  //     commit('LOCATIONS_ERROR', res);
  //   })
  // },

  // ['locations.add'] ({commit}, data) {
  //   commit('CLEAR_ERRORS');
  //
  //   return (http.post(`locations`, data)).then ((res) => {
  //     commit('NEW_LOCATION', res);
  //   }).catch ((res) => {
  //     commit('LOCATIONS_ERROR', res);
  //   })
  // },
}

// mutations
const mutations = {
  GET_ALL_LOCATIONS (state, res) {
    state.all = _.cloneDeep(res.data);
  },

  REMOVE_LOCATION (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_LOCATION (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_LOCATION (state, {location}) {
    state.active = location.data;
    // state.active.fees = fees.data;
    state.active = _.cloneDeep(state.active);
  },

  LOCATIONS_GET_TYPES (state, res) {
    state.active.types = res.data;
    state.active = _.cloneDeep(state.active)
  },
  
  LOCATIONS_GET_MIN_TOTAL (state, {id, res}) {
    state.totals.push(Object.assign({id}, res.data));
    console.log('state.totals', state.totals);
  },

  CLEAR_LOCATION_COSTS (state) {
    state.totals = _.cloneDeep([]);
  },

  GET_LOCATION_AMENITIES (state, amenities) {
    state.active.amenities = amenities.data;
    // state.active.fees = fees.data;
    state.active = _.cloneDeep(state.active);
  },

  EDIT_LOCATION (state, {res, lang}) {
    state.active = _.assign(state.active, res.data);

    let indx = _.findIndex(state.active.translations, (trans) => {
      if (!trans.language) return false;
      return trans.language.code == lang;
    });

    if(indx > -1) {
      state.active.translations[indx] = _.assign(state.active.translations[indx], res.data);
    } else {
      state.active.translations.push(res.data);
    }
    console.log('state.active.translations', state.active.translations);
    state.active = _.cloneDeep(state.active);
  },

  NEW_LOCATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_FEE (state, res) {
    state.active.fees.push(res.data);
    state.active = _.cloneDeep(state.active);
  },

  UPDATE_LOCATION_FEE (state, {res, lang}) {

    let indx = _.findIndex(state.active.fees, (item) => item.id == res.data.id);
    if(indx > -1) {
      state.active.fees[indx] = _.assign(state.active.fees[indx], res.data);

      let _indx = _.findIndex(state.active.fees[indx].translations, (trans) => {
        if (!trans.language) return false;
        return trans.language.code == lang;
      });

      if(_indx > -1) {
        state.active.fees[indx].translations[_indx] = _.assign(state.active.fees[indx].translations[_indx], res.data);
      } else {
        state.active.fees[indx].translations.push(res.data);
      }
    }

    state.active = _.cloneDeep(state.active);
  },

  REMOVE_LOCATION_FEE (state, res) {
    state.active.fees = state.active.fees.filter((item) => {
      return item.id !== res.data.id
    });
    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  LOCATIONS_ERROR (state, err) {
    console.log(err);
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  LOCATIONS_GET_MIN_TOTAL_ERROR (state, {id, error}) {
    console.log('error', error);
    error = error ? error.data.message : 'Unexpected Error'
    state.totals.push({id, error});
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
