var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "View FAQ" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _vm.faq
          ? _c(
              "table",
              {
                staticClass:
                  "table table-bordered margin-top-2x view-faq-modal--table"
              },
              [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Question")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.faq.question) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Answer")]),
                    _vm._v(" "),
                    _c("td", [
                      _c("div", {
                        domProps: {
                          innerHTML: _vm._s(_vm.cleanHtmlString(_vm.faq.answer))
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Category")]),
                    _vm._v(" "),
                    _vm.faq.category
                      ? _c("td", [_vm._v(_vm._s(_vm.faq.category.name))])
                      : _c("td", [_vm._v("--")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Language")]),
                    _vm._v(" "),
                    _vm.faq.category
                      ? _c("td", [
                          _vm.faq.category.language
                            ? _c("span", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.faq.category.language.name) +
                                    "  \n              "
                                )
                              ])
                            : _vm._e()
                        ])
                      : _c("td", [_vm._v("--")])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Order")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.faq.order_number + 1))])
                  ])
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-753fc215", { render: render, staticRenderFns: staticRenderFns })
  }
}