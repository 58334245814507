import layout from 'layouts/default'
import toastr from 'toastr'
import fileUpload from 'components/fileUpload'
import hasPermits from 'directives/hasPermits'

const filters = {

}

const directives = {
  hasPermits
}

const watch = {
}

const components = {
  fileUpload,
  layout
}

const methods = {
  submit () {
    let errors = false;
    this.errors = {};

    //validating totals
    let data = _.cloneDeep(this.form)
    this.errors = {};
    this.loading = true;
    this.submit_loading = true;
    this.$store.dispatch('carousels.edit', {
      data: data,
      image: this.image,
      id: this.$route.params.id,
    }).then(() => {
      this.loading = false;
      this.submit_loading = false;
      console.log('this.$store.state.carousels.errors',this.$store.state.carousels.errors)
      if (this.$store.state.carousels.errors) {
        this.errors = _.cloneDeep(this.$store.state.carousels.errors.errors);
        toastr.error(this.$store.state.carousels.errors.message);
      } else {
        toastr.success('Data has been updated');
        // this.$refs.file_upload.removeFiles();
        this.$router.push({name: 'carousels.all'})
      }
    });
  },
  
  imageToUpload (files) {
    if (files.length) {
      this.image = files;
    } else {
      this.image = [];
    }
  },

  getCarousel () {
    this.$store.dispatch('carousels.get', this.$route.params.id).then(() => {
      this.loading = false;
      this.form = _.cloneDeep(this.$store.state.carousels.active);
    });
  },

  wysiwygContentChanged: function(val) {
    this.form.text = val
  }
}

export default {
  name: 'edit-carousel',
  methods,
  components,
  watch,
  directives,
  filters,
  data() {
    return {
      errors: {},
      form: {},
      image: [],
      loading: false,
      submit_loading: false,
    }
  },
  created () {
    this.getCarousel();
  }
}
