import { funcModal } from 'components/modals'
import datepicker from 'components/datepicker'
import toastr from 'toastr'
import moment from 'moment'

const components = {
  funcModal,
  datepicker,
}

const computed = {

}

const watch = {
  formData (n, o) {
    if (n == o) return;

    this.form = n;
  }
}

const methods = {
  open () {
    this.$refs.notes.open();
  },
  resetForm () {
    this.note = {
      value: '',
      time: moment().format('YYYY-MM-DD 00:00:00'),
    };
  },
  submit (modal) {
    this.errors = {}
    this.submit_loading = true;
    let data = _.cloneDeep(this.formData);
    delete data.translations;
    console.log('note', this.note);
    console.log('data', data);
    data.note = _.clone(this.note);

    this.$store.dispatch('locations.edit', {
      id: this.$route.params.id,
      data: data,
      lang: this.lang
    }).then(() => {
      this.submit_loading = false;
      modal.close();
      if (this.$store.state.locations.errors) {
        this.errors = _.cloneDeep(this.$store.state.locations.errors.errors);
        toastr.error(this.$store.state.locations.errors.message);
        this.$emit('errorWhenSaveLocation', this.errors)
      } else {
        toastr.success('Data has been saved successfully!');
        this.$emit('errorWhenSaveLocation', {})
        this.resetForm();
      }
    })
  },
}

export default {
  name: "notes-form",
  props:['formData','lang'],
  components,
  methods,
  computed,
  data () {
    return {
      form: this.formData,
      note: {
        value: '',
        time: moment().format('YYYY-MM-DD 00:00:00'),
      },
      submit_loading:false,
    }
  }
}
