import { phone } from 'filters'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'

const filters = {
  phone
}

const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  confirm
}

const methods = {
  loadPage (page) {
    this.loading = true;
    this.$route.query.page = page;
    let data = {page, _q: this.searchKey};

    this.loading = true;

    if (this.filter == 'deleted') {
      data._only_deleted = 'true';
    } else if (this.filter == 'all') {
      data._with_deleted = 'true';
    }

    this.$store.dispatch('customers.getAll', data).then (() => {
      this.loading = false;
    });
  },
  search (searchKey) {
    this.searchKey = searchKey;
    this.loadPage(1);
  },
  removeCustomer (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.customers_loadings[item.id] = true

      this.$store.dispatch('customers.removeCustomer', item.id).then(() => {
        this.customers_loadings[item.id] = false

        if (this.$store.state.customers.errors) {
          this.errors = _.cloneDeep(this.$store.state.customers.errors.errors);
          if (this.errors.hasOwnProperty('message')) {
            toastr.error(this.errors.message);
          }
        }
      })
    });
  },
  undoRemove (item) {
    this.$refs.confirmUndoDelete.confirm(
    'Are you sure that you want to undelete this customer?',
    () => {
      this.$set(this.customers_loadings, item.id, true);

      this.$store.dispatch('customers.undoRemove', item.id).then(() => {
        this.customers_loadings[item.id] = false;
        console.log('this.$store.state.customers.errors', this.$store.state.customers.errors);
        let errors;
        if (errors = this.$store.state.customers.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },
  filterChanged () {
    console.log(this.filter);
    this.loadPage(1);
  }
}

const computed = {
  customers () {
    let customers = _.cloneDeep(this.$store.state.customers.all);
    _.each(customers.data, (customer) => {
      this.customers_loadings[customer.id] = false;
    });
    this.customers_loadings = _.clone(this.customers_loadings);
    return customers;
  }
}

export default {
  name: 'all-customers',
  components,
  methods,
  computed,
  filters,
  directives,
  data () {
    return {
      loading: true,
      searchKey: null,
      customers_loadings: {},
      filter: 'active'
    }
  },
  created () {
    this.loadPage (this.$route.query.page || 1);
  }
}
