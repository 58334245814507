import Vue from 'vue'
import _ from 'lodash'
import { http, permits } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {},
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //get all ports (params: {_q: search name, page})
  ['ports.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('airports', {
      params
    })).then ((res) => {
      commit('GET_ALL_PORTS', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  //get single location by id
  ['ports.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')


    // return;
    return (Promise.all([
      http.get(`airports/${id}`)
    ])).then(([
      port
    ]) => {
      commit('GET_PORT', port);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    });
  },

  //update port
  ['ports.edit'] ({dispatch, commit, state}, {id, data, lang}) {
    commit('CLEAR_ERRORS');

    return (http.put(`airports/${id}?lang=${lang}`, data)).then ((res) => {
      commit('EDIT_PORT', {res, lang});
    })
    .catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`airports/${id}`)).then ((res) => {
      commit('REMOVE_PORT', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`airports/${id}?_undelete=true`)).then ((res) => {
      commit('UNDO_REMOVE_PORT', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.add'] ({commit}, {lang, data}) {
    commit('CLEAR_ERRORS');

    return (http.post(`airports?lang=${lang}`, data)).then ((res) => {
      commit('NEW_PORT', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.uploadImages'] ({commit}, {id, files}) {
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append("images[]", files[i]);
      }
    }

    return (http.post(`airports/${id}/photos`, formData, config)).then ((res) => {
      commit('UPLOAD_IMAGES', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.removeImage'] ({commit}, {image}) {
    commit('CLEAR_ERRORS')
    if (_.isArray(image)) {
        _.forEach(image, (item) => {
          commit('REMOVE_IMAGE_FROM_PORT', item);
        });
    } else {
      commit('REMOVE_IMAGE_FROM_PORT', image);
    }

  },

  ['ports.setFeaturedImage'] ({commit}, {id, airport_id}) {
    commit('CLEAR_ERRORS')
    return (http.post(`airports/${airport_id}/featured/${id}`)).then ((res) => {
      commit('SET_IMAGE_AS_FEATURED', res);
    }).catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },

  ['ports.deleteImages'] ({dispatch, commit, state}, {id, photos}) {
    commit('CLEAR_ERRORS');

    return (http.post(`airports/${id}/removePhotos`, {
      photos: photos
    })).then ((res) => {})
    .catch ((res) => {
      commit('PORTS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_PORTS (state, res) {
    state.all = res.data;
  },

  REMOVE_PORT (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_PORT (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_PORT (state, res) {
    state.active = _.assign(state.active, res.data);

    state.active = _.cloneDeep(state.active);
  },

  EDIT_PORT (state, {res, lang}) {
    state.active = _.assign(state.active, res.data);

    let indx = _.findIndex(state.active.translations, (trans) => {
      console.log('trans', trans);
      if (!trans.language) return false;
      return trans.language.code == lang;
    });

    if(indx > -1) {
      state.active.translations[indx] = _.assign(state.active.translations[indx], res.data);
    } else {
      state.active.translations.push(res.data);
    }
    state.active = _.cloneDeep(state.active);
  },

  NEW_PORT (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  PORTS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  },

  UPLOAD_IMAGES (state, res) {
    state.active['photos'] = state.active['photos'].concat(res.data);
    state.active = _.cloneDeep(state.active);
  },

  SET_IMAGE_AS_FEATURED(state, res) {
    _.forEach(state.active.photos, (photo, i) => {
      state.active.photos[i].featured = 1;
      if (photo.id != res.data.id) {
        state.active.photos[i].featured = 0;
      }
    });

    state.active = _.cloneDeep(state.active);
  },

  REMOVE_IMAGE_FROM_PORT (state, image) {
    state.active['photos'] = state.active['photos'].filter((item) => item.id != image.id);
    state.active = _.cloneDeep(state.active);
  },


}

export default {
  state,
  getters,
  actions,
  mutations
}
