import { dateFormat, currencySymbol } from 'filters'

const filters = {
  dateFormat
}

const methods = {
  symbol (code) {
    return currencySymbol(code);
  },

  orderByActive () {
    return _.orderBy(_.cloneDeep(this.history), ['active', 'updated_at'], ['desc', 'desc']);
  }
}

export default {
  name: "reservation-history",
  props: ['history', 'cancelled'],
  filters,
  methods,
  data () {
    return {}
  }
}
