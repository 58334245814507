var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "page-header" }, [
      !_vm.loading
        ? _c("h1", [_c("small", [_vm._v("New FAQ Category")])])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("form", {}, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermits",
                  rawName: "v-hasPermits:allow",
                  value: ["faqcategory_create"],
                  expression: "['faqcategory_create']",
                  arg: "allow"
                }
              ],
              staticClass: "text-right"
            },
            [
              _vm.submit_loading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { disabled: _vm.submit_loading, type: "button" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                      _vm._v("\n        SAVING...\n      ")
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmitForm($event)
                        }
                      }
                    },
                    [_vm._v("\n        SAVE\n      ")]
                  )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row margin-top-4x" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "faq-question" } }, [
                  _vm._v("Name")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.form.name,
                      expression: "form.name",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "faq-name",
                    placeholder: "FAQ Category name"
                  },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "name", $event.target.value.trim())
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.name
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.name[0]) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Language")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.lang_id,
                        expression: "form.lang_id"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: { placeholder: "language" },
                    on: {
                      change: function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.form,
                          "lang_id",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      }
                    }
                  },
                  _vm._l(_vm.languages, function(language) {
                    return _c("option", { domProps: { value: language.id } }, [
                      _vm._v(_vm._s(language.name))
                    ])
                  })
                ),
                _vm._v(" "),
                _vm.errors.lang_id
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.lang_id[0]) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4206d05c", { render: render, staticRenderFns: staticRenderFns })
  }
}