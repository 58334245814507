import { http } from 'services'
// initial state
const state = () => ({
  all: [],
  active: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['users.getAll'] ({ commit }, params) {
    if (!params._q) params._q = null

    commit('CLEAR_ERRORS')

    return (http.get('users', {
      params
    })).then ((res) => {
      commit('GET_ALL_USERS', res);
    })
    .catch ((res) => {
      commit('USERS_ERROR', res);
    })
  },
  ['users.newUser'] ({ commit }, data) {
    commit('CLEAR_ERRORS')

    return (http.post('users', data))
    .then ((res) => {
      // commit('NEW_USER', res);
    })
    .catch ((res) => {
      commit('USERS_ERROR', res);
    })
  },
  ['users.editUser'] ({ commit }, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put('users/' + id , data))
    .then ((res) => {
      commit('EDIT_USER', res);
    })
    .catch ((res) => {
      commit('USERS_ERROR', res);
    })
  },
  ['users.removeUser'] ({ commit }, id) {
    commit('CLEAR_ERRORS')

    return (http.delete('users/' + id))
    .then ((res) => {
      commit('REMOVE_USER', id);
    })
    .catch ((res) => {
      commit('USERS_ERROR', res);
    })
  },

  ['users.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`users/${id}?_undelete=true`)).then ((res) => {
      commit('UNDO_REMOVE_USER', res);
    }).catch ((res) => {
      commit('COUPONS_ERROR', res);
    })
  },

  ['users.getUser'] ({ dispatch, commit, state }, id) {
    commit('CLEAR_ERRORS')
    // return dispatch('roles.getAll', {_all: true}).then(() => {
      return (http.get('users/' + id))
      .then ((res) => {
        commit('GET_USER', res);
      })
      .catch ((res) => {
        commit('USERS_ERROR', res);
      })
    // })
  },
  ['users.getUserByEmail'] ({ dispatch, commit, state }, email) {
    commit('CLEAR_ERRORS')
    return (http.get('users/get', {
      params: {email}
    }))
    .then ((res) => {
      commit('GET_USER', res);
    })
    .catch ((res) => {
      commit('USERS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_USERS (state, res) {

    state.all = res.data;
  },

  GET_USER (state, res) {
    state.active = res.data
  },

  REMOVE_USER (state, id) {
    state.all.data = _.filter(state.all.data, (item) => item.id != id);
  },

  UNDO_REMOVE_USER (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },
  
  EDIT_USER (state, res) {
    state.active = res.data
  },
  // NEW_USER (state, res) {
  //   // state.all.data =
  // },

  //ERRORS
  USERS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
