import axios from 'axios'
import env from 'constants/env'
import user from './user'


// let promise;
let instance = axios.create({
  baseURL: isAffiliate ? env.default.affiliateApi : env.default.api
});

instance.interceptors.request.use((config) => {
  if (user.getToken()) {
    config.headers.common['Authorization'] = "Bearer " + user.getToken();
  }
  return config;
});


let refreshing = false;
let refreshRequests = [];
instance.interceptors.response.use((response) => {
  // Do something with response data
  // console.log('response', response);
  return response;
}, function (error) {
  if (isServer) {
    return Promise.resolve({
      data: {
        // data: []
      }
    });
  }

  // Do something with response error
  if ([401].indexOf(error.response.status) > -1) {
    let error_code = error.response.data.error_code;
    if(!_.isEmpty(error_code) && error_code !== 'invalid_credentials') {
    
      const { config, response: { status } } = error;
      const originalRequest = config;
      if (!refreshing) {
        refreshToken(originalRequest)
                    .then(newToken => {
                      refreshing = false;
                      onRrefreshed(newToken);
                    });
      }
      const retryOrigReq = new Promise((resolve, reject) => {
        subscribeTokenRefresh(token => {
          // replace the expired token and retry
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          resolve(axios(originalRequest));
        });
      });
      return retryOrigReq;

    }
  } else if ([403].indexOf(error.response.status) > -1) {
    // router.replace({
    //   name: 'home'
    // });
    return Promise.resolve({
      data: {
        // data: []
      }
    });
  }
  return Promise.reject(error);
});

function refreshToken (config) {
  refreshing = true;
  return instance.post('users/auth/refresh-token').then((res) => {
    refreshing = false;

    // console.log('res.data.newToken', res.data.newToken);
    user.setToken(res.data.newToken);
    return Promise.resolve(res.data.newToken);
  })
  .catch((error) => {
    refreshing = false;

    if (!isServer) {
      location.reload();
    }

    return Promise.reject(error);
  })
}

function subscribeTokenRefresh(cb) {
  refreshRequests.push(cb);
}

function onRrefreshed(token) {
  refreshRequests.map(cb => cb(token));
  refreshRequests = [];
}

export default instance
// export default axios
