var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "three-d-secure-container" }, [
    _c(
      "div",
      {
        staticClass: "close-3d-secure",
        on: {
          click: function($event) {
            _vm.close()
          }
        }
      },
      [
        _c("i", {
          staticClass: "fa fa-long-arrow-left",
          attrs: { "aria-hidden": "true" }
        }),
        _vm._v("\n    Cancel Payment\n  ")
      ]
    ),
    _vm._v(" "),
    _c("iframe", { attrs: { src: _vm.url, width: "100%", height: "100%" } })
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-0ff2bdf9", { render: render, staticRenderFns: staticRenderFns })
  }
}