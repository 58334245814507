var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "btn btn-default",
      attrs: { href: "#" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.goBack($event)
        }
      }
    },
    [
      _c("i", {
        staticClass: "fa fa-angle-left",
        attrs: { "aria-hidden": "true" }
      }),
      _vm._v("\n    back\n")
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7733573d", { render: render, staticRenderFns: staticRenderFns })
  }
}