import { http } from 'services'

// initial state
const state = () => ({
  all: [],
  active: {},
  errors: null,
  locations: {},
  cost: null,
  refund_list: [],
  bundle_costs: [],
  parking_costs: [],
  room_costs: [],
  combination_costs: [],
})

// getters
const getters = {

}
let counter = 1;
// actions
const actions = {
  //get all reservations (params: {_q: search name, page, done})
  ['reservations.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('reservations', {
      params
    })).then ((res) => {
      commit('GET_ALL_RESERVATIONS', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  //get single reservations by id
  ['reservations.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')


    // return;
    return (Promise.all([
      http.get(`reservations/${id}`)
    ])).then(([
      port
    ]) => {
      commit('GET_RESERVATION', port);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    });
  },


  //update reservation
  ['reservations.edit'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`reservations/${id}`, data)).then ((res) => {
      commit('EDIT_RESERVATION', {res});
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`reservations/${id}`)).then ((res) => {
      commit('EDIT_RESERVATION', {res});
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.revert_remove'] ({commit}, data) {
    commit('CLEAR_ERRORS');
    const id = data.id;

    return (http.put(`reservations/${id}/revert-cancel`,data)).then ((res) => {
      commit('EDIT_RESERVATION', {res});
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.add'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`reservations`, data)).then ((res) => {
      commit('NEW_RESERVATION', res);
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.locations'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    let url = `search/locations`;

    if (data.reservation_id) {
      url = url + "/" + data.reservation_id
    }

    return (http.post(url, data)).then ((res) => {
      commit('LOCATIONS', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.clearCosts'] ({commit}) {
    commit('CLEAR_RESERVATION_COSTS');
  },

  ['reservations.cost'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    let url = `search/cost`;

    if (data.reservation_id) {
      url = url + "/" + data.reservation_id
    }

    return (http.post(url, data)).then ((res) => {
      commit('CLEAR_ERRORS');
      commit('GET_COST', {res, data});
    })
    .catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.sendToEmail'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.post('reservations/' + id + '/send-to-emails', data)).then ((res) => {
      commit('SEND_TO_EMAIL', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.partialRefund'] ({commit}, {id, data}) {
    commit('CLEAR_ERRORS');

    return new Promise((resolve, reject) => {
      http.post('reservations/partial-refund/' + id, data).then((res) => {
        commit('PARTIAL_REFUND', res);
        resolve(res);
      })
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.getRefundList'] ({commit}, {id}) {
    commit('CLEAR_ERRORS');

    return (http.post('reservations/refund/' + id)).then ((res) => {
      commit('REFUND_LIST', res);
    }).catch ((res) => {
      commit('RESERVATIONS_ERROR', res);
    })
  },

  ['reservations.clear_active'] ({commit}) {
    commit('CLEAR_ACTIVE');
  }
}

// mutations
const mutations = {
  GET_ALL_RESERVATIONS (state, res) {
    state.all = res.data;
  },

  GET_RESERVATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  PARTIAL_REFUND(state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  EDIT_RESERVATION (state, {res}) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  SEND_TO_EMAIL (state, {res}) {
  },

  NEW_RESERVATION (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  LOCATIONS(state, res) {
    state.locations = _.cloneDeep(res.data);
  },
  
  GET_COST(state, {res, data}) {
    if (data.finalCost) {
      state.cost = _.cloneDeep(res.data);
    } else {
      res.data.request = data;
      if (data.parking_type && data.room_type) {
        state.combination_costs = state.combination_costs.concat([res.data]);
      } else if (data.bundle_type) {
        state.bundle_costs = state.bundle_costs.concat([res.data]);
      } else if (data.parking_type) {
        state.parking_costs = state.parking_costs.concat([res.data]);
      } else if (data.room_type) {
        state.room_costs = state.room_costs.concat([res.data]);
      }
    }

  },

  CLEAR_RESERVATION_COSTS (state) {
    state.bundle_costs = _.cloneDeep([]);
    state.parking_costs = _.cloneDeep([]);
    state.room_costs = _.cloneDeep([]);
    state.combination_costs = _.cloneDeep([]);
  },

  REFUND_LIST (state, res) {
    state.refund_list = _.cloneDeep(res.data);
  },
  //ERRORS
  RESERVATIONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  },

  CLEAR_ACTIVE (state) {
    state.active = {}
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
