var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "page-header" }, [
      !_vm.loading
        ? _c("h1", [_c("small", [_vm._v("New Trip Protection")])])
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("form", {}, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermits",
                  rawName: "v-hasPermits:allow",
                  value: ["tripprotection_create"],
                  expression: "['tripprotection_create']",
                  arg: "allow"
                }
              ],
              staticClass: "text-right"
            },
            [
              _vm.submit_loading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { disabled: _vm.submit_loading, type: "button" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                      _vm._v("\n        SAVING...\n      ")
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmitFom($event)
                        }
                      }
                    },
                    [_vm._v("\n        SAVE\n      ")]
                  )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row margin-top-4x" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "TripProtectionTitle" } }, [
                  _vm._v("Title")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.form.name,
                      expression: "form.name",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "TripProtectionTitle",
                    placeholder: "Trip Protection Title"
                  },
                  domProps: { value: _vm.form.name },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "name", $event.target.value.trim())
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.name
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.name[0]) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "number_of_hours" } }, [
                      _vm._v("Number Of Hours")
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.trim",
                          value: _vm.form.number_of_hours,
                          expression: "form.number_of_hours",
                          modifiers: { trim: true }
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        type: "text",
                        id: "number_of_hours",
                        placeholder: "Number Of Hours"
                      },
                      domProps: { value: _vm.form.number_of_hours },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.form,
                            "number_of_hours",
                            $event.target.value.trim()
                          )
                        },
                        blur: function($event) {
                          _vm.$forceUpdate()
                        }
                      }
                    }),
                    _vm._v(" "),
                    _vm.errors.number_of_hours
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.errors.number_of_hours[0]) +
                              "\n              "
                          )
                        ])
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Prices")]),
                _vm._v(" "),
                _c("table", { staticClass: "table" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Currency Code")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Price")]),
                      _vm._v(" "),
                      _c("th")
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.form.trip_protection_prices, function(item, i) {
                      return _c("tr", [
                        _c("td", [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.form.trip_protection_prices[i]
                                      .currency_id,
                                  expression:
                                    "form.trip_protection_prices[i].currency_id"
                                }
                              ],
                              staticClass: "form-control",
                              on: {
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.form.trip_protection_prices[i],
                                    "currency_id",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", { domProps: { value: null } }, [
                                _vm._v("Select Currency")
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.currencies, function(curr) {
                                return _c(
                                  "option",
                                  { domProps: { value: curr.id } },
                                  [
                                    _vm._v(
                                      _vm._s(curr.name + " - " + curr.code)
                                    )
                                  ]
                                )
                              })
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _vm.errors[
                            "trip_protection_prices." + i + ".currency_id"
                          ]
                            ? _c("div", { staticClass: "text-danger" }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm.errors[
                                        "trip_protection_prices." +
                                          i +
                                          ".currency_id"
                                      ][0]
                                    ) +
                                    "\n                  "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.form.trip_protection_prices[i].price,
                                expression:
                                  "form.trip_protection_prices[i].price"
                              }
                            ],
                            staticClass: "form-control",
                            domProps: {
                              value: _vm.form.trip_protection_prices[i].price
                            },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.form.trip_protection_prices[i],
                                  "price",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          i > 0
                            ? _c(
                                "a",
                                {
                                  staticClass: "btn btn-danger",
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.removePrice(i)
                                    }
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-trash",
                                    attrs: { "aria-hidden": "true" }
                                  })
                                ]
                              )
                            : _vm._e()
                        ])
                      ])
                    })
                  )
                ]),
                _vm._v(" "),
                _vm.errors.currency_id
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.currency_id[0]) +
                          "\n          "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.createPrice($event)
                        }
                      }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-plus",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v("\n              Add another price\n            ")
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "short_description" } }, [
                    _vm._v("Short Description")
                  ]),
                  _vm._v(" "),
                  _c("vue-html5-editor", {
                    attrs: { height: 100, content: "" },
                    on: { change: _vm.wysiwygShortDescriptionChanged }
                  }),
                  _vm._v(" "),
                  _vm.errors.short_description
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.short_description[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "seo_keywords" } }, [
                    _vm._v("Description")
                  ]),
                  _vm._v(" "),
                  _c("vue-html5-editor", {
                    attrs: { content: "" },
                    on: { change: _vm.wysiwygDescriptionChanged }
                  }),
                  _vm._v(" "),
                  _vm.errors.description
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.description[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bcea7bdc", { render: render, staticRenderFns: staticRenderFns })
  }
}