var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row margin-top-4x" }, [
    _c("div", { staticClass: "col-sm-6" }, [
      _c("h3", { staticClass: "text-info" }, [_vm._v("GENERAL INFORMATION")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-9" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "portName" } }, [_vm._v("Name")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.name,
                  expression: "form.name",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "portName", placeholder: "Port Name" },
              domProps: { value: _vm.form.name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "name", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.name
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.name[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-3" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "timezone" } }, [_vm._v("Port Type")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.type,
                    expression: "form.type",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { placeholder: "type" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "type",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { attrs: { value: "airport" } }, [
                  _vm._v("Airport")
                ]),
                _vm._v(" "),
                _c("option", { attrs: { value: "seaport" } }, [
                  _vm._v("Seaport")
                ])
              ]
            ),
            _vm._v(" "),
            _vm.errors.type
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.type[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "code" } }, [_vm._v("Code")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.code,
                  expression: "form.code",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "code", placeholder: "Airport Code" },
              domProps: { value: _vm.form.code },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "code", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.code
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.code[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "latitude" } }, [_vm._v("Latitude")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.latitude,
                  expression: "form.latitude",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "latitude",
                placeholder: "Geolocation latitude"
              },
              domProps: { value: _vm.form.latitude },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "latitude", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.latitude
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.latitude[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "city" } }, [_vm._v("Longitude")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.longitude,
                  expression: "form.longitude",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "longitude",
                placeholder: "Geolocation longitude"
              },
              domProps: { value: _vm.form.longitude },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "longitude", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.longitude
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.longitude[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "url" } }, [_vm._v("Friendly URL")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.url,
                  expression: "form.url",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "url",
                placeholder: "SEO Friendly URL"
              },
              domProps: { value: _vm.form.url },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "url", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.url
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.url[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "timezone" } }, [_vm._v("Timezone")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.timezone_id,
                    expression: "form.timezone_id",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { placeholder: "Timezone" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "timezone_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.timezones, function(timezone) {
                return _c("option", { domProps: { value: timezone.id } }, [
                  _vm._v(_vm._s(timezone.name))
                ])
              })
            ),
            _vm._v(" "),
            _vm.errors.timezone_id
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.timezone_id[0]))
                ])
              : _vm._e()
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-6" }, [
      _c("h3", { staticClass: "text-info" }, [_vm._v("PORT ADDRESS")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "country" } }, [_vm._v("Country")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.country_id,
                    expression: "form.country_id",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { placeholder: "Country" },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.form,
                        "country_id",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    _vm.onChangeCountry
                  ]
                }
              },
              _vm._l(_vm.countries, function(country) {
                return _c("option", { domProps: { value: country.id } }, [
                  _vm._v(_vm._s(country.name))
                ])
              })
            ),
            _vm._v(" "),
            _vm.errors.country_id
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.country_id[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", [_vm._v("State")]),
            _vm._v(" "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.state_id,
                    expression: "form.state_id",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { placeholder: "State" },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.form,
                      "state_id",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              _vm._l(_vm.states, function(state) {
                return _c("option", { domProps: { value: state.id } }, [
                  _vm._v(_vm._s(state.name))
                ])
              })
            ),
            _vm._v(" "),
            _vm.errors.state_id
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.state_id[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "city" } }, [_vm._v("City")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.city,
                  expression: "form.city",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", id: "city", placeholder: "City Name" },
              domProps: { value: _vm.form.city },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "city", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.city
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.city[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "city" } }, [_vm._v("Address")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.address,
                  expression: "form.address",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "address",
                placeholder: "Street Address"
              },
              domProps: { value: _vm.form.address },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "address", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.address
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.address[0]))
                ])
              : _vm._e()
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.editMode
        ? _c("div", [
            _c("h3", { staticClass: "text-info" }, [_vm._v("PHOTOS")]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "well port-custom-well-box" },
                  [
                    _c(
                      "file-upload",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["airport_photos_create"],
                            expression: "['airport_photos_create']",
                            arg: "allow"
                          }
                        ],
                        ref: "file_upload",
                        attrs: { multiple: true, accept: "image/*" },
                        on: { filesChanged: _vm.imagesToUpload }
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success",
                            class: { disabled: _vm.files_processing },
                            attrs: { disabled: _vm.files_processing },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.uploadImages($event)
                              }
                            }
                          },
                          [
                            _vm.files_processing
                              ? _c("span", [
                                  _vm.files_processing
                                    ? _c("i", {
                                        staticClass:
                                          "fa fa-spinner fa-spin fa-fw"
                                      })
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm._v("\n                Upload\n              ")
                          ]
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _vm.errors.images
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(_vm._s(_vm.errors.images[0]))
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.photos.length
                      ? _c("div", { staticClass: "margin-top-2x" }, [
                          _vm.need_to_save
                            ? _c("div", { staticClass: "text-warning" }, [
                                _c("strong", [
                                  _vm._v(
                                    "When removing an image, you need to hit save"
                                  )
                                ])
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "ul",
                            { staticClass: "images-list" },
                            _vm._l(_vm.photos, function(photo) {
                              return _c("li", [
                                _c("img", {
                                  staticClass: "pointer",
                                  class: {
                                    "port-featured-photo": photo.featured
                                  },
                                  attrs: {
                                    src: photo.filename,
                                    alt: "",
                                    "data-toggle": "tooltip",
                                    title: "Make this photo featured ?"
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.setFeatured(photo)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    directives: [
                                      {
                                        name: "hasPermits",
                                        rawName: "v-hasPermits:allow",
                                        value: ["airport_photos_delete"],
                                        expression: "['airport_photos_delete']",
                                        arg: "allow"
                                      }
                                    ],
                                    staticClass: "remove text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.removePhoto(photo)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-times" })]
                                )
                              ])
                            })
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.need_to_save
                      ? _c(
                          "div",
                          { staticClass: "padding-top-2x text-right" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success",
                                class: { disabled: _vm.processing },
                                attrs: { disabled: _vm.processing },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.submit()
                                  }
                                }
                              },
                              [
                                _vm.processing
                                  ? _c("span", [
                                      _c("i", {
                                        staticClass:
                                          "fa fa-spinner fa-spin fa-fw"
                                      })
                                    ])
                                  : _vm._e(),
                                _vm._v("\n                Save\n              ")
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-12" }, [
      _c("h3", { staticClass: "text-info" }, [_vm._v("DESCRIPTION")]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-6" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", { attrs: { for: "portDescription" } }, [
                _vm._v("Description")
              ]),
              _vm._v(" "),
              _c("vue-html5-editor", {
                attrs: {
                  id: "portDescription",
                  height: 100,
                  content: _vm.form.description || ""
                },
                on: {
                  change: function(x) {
                    return (_vm.form.description = x)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.description
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(_vm._s(_vm.errors.description[0]))
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "city" } }, [_vm._v("Head Title")]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.head_title,
                  expression: "form.head_title",
                  modifiers: { trim: true }
                }
              ],
              ref: "desc",
              staticClass: "form-control",
              domProps: { value: _vm.form.head_title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "head_title", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.head_title
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.head_title[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "city" } }, [
              _vm._v("Head Description")
            ]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.head_description,
                  expression: "form.head_description",
                  modifiers: { trim: true }
                }
              ],
              ref: "desc",
              staticClass: "form-control",
              domProps: { value: _vm.form.head_description },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form,
                    "head_description",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.head_description
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.head_description[0]))
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-6" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "city" } }, [_vm._v("Head Keywords")]),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.head_keywords,
                  expression: "form.head_keywords",
                  modifiers: { trim: true }
                }
              ],
              ref: "desc",
              staticClass: "form-control",
              domProps: { value: _vm.form.head_keywords },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.form,
                    "head_keywords",
                    $event.target.value.trim()
                  )
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.head_keywords
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.errors.head_keywords[0]))
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-4fdd0636", { render: render, staticRenderFns: staticRenderFns })
  }
}