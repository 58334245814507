var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "notes",
      attrs: { title: "Add Notes", backdrop: false },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _vm.submit_loading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { disabled: _vm.submit_loading, type: "button" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                      _vm._v("\n      SAVING...\n    ")
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.submit(props)
                        }
                      }
                    },
                    [_vm._v("\n      Submit\n    ")]
                  ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { attrs: { for: "note" } }, [_vm._v("Note")]),
            _vm._v(" "),
            _c("vue-html5-editor", {
              attrs: { id: "note", height: 200, content: _vm.note.value || "" },
              on: {
                change: function(x) {
                  return (_vm.note.value = x)
                }
              }
            })
          ],
          1
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-028ecd80", { render: render, staticRenderFns: staticRenderFns })
  }
}