import layout from 'layouts/default'
import pagination from 'components/pagination'
import confirm from 'components/modals/confirm'
import editModal from 'pages/amenities/edit'
import hasPermits from 'directives/hasPermits'

const filters = {

}

const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  confirm,
  editModal
}

const computed = {
  amenities () {
    return _.cloneDeep(this.$store.state.amenities.all);
  }
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    let data = {page, _q: this.searchKey};

    this.loading = true;

    this.$store.dispatch('amenities.getAll', data).then(() => {
      this.loading = false;
    });
  },

  search (searchKey) {
    this.searchKey = searchKey;
    this.loadPage(1);
  },

  syncAmenities () {
    this.$route.query.page = 1;
    let data = {page: 1, _q: this.searchKey};
    this.syncing = true;

    this.$store.dispatch('amenities.sync').then(() => {
      this.$store.dispatch('amenities.getAll', data).then(() => {
        this.syncing = false;
      })
    });
  },

  editAmenity (id) {

    this.active_amenity = this.amenities.data.filter((amenity) => {
      return amenity.id == id;
    })[0];
    this.open();
  },

  open () {
    this.toggle_modal = !this.toggle_modal ;
    // this.$refs.modal.open();
  },
  close () {
    this.toggle_modal = false;
  }
}

const data = {
  loading: false,
  searchKey: null,
  active_amenity: {},
  toggle_modal: false,
  loadings: {},
  syncing: false,
}

export default {
  name: 'all-amenities',
  components,
  computed,
  methods,
  directives,
  filters,
  data: () => data,
  created () {
    this.loadPage(this.$route.query.page || 1);
  }
}
