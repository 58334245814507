import popover from 'components/popover'
import { currencySymbol as currencySymbolFilter} from 'filters'
const components = {
  popover
}

const methods = {
  applyDiscountCode () {
    this.$emit('applyDiscountCode', this.coupon_code);
  },
  removeCoupon () {
    this.$emit('removeCoupon');
  },
}
const filters = {
  currencySymbolFilter
}
const computed = {
  
  currentCurrencySymbol () {
    return !this.selected_currency ? this.currencySymbol : currencySymbolFilter(this.selected_currency);
  }
}

const watch = {
  couponCode (n, o) {
    if (n == o) return;
    this.coupon_code = n;
  },
  selectedCurrency (n, o) {
    if (n == o) return;
    this.selected_currency = this.selectedCurrency;
  }
}

export default {
  name: 'calculations',
  props: [
    'cost', 'costLoading', 'activeHistory',
    'reservation', 'couponCode', 'couponSuccess',
    'couponError', 'numberOfRooms','currencySymbol',"reservationType",
    'selectedCurrency',
  ],
  components,
  methods,
  watch,
  computed,
  filters,
  data () {
    return {
      coupon_code: this.couponCode,
      selected_currency: this.selectedCurrency,
    }
  }
}
