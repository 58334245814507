import starRating from 'vue-star-rating'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const filters = {
}

const directives = {
  hasPermits
}

const components = {
  layout,
  starRating
}

const methods = {
  updateStatus (review, status) {
    let data = _.clone(review)
    data.published = status

    this.$store.dispatch('reviews.editReview', {id: review.id, data: data}).then (() => {
      review.published = status
    });
  }
}

const computed = {
  review () {
    return _.cloneDeep(this.$store.state.reviews.active);
  }
}

export default {
  name: 'edit-review',
  components,
  methods,
  computed,
  filters,
  directives,
  data () {
    return {
      loading: true
    }
  },
  created () {
    this.$store.dispatch('reviews.getReview', this.$route.params.id).then (() => {
      this.loading = false;
    });
  }
}
