var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "page-header" }, [
      _c("h1", [_c("small", [_vm._v("New Carousel")])])
    ]),
    _vm._v(" "),
    _c("form", [
      _c(
        "div",
        {
          directives: [
            {
              name: "hasPermits",
              rawName: "v-hasPermits:allow",
              value: ["carousel_create"],
              expression: "['carousel_create']",
              arg: "allow"
            }
          ],
          staticClass: "text-right"
        },
        [
          _vm.submit_loading
            ? _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { disabled: _vm.submit_loading, type: "button" }
                },
                [
                  _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                  _vm._v("\n        SAVING...\n      ")
                ]
              )
            : _c(
                "button",
                {
                  staticClass: "btn btn-success",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.submit($event)
                    }
                  }
                },
                [_vm._v("\n        SAVE\n      ")]
              )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row margin-top-4x" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "carouselTitle" } }, [_vm._v("Title")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.title,
                  expression: "form.title",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "carouselTitle",
                placeholder: "Carousel Title"
              },
              domProps: { value: _vm.form.title },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "title", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.title
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.errors.title[0]) +
                      "\n          "
                  )
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12 margin-top-2x" },
          [
            _c("label", { attrs: { for: "slug" } }, [_vm._v("Text")]),
            _vm._v(" "),
            _c("vue-html5-editor", {
              attrs: { content: _vm.form.text },
              on: { change: _vm.wysiwygContentChanged }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm-12 margin-top-2x" },
          [
            _c("label", { attrs: { for: "slug" } }, [_vm._v("Image")]),
            _vm._v(" "),
            _c("file-upload", {
              ref: "file_upload",
              attrs: { multiple: false, accept: "image/*" },
              on: { filesChanged: _vm.imageToUpload }
            }),
            _vm._v(" "),
            _vm.errors.image
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.errors.image[0]) + "\n        "
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-12 margin-top-2x" }, [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "link" } }, [_vm._v("Link")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model.trim",
                  value: _vm.form.link,
                  expression: "form.link",
                  modifiers: { trim: true }
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "link",
                placeholder: "https://www.domain.com"
              },
              domProps: { value: _vm.form.link },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "link", $event.target.value.trim())
                },
                blur: function($event) {
                  _vm.$forceUpdate()
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.link
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.errors.link[0]) +
                      "\n          "
                  )
                ])
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2b17bd53", { render: render, staticRenderFns: staticRenderFns })
  }
}