import { funcModal } from 'components/modals'
const components = {
  funcModal,
}

const computed = {
  faqCateogry () {
    return this.$store.state.faqCategories.active;
  },
}

const filters = {
}

const methods = {
  open () {
    this.$refs.modal.open();
  },
}

export default {
  name: "view-faq-category-modal",
  components,
  methods,
  computed,
  filters,
  data () {
    return {}
  }
}
