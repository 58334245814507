import layout from 'layouts/default'
import { hasPermits } from 'directives'
import toastr from 'toastr'

const components = {
  layout,
}

const directives = {
  hasPermits
}

const computed = {
  languages () {
    return _.cloneDeep(this.$store.state.multilingual.langs);
  },

  formData () {
    return _.cloneDeep(this.$store.state.faqCategories.active);
  },
}


const methods = {
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('faqCategories.edit', {
      id: this.$route.params.id,
      data: this.formData,
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.faqCategories.errors) {
        this.errors = _.cloneDeep(this.$store.state.faqCategories.errors.errors);
        toastr.error(this.$store.state.faqCategories.errors.message);
      } else {
        toastr.success('Faq Category has been updated!');
      }
    })
  },
 
}

export default {
  name: 'edit-faq-category',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      errors: {}
    }
  },
  created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('multilingual.getLangs'),
      this.$store.dispatch('faqCategories.get', this.$route.params.id)
    ]).then(() => {
      this.loading = false;
    })
  }
}
