var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row margin-top-4x" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.notes.length
      ? _c("div", [
          _c("table", { staticClass: "table table-striped" }, [
            _vm._m(1),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.notes, function(note) {
                return _c("tr", [
                  _c("td", { domProps: { innerHTML: _vm._s(note.value) } }),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.toDate(note.time)) +
                        "\n            "
                    )
                  ])
                ])
              })
            )
          ])
        ])
      : _c("div", { staticClass: "text-warning col-sm-12" }, [
          _vm._v("\n      There are no notes yet\n    ")
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-12" }, [
      _c("h3", { staticClass: "text-info" }, [_vm._v("NOTES HISTORY")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" Description ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" Date ")])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d49944fa", { render: render, staticRenderFns: staticRenderFns })
  }
}