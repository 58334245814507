var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "page-header" }, [
      !_vm.loading ? _c("h1", [_c("small", [_vm._v("New Coupon")])]) : _vm._e()
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("form", {}, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "hasPermits",
                  rawName: "v-hasPermits:allow",
                  value: ["coupon_create"],
                  expression: "['coupon_create']",
                  arg: "allow"
                }
              ],
              staticClass: "text-right"
            },
            [
              _vm.submit_loading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { disabled: _vm.submit_loading, type: "button" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                      _vm._v("\n        SAVING...\n      ")
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onSubmitFom($event)
                        }
                      }
                    },
                    [_vm._v("\n        SAVE\n      ")]
                  )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row margin-top-4x" }, [
            _c("div", { staticClass: "col-sm-8 col-sm-offset-2" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Coupon Code")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.form.code,
                      expression: "form.code",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Coupon Code" },
                  domProps: { value: _vm.form.code },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "code", $event.target.value.trim())
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.code
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.code[0]) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v("Start Date")]),
                  _vm._v(" "),
                  _c("datepicker", {
                    staticClass: "form-control",
                    attrs: { type: "text", placeholder: "--/--/----" },
                    model: {
                      value: _vm.form.start_date,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "start_date",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.start_date"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.start_date
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.start_date[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", [_vm._v("Expiry Date")]),
                  _vm._v(" "),
                  _c("datepicker", {
                    staticClass: "form-control",
                    attrs: {
                      "min-date": _vm.form.start_date,
                      type: "text",
                      placeholder: "--/--/----"
                    },
                    model: {
                      value: _vm.form.expiry_date,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.form,
                          "expiry_date",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "form.expiry_date"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.expiry_date
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.errors.expiry_date[0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-group radio" }, [
                _c("label", { staticClass: "margin-right-3x" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.one_time_use,
                        expression: "form.one_time_use"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: true,
                      checked: _vm._q(_vm.form.one_time_use, true)
                    },
                    on: {
                      change: function($event) {
                        _vm.$set(_vm.form, "one_time_use", true)
                      }
                    }
                  }),
                  _vm._v("\n            One Time Use\n          ")
                ]),
                _vm._v(" "),
                _c("label", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.one_time_use,
                        expression: "form.one_time_use"
                      }
                    ],
                    attrs: { type: "radio" },
                    domProps: {
                      value: false,
                      checked: _vm._q(_vm.form.one_time_use, false)
                    },
                    on: {
                      change: function($event) {
                        _vm.$set(_vm.form, "one_time_use", false)
                      }
                    }
                  }),
                  _vm._v("\n            Multiple Use\n          ")
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Minimum Qualifying Days")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.minimum_qualifying_days,
                      expression: "form.minimum_qualifying_days"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    min: "0",
                    type: "number",
                    placeholder: "minimum qualifying days"
                  },
                  domProps: { value: _vm.form.minimum_qualifying_days },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.form,
                        "minimum_qualifying_days",
                        $event.target.value
                      )
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.minimum_qualifying_days
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.minimum_qualifying_days[0]) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Discount Format")]),
                _vm._v(" "),
                _c("div", { staticClass: "radio" }, [
                  _c("label", { staticClass: "margin-right-3x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.fixed,
                          expression: "form.fixed"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: false,
                        checked: _vm._q(_vm.form.fixed, false)
                      },
                      on: {
                        change: function($event) {
                          _vm.$set(_vm.form, "fixed", false)
                        }
                      }
                    }),
                    _vm._v("\n              Based on %\n            ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.fixed,
                          expression: "form.fixed"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: true,
                        checked: _vm._q(_vm.form.fixed, true)
                      },
                      on: {
                        change: function($event) {
                          _vm.$set(_vm.form, "fixed", true)
                        }
                      }
                    }),
                    _vm._v("\n              Based on $\n            ")
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Discount Amount/Percent")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.form.discount_amount,
                      expression: "form.discount_amount",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "discount amount" },
                  domProps: { value: _vm.form.discount_amount },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.form,
                        "discount_amount",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.discount_amount
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.errors.discount_amount[0]) +
                          "\n          "
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Works For")]),
                _vm._v(" "),
                _c("div", { staticClass: "radio" }, [
                  _c("label", { staticClass: "margin-right-3x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.all_locations,
                          expression: "form.all_locations"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: true,
                        checked: _vm._q(_vm.form.all_locations, true)
                      },
                      on: {
                        change: function($event) {
                          _vm.$set(_vm.form, "all_locations", true)
                        }
                      }
                    }),
                    _vm._v("\n              All Locations\n            ")
                  ]),
                  _vm._v(" "),
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.all_locations,
                          expression: "form.all_locations"
                        }
                      ],
                      attrs: { type: "radio" },
                      domProps: {
                        value: false,
                        checked: _vm._q(_vm.form.all_locations, false)
                      },
                      on: {
                        change: function($event) {
                          _vm.$set(_vm.form, "all_locations", false)
                        }
                      }
                    }),
                    _vm._v("\n              Selected Locations\n            ")
                  ])
                ])
              ]),
              _vm._v(" "),
              !_vm.form.all_locations
                ? _c(
                    "div",
                    { staticClass: "form-group" },
                    [
                      _c("label", [_vm._v("Choose Locations")]),
                      _vm._v(" "),
                      _c("s-select", {
                        attrs: {
                          options: _vm.locations,
                          selected: _vm.selectedLocation
                        },
                        on: { selectedChanged: _vm.onSelectLocation }
                      }),
                      _vm._v(" "),
                      _c("label", { staticClass: "margin-top-3x" }, [
                        _vm._v("Chosen Locations:")
                      ]),
                      _vm._v(" "),
                      _vm.choosenLocations.length
                        ? _c(
                            "ul",
                            { staticClass: "chosen-locations" },
                            _vm._l(_vm.choosenLocations, function(l) {
                              return _c("li", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(l.text) +
                                    "\n              "
                                ),
                                _c(
                                  "a",
                                  {
                                    staticClass: "text-danger pull-right",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.onRemoveChosenLocation(l)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-times" })]
                                )
                              ])
                            })
                          )
                        : _c("div", { staticClass: "text-warning" }, [
                            _vm._v(
                              "\n            No locations were chosen\n          "
                            )
                          ])
                    ],
                    1
                  )
                : _vm._e()
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3af7afca", { render: render, staticRenderFns: staticRenderFns })
  }
}