var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "input",
    attrs: { type: "text" },
    domProps: { value: _vm.value },
    on: {
      input: function($event) {
        _vm.onChange($event.target.value)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8d13e962", { render: render, staticRenderFns: staticRenderFns })
  }
}