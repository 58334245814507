import { user } from 'services'

export function beforeRouteUpdateMixin () {
  return {
    beforeRouteUpdate (to, from, next) {
      const { asyncData } = this.$options
      console.log('beforeRouteUpdate', this.$options);

      if (asyncData) {
        asyncData({
          app: this.$root,
          router: this.$router,
          store: this.$store,
          route: to
        }).then(next).catch(next)
      } else {
        next()
      }
    }
  }
}

export function beforeRouteEnterMixin () {
  return {
    beforeCreate () {
      try {
        if (this.$route.name == 'reservations.editFrame') {
          let token = this.$route.query.token;
          if (token) {
            user.setToken(token);
          }
        }
        
        if (!user.getToken() && !this.$route.meta.public) {
          console.log('test user.getToken()', user.getToken());
          this.$router.replace({
            name: 'login'
          });
        }
      } catch (e) {

      }

    }
  }
}

export function lodash () {
  return {
    computed: {
      _ () {
        return _;
      }
    }
  };
}
