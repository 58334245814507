var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "page-header" }, [
      !_vm.loading ? _c("h1", [_c("small", [_vm._v("New Airport")])]) : _vm._e()
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c(
          "form",
          {},
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["airport_create"],
                    expression: "['airport_create']",
                    arg: "allow"
                  }
                ],
                staticClass: "text-right"
              },
              [
                _vm.submit_loading
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { disabled: _vm.submit_loading, type: "button" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                        _vm._v("\n        SAVING...\n      ")
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onSubmitFom($event)
                          }
                        }
                      },
                      [_vm._v("\n        SAVE\n      ")]
                    )
              ]
            ),
            _vm._v(" "),
            _c("general", {
              attrs: {
                "form-data": _vm.form,
                errors: _vm.errors,
                "edit-mode": false
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-96548a62", { render: render, staticRenderFns: staticRenderFns })
  }
}