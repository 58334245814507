var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Export" },
      on: { close: _vm.onClose },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-danger",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        !_vm.complete
          ? _c("div", { staticClass: "text-center download-again" }, [
              _c("i", {
                staticClass: "full-width fa fa-spinner fa-spin fa-3x"
              }),
              _vm._v(" "),
              _c("span", [_vm._v("Please wait")]),
              _vm._v(" "),
              !_vm.fileReady
                ? _c("span", [
                    _vm._v("Preparing export: " + _vm._s(_vm.percent) + "%")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.fileReady ? _c("span", [_vm._v("Downloading...")]) : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.canDownloadAgain && _vm.complete
          ? _c("div", { staticClass: "text-left download-again" }, [
              _c("div", [
                _c("span", { staticClass: "f14" }, [
                  _vm._v("Your export is ready. If you don't see your file,")
                ])
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.getFileForDownload(_vm.file_name, _vm.ext)
                    }
                  }
                },
                [
                  _c("strong", { staticClass: "f16" }, [
                    _vm._v(" you can try downloading it again ")
                  ])
                ]
              )
            ])
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-37341574", { render: render, staticRenderFns: staticRenderFns })
  }
}