import { hasPermits } from 'directives'
import { confirm } from 'components/modals'
import newFee from './newFee'
import editFee from './edit'
import toastr from 'toastr'

const components = {
  newFee,
  editFee,
  confirm
}

const directives = {
  hasPermits
}

const computed = {
  fees () {
    let LANG = this.lang
    let fees = []

    _.forEach(this.$store.state.locations.active.fees, (fee) => {

      let _FEE = _.cloneDeep(fee)

      _.forEach(fee.translations, (trans) => {
        if (!trans.language) return false;
        if (trans.language.code == LANG) {
          _FEE = _.assign(_FEE, trans)

        }
      });

      fees.push(_FEE)
    });

    return fees;
  }
}

const methods = {
  editFee (id) {
    this.$refs.editFeeModal.open(id);
  },

  removeFee (id) {
    this.$refs.confirmDelete.confirm('Are you sure?', () => {
      this.loadings[id] = true;
      this.loadings = _.cloneDeep(this.loadings);
      this.$store.dispatch('locations.removeFee', {
        locId: this.$route.params.id,
        id
      }).then(() => {
        this.loadings[id] = false;
        let errors;
        if (errors = this.$store.state.locations.errors) {
          console.log('errors', errors);
          _.forEach(errors.errors, (error) => {
            toastr.error(error[0]);
          })
        } else {
          toastr.success('fee removed successfully');
        }
      });
    })
  }
}

export default {
  name: 'location-fees',
  props: ['lang'],
  components,
  directives,
  computed,
  methods,
  data () {
    return {
      loadings: {},
      locId: this.$route.params.id
    }
  }
}
