import toastr from 'toastr'
import moment from 'moment'
import { dateTime } from 'services'
import sSelect from 'components/sSelect';
import { confirm } from 'components/modals'
import datepicker from 'components/datepicker'
import { dateFormat, currencySymbol } from 'filters'
import calculations from '../calculations'
import searchResult from './searchResults'
import bookingOptions from './bookingOptions'

const components = {
  sSelect,
  datepicker,
  toastr,
  confirm,
  calculations,
  searchResult,
  bookingOptions
}

const filters = {
  dateFormat
}

const computed = {

  airports() {
    if (!this.$store.state.ports.all.length) return []
    var locations = this.$store.state.ports.all.map((item) => {
      return _.merge({text: item.name, value: item.id}, item)
    })

    return locations
  },

  amenities () {
    return this.$store.state.amenities.all;
  },

  locations () {
    return _.cloneDeep(this.$store.state.locations.all);
  },

  reservation () {
    return _.cloneDeep(this.$store.state.reservations.active)
  },

  activeHistory () {
    let activeRes =  _.find(this.reservation.history, h => h.active);

    if (_.isEmpty(activeRes)) {
      this.resetData()
      return activeRes;
    }

    return activeRes;
  },

  symbol () {
    if (this.editMode && this.search_type == 'same_location') {
      return currencySymbol(this.activeHistory.payment_currency);
    }
    return currencySymbol(this.selectedLocation.currency_code);
  },

  location_amenities () {
    return this.$store.state.locations.active.amenities || [];
  },

  errors () {
    let errors;
    if (this.$store.state.reservations.errors) {
      errors = this.$store.state.reservations.errors;
      if (errors.message) {
        toastr.error(errors.message);
      }
      this.$set(this, 'local_errors', _.assign(this.local_errors, errors.errors));
      return errors.errors ? errors.errors : {}
    }

    return {}
  },
  currencies() {
    return _.cloneDeep(this.$store.state.currencies.all);
  }
}

const methods = {
  updateCouponData (data) {
    if (data.coupon_code) {
      this.coupon_code = data.coupon_code;
    }
    this.coupon_error = data.coupon_error;
    this.coupon_success = data.coupon_success;
  },
  updateCost (data) {
    console.log('update cost', data);
    
    this.cost = data;
  },
  setSelectedLocation (location) {
    this.selectedLocation = location;
    this.location_id = location.id;
    this.$emit('onSelectLocation', location);
    this.setTimesForBundleReservation(location);

  },

  setTimesForBundleReservation (location) {
    if (this.reservation_type == 'both') {
      if (this.nights_to_sleep == 'beginning') {
        this.from_time = location.checkin_time || "12:00";
      }

      if (this.nights_to_sleep == 'end') {
        this.to_time = location.checkout_time || "12:00";
      }

      if (this.nights_to_sleep == 'both') {
        this.from_time = location.checkin_time || "12:00";
        this.to_time = location.checkout_time || "12:00";
      }
    }
    
  },
  handleLoading (param) {
    switch (param) {
      case 'search_loading':
        this.search_loading = !this.search_loading;
        break;
      case 'cost_loading':
        this.cost_loading = !this.cost_loading;
      break;
    }
  },
  toggleCalculations () {

    if (!$(this.$refs.calculationResponsive).is(":visible")) {
      $(this.$refs.calculationResponsive).show().animate({
        top: 0
      });
      $('body').css('overflow', 'hidden');
    } else {
      // $(this.$refs.mainCalculations).html(this.calculationContent);
      $(this.$refs.calculationResponsive).hide().css('top', "100%");
      $('body').css('overflow', 'auto');
      // this.calculationContent = null;
    }
  },

  removeCoupon () {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.coupon_code = null
    });
  },

  applyDiscountCode (couponCode) {
    this.coupon_code = couponCode
  },

  locationChanged (airport) {

    if (!this.editMode) {
      this.airport = airport
    }

    this.selectedAirport = airport
    this.airport_id = airport.value
  },

  validateForm () {    
    this.clear_errors = !this.clear_errors;
    this.local_errors = {};
    let fields = ['airport_id', 'from_date', 'to_date']

    if (this.reservation_type == 'both') {
      fields = fields.concat(['nights_to_sleep']);

      if (this.nights_to_sleep == 'beginning') {
        if (!this.to_time) {
          this.local_errors.to_time = [];
          this.local_errors.to_time.push('to time field is required');
        }
      }

      if (this.nights_to_sleep == 'end') {
        if (!this.from_time) {
          this.local_errors.from_time = [];
          this.local_errors.from_time.push('from time field is required');
        }
      }
    }

    if (this.reservation_type == 'room' || this.reservation_type == 'both') {    
      fields = fields.concat(['number_of_rooms']);

      for (let i in _.range(this.number_of_rooms)) {
        let number_of_guests = parseInt(this.guests_in_rooms[i]);
        if (!number_of_guests || isNaN(number_of_guests)) {
          this.$set(this.local_errors, 'guests_in_rooms.' + i, ['The guests in rooms fields are required.'])
        }

        if (!this.guests_names[i] || !this.guests_names[i].length) {                    
          let room_number = 1 + parseInt(i);
          this.$set(this.local_errors, 'guests_names.' + i, ['Guest name in room ' + room_number + ' is required.'])
        }
      }
    }

    if (this.reservation_type == 'parking') {
      fields = fields.concat(['from_time', 'to_time']);
    }
    
    if (this.reservation_type == 'parking' || this.reservation_type == 'both') {
      fields = fields.concat(['number_of_spots']);
    }

    for (let i in fields) {
      if (!this[fields[i]]) {
        this.$set(this.local_errors, fields[i], [fields[i].replace(/_/g, " ") + ' field is required!'])
      }
    }

    for (let i in this.guests_names) {
      if (this.guests_names[i].length > 255) {
        this.$set(this.local_errors, 'guests_names.' + i, ['The guest name may not be greater than 255 characters.'])
      }
    }    

    if (!_.isEmpty(this.local_errors)) {
      return false;
    }

    return true;
  },

  search (ignore_availability = false, keepSelectedLocation = false) {
    // this.pms_location_id = null
    if (!keepSelectedLocation) {
      this.location_id = null;
      this.selectedLocation = {}
    }

    let dateFormat = 'YYYY-MM-DD HH:mm:ss';
    let timeFormat = 'HH:mm';
    if (this.reservation_type == 'both') {
      if (this.nights_to_sleep == 'beginning') {
        if (this.editMode) {
          this.from_time = this.activeHistory.location.checkin_time;
        }
      }

      if (this.nights_to_sleep == 'end') {
        if (this.editMode) {
          this.to_time = this.activeHistory.location.checkout_time;
        }
      }
    }

    if (this.reservation_type == 'room' || this.nights_to_sleep == 'both') {
      if (this.editMode) {
        this.from_time = this.activeHistory.location.checkin_time;
        this.to_time = this.activeHistory.location.checkout_time;
      }
    }

    if (!this.validateForm()) {
      return;
    }

    this.search_loading = true

    var data = {
      airport_id: this.airport_id,
      reservation_type: this.reservation_type,
      currency_code: this.selected_currency == '' ? null : this.selected_currency,
    };

    data = _.assign(data, this.extra_fields);

    data.from_date = moment(this.from_date, ['DD/MMM/YYYY']).format(`YYYY-MM-DD`)
    data.to_date = moment(this.to_date, ['DD/MMM/YYYY']).format(`YYYY-MM-DD`);

    if (this.reservation_type == 'room' || this.reservation_type == 'both') {
      data.number_of_rooms = this.number_of_rooms;
      data.guests_in_rooms = this.guests_in_rooms.slice(0, this.number_of_rooms);
      data.guests_names = this.guests_names.slice(0, this.number_of_rooms);
      // data.room_type = this.room_type;

    }

    if (this.reservation_type == 'parking' || this.reservation_type == 'both') {
      data.number_of_spots = this.number_of_spots;
      // data.parking_type = this.parking_type;
    }

    if (this.reservation_type == 'both') {
      data.nights_to_sleep = this.nights_to_sleep;
    }

    if (this.selectedAmenities.length) {

      data.amenities = this.selectedAmenities;
    }

    if (this.reservation) {
      data.reservation_id = this.reservation.id
    }
    if (!ignore_availability) {

      this.$store.dispatch('reservations.locations', {airport_id: data.airport_id, amenities: data.amenities, reservation_type: this.reservation_type}).then((res) => {
        this.search_loading = false
        if (this.$store.state.reservations.errors) {
          toastr.error(this.$store.state.reservations.errors.message);
          this.local_errors = _.cloneDeep(this.$store.state.reservations.errors.errors)
          return;
        }

        if (!this.$store.state.reservations.errors) {
          this.search_results = _.cloneDeep(this.$store.state.reservations.locations.results);
          this.$store.dispatch('locations.clearCosts');
          let promises = [];

          this.search_results.forEach(location => {
            
            this.$set(location,'price', 'Calculating...');
            this.$set(location,'sold_out', false);

            promises.push(this.$store.dispatch('locations.getMinGrandTotal', {
              id: location.id,
              data
            }));
          });

          Promise.all(promises).then(() => {
            this.setLocationsPrices();
          });
        }

      })
    } else {
      this.search_results = _.cloneDeep(this.$store.state.reservations.locations.results);
      this.$store.dispatch('locations.clearCosts');
      let promises = [];

      this.search_results.forEach(location => {
        this.$set(location,'price', 'Calculating...');
        this.$set(location,'sold_out', false);

        promises.push(this.$store.dispatch('locations.getMinGrandTotal', {
          id: location.id,
          data
        }));
      });

      Promise.all(promises).then(() => {
        this.search_loading = false;
        this.setLocationsPrices();
      });
    }
  },

  onChangeExtraFields (fields) {
    this.extra_fields = fields;
    this.child_data = _.assign(this.child_data, this.extra_fields);
    this.$emit('searchCompleted', this.child_data);
  },

  setLocationsPrices () {
    let totals = _.cloneDeep(this.$store.state.locations.totals);

    for (let i in totals) {
      for (let j in this.search_results) {
        if (this.search_results[j].id == totals[i].id) {
          let grand_total = totals[i].error ? totals[i].error : totals[i].reservation.grand_total
          this.$set(this.search_results[j], 'price', grand_total);
          this.$set(this.search_results[j], 'sold_out', totals[i].reservation ? totals[i].reservation.sold_out : false);
          this.$set(this.search_results[j], 'warning', totals[i].warning);
          break;
        }
      }
    }
  },

  onSearchCompleted (data) {
    if(_.isEmpty(this.extra_fields)) {
      try {
        _.forEach(this.activeHistory.extra_fields, (item) => {
          console.log('item', item);
          this.extra_fields[item.name] = item.value;
        })
      } catch (e) {}
    }

    console.log('this.extra_fields', this.extra_fields);

    this.child_data = _.assign(data, this.extra_fields);

    console.log('this.child_data', this.child_data);
    this.$emit('searchCompleted', data);


  },

  resetData () {
    this.coupon_code = null
    this.selectedLocation = {}
    this.selectedAmenities = [];
    this.search_type = "same_location"
    this.airport_id = null
    this.location_id = null
    this.airport = null
    this.selectedAirport = {value: null, text: '-- Choose --'}
    this.nights_to_sleep = null
    this.from_date = null
    this.to_date = null
    this.from_time = null
    this.to_time = null
    this.number_of_spots = 1
    this.number_of_rooms = 1
    this.guests_in_rooms = []
    this.guests_names = []
    this.amount_to_pay = 0
    this.selected_currency = ""
  },

  setReservationTypes (data) {
    console.log('inside emiited action in search', data);
    if (data) {
      this.selected_reservation_types = data;
    }
  }
}

const watch = {
  activeTripProtection (n, o) {
    if (n == o) return;

    this.trip_protection = n;

    if (n.price == null) {
      this.trip_protection = {};
    }

  },
  airport_id () {
    this.cost = null;
  },
  from_date () {
    this.cost = null;
  },
  to_date () {
    this.cost = null;
  },
  nights_to_sleep () {
    this.cost = null;
  },
  number_of_spots () {
    this.cost = null;
  },
  number_of_rooms () {
    this.cost = null;
  },
  reservation_type () {
    this.selectedLocation = {};
    this.search_results = {};
    this.clear_errors = !this.clear_errors;
    this.cost = null;
    this.$emit('reservationTypeChanged', this.reservation_type);
    this.trip_protection = null;
  },
  selected_currency (n, o) {
    if (n == o) return;
    this.$emit('updateCurrency', this.selected_currency);
    this.deselect_location = false;
    if (!_.isEmpty(this.search_results)) {
      let selected_location = _.cloneDeep(this.selectedLocation);
      this.search(true,selected_location,true);

    }
  },
  selectedCurrency (n, o) {
    if (n == o) return;
    this.selected_currency = n;
  }
}

export default {
  name: "view-reservation-search",
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    activeTripProtection: {
      default: null,
    },
    selectedCurrency: {
      type: String,
      default: '',
    },
  },
  components,
  methods,
  computed,
  filters,
  watch,
  data () {
    return {
      local_errors: {},
      cost: null,
      search_loading: false,
      cost_loading: false,
      coupon_code: null,
      coupon_error: null,
      coupon_success: false,
      extra_fields: {},
      selectedLocation: {},
      selectedAmenities: [],
      deselect_location: true,
      search_type: "same_location",
      airport_id: null,
      location_id: null,
      airport: null,
      selectedAirport: {value: null, text: '-- Choose --'},
      reservation_type: 'both',
      nights_to_sleep: null,
      from_date: null,
      to_date: null,
      from_time: null,
      to_time: null,
      number_of_spots: 1,
      number_of_rooms: 1,
      guests_in_rooms: [],
      guests_names: [],
      amount_to_pay: 0,
      search_results: {},
      trip_protection: this.activeTripProtection,
      times: dateTime.getHalfHours(),
      child_data: {},
      clear_errors: false,
      selected_currency: this.selectedCurrency,
      requestData: {},
      selected_reservation_types: {
        combination_type: null,
        bundle_type: null,
        parking_type: null,
        room_type: null,
      },
    }
  },
  created () {
    this.$store.dispatch('ports.getAll', {_all: true})
    this.$store.dispatch('amenities.getAll', {_all: true})
    this.$store.dispatch('currencies.getAll', {_all: true})
  },
  mounted () {
    setTimeout(() => {
      console.log();
      $(this.$refs.affix).affix({
        offset: {
          top: $(this.$refs.affix).parent()[0].offsetTop,
          bottom: $('body').outerHeight() - $('.tab-content').height() - $(this.$refs.affix).parent()[0].offsetTop + 100
        }
      })
      $(this.$refs.affix).width($(this.$refs.affix).parent().width());
      console.log($(this.$refs.affix).width());
    }, 500);

    this.$nextTick(() => {
      this.guests_in_rooms = [];
      this.guests_names = [];

      if (this.activeHistory) {
        this.from_date = moment(this.activeHistory.from_date, [`YYYY-MM-DD HH:mm:00`]).format('DD/MMM/YYYY')
        this.to_date =  moment(this.activeHistory.to_date, [`YYYY-MM-DD HH:mm:00`]).format('DD/MMM/YYYY')
        this.from_time = moment(this.activeHistory.from_date, [`YYYY-MM-DD HH:mm:00`]).format(`HH:mm`);
        this.to_time = moment(this.activeHistory.to_date, [`YYYY-MM-DD HH:mm:00`]).format(`HH:mm`);
        this.airport_id = this.activeHistory.airport_id
        this.location_id = this.activeHistory.location_id
        this.selectedAirport = {value: this.activeHistory.airport_id, text: this.activeHistory.airport.name};
        this.airport = this.activeHistory.airport
        this.nights_to_sleep = this.activeHistory.nights_to_sleep
        this.number_of_rooms = this.activeHistory.number_of_rooms
        this.number_of_spots = this.activeHistory.number_of_spots
        this.reservation_type = this.activeHistory.reservation_type;

        _.forEach(this.activeHistory.rooms, (room) => {
          this.guests_in_rooms.push(room.number_of_guests);
          this.guests_names.push(room.guest_name);
        });

        if (this.activeHistory.coupon) {
          this.coupon_code = this.activeHistory.coupon.code;
        }
      }
    })
  }
}
