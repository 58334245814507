import moment from 'moment'
import env from 'constants/env'
import datatable from 'components/datatable'
import { permits, user } from 'services'
import { currencySymbol } from 'filters'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
    },

    setCols () {
        this.columns = [
            {name: "Cancelled", object_name: (obj) => {
                return obj.cancelled ? 'Yes' : 'No';
                }},
            {name: "Active", object_name: (obj) => {
                return obj.active ? 'Yes' : 'No';
                }},
            {name: "Modified", object_name: (obj) => {
                return obj.history_count > 1 ? 'Yes' : 'No';
                }},
            {name: "Airport", object_name: "airport"},
            {name: "Location", object_name: "location_name"},
            {name: "Start Date", object_name: (obj) => {
                return moment(obj.from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
                }},
            {name: "End Date", object_name: (obj) => {
                return moment(obj.to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
                }},
            {name: "Reservation #", object_name: "reservation_number"},
            {name: "Creation Date", object_name: (obj) => {
                return moment(obj.booking_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
            }},
            {name: "Customer Name", object_name:  (obj) => {
                return obj.first_name + " " + obj.last_name ;
            }},
            {name: "Customer Email", object_name: "email"},
            {name: "# Of Spots", object_name: "number_of_spots"},
            {name: "# Of Rooms", object_name: "number_of_rooms"},
            {name: "Currency", object_name: "currency_code"},
            {name: "Subtotal", object_name: (obj) => {
                return obj.subtotal + currencySymbol(obj.currency_code);
            }},
            {name: "TT Fees", object_name: (obj) => {
                return obj.sj_fees + currencySymbol(obj.currency_code);
            }},
            // for now in TT location_commission represents Location Total Amount 
            {name: "Location Total Amount", object_name: (obj) => {
                return obj.location_commission + currencySymbol(obj.currency_code);
            }},
            // TODO these fields needs to be discussed
            // {name: "Location Fees", object_name: (obj) => {
            //     return obj.location_fees + currencySymbol(obj.currency_code);
            // }},
            // {name: "Location Total Amount", object_name: (obj) => {
            //     return obj.location_total_amount + currencySymbol(obj.currency_code);
            // }},
            {name: "Partial Refund", object_name: (obj) => {
                return obj.partial_refund + currencySymbol(obj.currency_code);
            }},
            {name: "Taxes", object_name: (obj) => {
                return obj.total_tax + currencySymbol(obj.currency_code);
            }},
            {name: "TT Tax", object_name: (obj) => {
                return obj.sj_tax + currencySymbol(obj.currency_code);
            }},
            {name: "Discount", object_name: (obj) => {
                return obj.total_discount + currencySymbol(obj.currency_code);
            }},
            {name: "Coupon Code", object_name: "coupon_code"},
            {name: "Trip Protection Price", object_name: (obj) => {
                return obj.trip_protection_price + currencySymbol(obj.currency_code);
            }},
            {name: "Grand Total", object_name: (obj) => {
                return obj.grand_total + currencySymbol(obj.currency_code);
            }},
            {name: "Due at Lot", object_name: (obj) => {
                return obj.due_at_location_total > 0 ? "true" : "false"; ;
            }},
            {name: "Due at Location Total", object_name: (obj) => {
                return obj.due_at_location_total + currencySymbol(obj.currency_code);
            }},
            {name: "TT Commission", object_name: (obj) => {
                return obj.sj_commission + currencySymbol(obj.currency_code);
            }},
            {name: "Stripe Account", object_name: "stripe_account"},
            {name: "Stripe Fees", object_name: (obj) => {
                return obj.stripe_net_fees + currencySymbol(obj.currency_code);
            }},
        ]
    }
}

let computed = {
}

let watch = {
}

export default {
    name: "commission-report",
    methods,
    components,
    computed,
    watch,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/commission',
        columns: []
    }),
    mounted () {
        this.user = user.getUser();
        this.setCols();
    }
}