var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "page-header" }, [
      !_vm.loading ? _c("h1", [_c("small", [_vm._v("Edit Page")])]) : _vm._e()
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("form", {}, [
          _c("div", { staticClass: "wrapper wrapper-overflow" }, [
            _c("div", { staticClass: "pull-left" }, [_c("page-go-back")], 1),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["page_update"],
                    expression: "['page_update']",
                    arg: "allow"
                  }
                ],
                staticClass: "pull-right"
              },
              [
                _vm.submit_loading
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { disabled: _vm.submit_loading, type: "button" }
                      },
                      [
                        _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                        _vm._v("\n          SAVING...\n        ")
                      ]
                    )
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.onSubmitForm($event)
                          }
                        }
                      },
                      [_vm._v("SAVE")]
                    )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "row margin-top-4x" }, [
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "margin-bottom-4x margin-top-4x" }, [
                _c(
                  "ul",
                  { staticClass: "nav nav-pills" },
                  _vm._l(_vm.langs, function(language) {
                    return _c(
                      "li",
                      {
                        class: { active: language.code == _vm.lang },
                        attrs: { role: "presentation" }
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                _vm.lang = language.code
                              }
                            }
                          },
                          [_vm._v(_vm._s(language.name))]
                        )
                      ]
                    )
                  })
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "pageTitle" } }, [_vm._v("Title")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.title,
                      expression: "formData.title",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "pageTitle",
                    placeholder: "Page Title"
                  },
                  domProps: { value: _vm.formData.title },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.formData,
                        "title",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.title
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.title[0]))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "slug" } }, [_vm._v("Slug")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.slug,
                      expression: "formData.slug",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", id: "slug", placeholder: "Page Slug" },
                  domProps: { value: _vm.formData.slug },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.formData, "slug", $event.target.value.trim())
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.slug
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.slug[0]))
                    ])
                  : _vm._e()
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12" }, [
              _c("h3", { staticClass: "text-info" }, [_vm._v("Page Content")])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-12" },
              [
                _c("tinymce", {
                  model: {
                    value: _vm.formData.content,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "content", $$v)
                    },
                    expression: "formData.content"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12" }, [
              _c("h3", { staticClass: "text-info" }, [_vm._v("SEO")])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-sm-12" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "seo_title" } }, [
                  _vm._v("SEO Title")
                ]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.seo_title,
                      expression: "formData.seo_title",
                      modifiers: { trim: true }
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    type: "text",
                    id: "seo_title",
                    placeholder: "Page SEO Title"
                  },
                  domProps: { value: _vm.formData.seo_title },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.formData,
                        "seo_title",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.seo_title
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.seo_title[0]))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "seo_description" } }, [
                  _vm._v("SEO Description")
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.seo_description,
                      expression: "formData.seo_description",
                      modifiers: { trim: true }
                    }
                  ],
                  ref: "desc",
                  staticClass: "form-control",
                  domProps: { value: _vm.formData.seo_description },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.formData,
                        "seo_description",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.seo_description
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.seo_description[0]))
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "seo_keywords" } }, [
                  _vm._v("SEO Keywords")
                ]),
                _vm._v(" "),
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.trim",
                      value: _vm.formData.seo_keywords,
                      expression: "formData.seo_keywords",
                      modifiers: { trim: true }
                    }
                  ],
                  ref: "desc",
                  staticClass: "form-control",
                  domProps: { value: _vm.formData.seo_keywords },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.formData,
                        "seo_keywords",
                        $event.target.value.trim()
                      )
                    },
                    blur: function($event) {
                      _vm.$forceUpdate()
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.seo_keywords
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(_vm._s(_vm.errors.seo_keywords[0]))
                    ])
                  : _vm._e()
              ])
            ])
          ])
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-46987f0e", { render: render, staticRenderFns: staticRenderFns })
  }
}