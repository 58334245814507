import Vue from 'vue'
import Vuex from 'vuex'
import title from './modules/title'
import * as actions from './actions'
import * as getters from './getters'
import * as mutations from './mutations'
//NOTE: CHANGE UNDER THIS LINE
import nav from './modules/nav'
import user from './modules/user'
import users from './modules/users'
import customers from './modules/customers'
import address from './modules/address'
import affiliates from './modules/affiliates'
import timezones from './modules/timezones'
import ports from './modules/ports'
import locations from './modules/locations'
import multilingual from './modules/multilingual'
import roles from './modules/roles'
import coupons from './modules/coupons'
import reservations from './modules/reservations'
import reviews from './modules/reviews'
import pages from './modules/pages'
import tripProtections from './modules/tripProtections'
import currencies from './modules/currencies'
import amenities from './modules/amenities'
import carousels from './modules/carousels'
import faqs from './modules/faqs'
import faqCategories from './modules/faqCategories'
import deferredRevenuesEmails from './modules/deferredRevenuesEmails'

Vue.use(Vuex)

export function createStore () {
  const debug = process.env.NODE_ENV !== 'prod'
  return new Vuex.Store({
    actions,
    getters,
    mutations,
    modules: {
      nav,
      user,
      users,
      address,
      affiliates,
      timezones,
      ports,
      locations,
      multilingual,
      roles,
      coupons,
      reservations,
      customers,
      reviews,
      pages,
      tripProtections,
      currencies,
      amenities,
      carousels,
      faqs,
      faqCategories,
      deferredRevenuesEmails,
      //NOTE: !!!DO NOT CHANGE AFTER THIS LINE!!!
      title
    },
    strict: debug,
  })
}
