var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row margin-top-4x" },
    [
      _c(
        "div",
        { staticClass: "col-md-7 col-sm-12" },
        [
          _c("h2", { staticClass: "header-title" }, [
            _vm._v("GENERAL INFORMATION")
          ]),
          _vm._v(" "),
          _vm.editMode
            ? _c("div", { staticClass: "col-sm-12 margin-top-4x" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "radio-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search_type,
                          expression: "search_type"
                        }
                      ],
                      attrs: { type: "radio", value: "same_location" },
                      domProps: {
                        checked: _vm._q(_vm.search_type, "same_location")
                      },
                      on: {
                        change: function($event) {
                          _vm.search_type = "same_location"
                        }
                      }
                    }),
                    _vm._v("Edit Current Reservation\n        ")
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "radio-inline" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.search_type,
                          expression: "search_type"
                        }
                      ],
                      attrs: { type: "radio", value: "other_location" },
                      domProps: {
                        checked: _vm._q(_vm.search_type, "other_location")
                      },
                      on: {
                        change: function($event) {
                          _vm.search_type = "other_location"
                        }
                      }
                    }),
                    _vm._v("Search Other Locations\n        ")
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.editMode && _vm.search_type !== "other_location"
            ? _c("div", { staticClass: "margin-top-5x" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", { staticClass: "col-xs-12" }, [
                  _c("table", { staticClass: "table table-bordered" }, [
                    _c(
                      "tbody",
                      [
                        _c("tr", [
                          _c("td", { staticClass: "col-xs-3" }, [
                            _vm._v("Reservation No.")
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.reservation.reservation_number))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", { staticClass: "col-xs-3" }, [
                            _vm._v("Reserved By:")
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.activeHistory.first_name +
                                    " " +
                                    _vm.activeHistory.last_name
                                ) +
                                "\n                -\n                " +
                                _vm._s(_vm.reservation.reserved_by) +
                                "\n              "
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", { staticClass: "col-xs-3" }, [
                            _vm._v("Dates")
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(
                              _vm._s(
                                _vm._f("dateFormat")(
                                  _vm.activeHistory.from_date,
                                  "DD/MMM/YYYY hh:mm A"
                                )
                              ) +
                                " - " +
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.activeHistory.to_date,
                                    "DD/MMM/YYYY hh:mm A"
                                  )
                                )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", { staticClass: "col-xs-3" }, [
                            _vm._v("Port")
                          ]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.activeHistory.airport.name))
                          ])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", { staticClass: "col-xs-3" }, [
                            _vm._v("Timezone")
                          ]),
                          _vm._v(" "),
                          _vm.activeHistory.airport.timezone
                            ? _c("th", [
                                _vm._v(
                                  _vm._s(
                                    _vm.activeHistory.airport.timezone.name
                                  )
                                )
                              ])
                            : _c("th", [_vm._v("GMT")])
                        ]),
                        _vm._v(" "),
                        _c("tr", [
                          _c("td", [_vm._v("Location")]),
                          _vm._v(" "),
                          _c("th", [
                            _vm._v(_vm._s(_vm.activeHistory.location.name))
                          ])
                        ]),
                        _vm._v(" "),
                        _vm._m(1),
                        _vm._v(" "),
                        !_vm._.isEmpty(_vm.activeHistory.bundle_info)
                          ? _c("tr", [
                              _c("td", [_vm._v("Bundle Type")]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(_vm.activeHistory.bundle_info.name)
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm._.isEmpty(_vm.activeHistory.bundle_info)
                          ? _c("tr", [
                              _c("td", [_vm._v("Bundle Includes")]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  "\n\n                " +
                                    _vm._s(
                                      _vm.activeHistory
                                        .number_of_sleeping_nights +
                                        " Night" +
                                        (_vm.activeHistory
                                          .number_of_sleeping_nights > 1 ||
                                        _vm.activeHistory
                                          .number_of_sleeping_nights == 0
                                          ? "s"
                                          : "")
                                    ) +
                                    " & " +
                                    _vm._s(
                                      _vm.activeHistory.number_of_parking_days +
                                        " Day" +
                                        (_vm.activeHistory
                                          .number_of_parking_days > 1 ||
                                        _vm.activeHistory
                                          .number_of_parking_days == 0
                                          ? "s"
                                          : "")
                                    ) +
                                    " of Parking\n\n              "
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeHistory.parking_type_name
                          ? _c("tr", [
                              _c("td", [_vm._v("Parking Type")]),
                              _vm._v(" "),
                              _c("th", [
                                _vm._v(
                                  _vm._s(_vm.activeHistory.parking_type_name) +
                                    " (" +
                                    _vm._s(_vm.activeHistory.number_of_spots) +
                                    " spot/s)"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeHistory.rooms
                          ? _c("tr", [
                              _c("th", { attrs: { colspan: "2" } }, [
                                _vm._v("Rooms")
                              ])
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.activeHistory.rooms, function(room, i) {
                          return _vm.activeHistory.rooms
                            ? _c("tr", [
                                _c("td", [_vm._v("Room " + _vm._s(i + 1))]),
                                _vm._v(" "),
                                _c("td", [
                                  _c("table", { staticClass: "table" }, [
                                    _c("tr", [
                                      _c("th", [_vm._v("Room Type: ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(room.room_type_name) +
                                            "\n                    "
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", [_vm._v("Guest Name: ")]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(room.guest_name) +
                                            "\n                    "
                                        )
                                      ])
                                    ])
                                  ])
                                ])
                              ])
                            : _vm._e()
                        })
                      ],
                      2
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.editMode || _vm.search_type == "other_location"
            ? _c("div", { staticClass: "col-sm-12" }, [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", { attrs: { for: "airportId" } }, [
                      _vm._v("Airport")
                    ]),
                    _vm._v(" "),
                    _c("s-select", {
                      attrs: {
                        selected: _vm.selectedAirport,
                        options: _vm.airports
                      },
                      on: { selectedChanged: _vm.locationChanged }
                    }),
                    _vm._v(" "),
                    _vm.local_errors.airport_id
                      ? _c("div", { staticClass: "text-danger" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.local_errors.airport_id[0]) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Reservation type")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "radio" }, [
                    _c("label", { staticClass: "margin-right-2x" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reservation_type,
                            expression: "reservation_type"
                          }
                        ],
                        attrs: { type: "radio", value: "both" },
                        domProps: {
                          checked: _vm._q(_vm.reservation_type, "both")
                        },
                        on: {
                          change: function($event) {
                            _vm.reservation_type = "both"
                          }
                        }
                      }),
                      _vm._v("\n            Parking & Room\n          ")
                    ]),
                    _vm._v(" "),
                    _c("label", { staticClass: "margin-right-2x" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reservation_type,
                            expression: "reservation_type"
                          }
                        ],
                        attrs: { type: "radio", value: "room" },
                        domProps: {
                          checked: _vm._q(_vm.reservation_type, "room")
                        },
                        on: {
                          change: function($event) {
                            _vm.reservation_type = "room"
                          }
                        }
                      }),
                      _vm._v("\n            Room Only\n          ")
                    ]),
                    _vm._v(" "),
                    _c("label", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.reservation_type,
                            expression: "reservation_type"
                          }
                        ],
                        attrs: { type: "radio", value: "parking" },
                        domProps: {
                          checked: _vm._q(_vm.reservation_type, "parking")
                        },
                        on: {
                          change: function($event) {
                            _vm.reservation_type = "parking"
                          }
                        }
                      }),
                      _vm._v("\n            Parking Only\n          ")
                    ])
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("From Date")]),
                _vm._v(" "),
                _c("datepicker", {
                  staticClass: "form-control",
                  model: {
                    value: _vm.from_date,
                    callback: function($$v) {
                      _vm.from_date = $$v
                    },
                    expression: "from_date"
                  }
                }),
                _vm._v(" "),
                _vm.local_errors.from_date
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.local_errors.from_date[0]) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-6" }, [
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", [_vm._v("To Date")]),
                _vm._v(" "),
                _c("datepicker", {
                  staticClass: "form-control",
                  attrs: { "min-date": _vm.from_date },
                  model: {
                    value: _vm.to_date,
                    callback: function($$v) {
                      _vm.to_date = $$v
                    },
                    expression: "to_date"
                  }
                }),
                _vm._v(" "),
                _vm.local_errors.to_date
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.local_errors.to_date[0]) +
                          "\n        "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ]),
          _vm._v(" "),
          _vm.reservation_type != "room"
            ? _c("div", { staticClass: "col-sm-6" }, [
                _vm.reservation_type == "parking" ||
                _vm.nights_to_sleep == "end"
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Vehicle Drop-Off")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.from_time,
                              expression: "from_time"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.from_time = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("-- Choose time --")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.times, function(time) {
                            return _c(
                              "option",
                              { domProps: { value: time.value } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(time.label) +
                                    "\n          "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.local_errors.from_time
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.local_errors.from_time[0]) +
                                "\n        "
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.reservation_type != "room"
            ? _c("div", { staticClass: "col-sm-6" }, [
                _vm.reservation_type == "parking" ||
                _vm.nights_to_sleep == "beginning"
                  ? _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Vehicle Pick-Up")]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.to_time,
                              expression: "to_time"
                            }
                          ],
                          staticClass: "form-control",
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.to_time = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("-- Choose time --")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.times, function(time) {
                            return _c(
                              "option",
                              { domProps: { value: time.value } },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(time.label) +
                                    "\n          "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.local_errors.to_time
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.local_errors.to_time[0]) +
                                "\n        "
                            )
                          ])
                        : _vm._e()
                    ])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "col-sm-12" }, [
            _vm.reservation_type == "both"
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", [_vm._v("Search Type")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.nights_to_sleep,
                          expression: "nights_to_sleep"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.nights_to_sleep = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("-- Choose which night to sleep --")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "beginning" } }, [
                        _vm._v("At the beginning of my trip")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "end" } }, [
                        _vm._v("At the end of my trip")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "both" } }, [
                        _vm._v("Both at the beginning and end of my trip")
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.local_errors.nights_to_sleep
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.local_errors.nights_to_sleep[0]) +
                            "\n        "
                        )
                      ])
                    : _vm._e()
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _vm.reservation_type == "parking" ||
              _vm.reservation_type == "both"
                ? _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Number Of Spots")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.number_of_spots,
                            expression: "number_of_spots"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", min: "1", max: "5" },
                        domProps: { value: _vm.number_of_spots },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.number_of_spots = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.local_errors.number_of_spots
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.local_errors.number_of_spots[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.reservation_type == "room" || _vm.reservation_type == "both"
                ? _c("div", { staticClass: "col-sm-6" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", [_vm._v("Number Of Rooms")]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.number_of_rooms,
                            expression: "number_of_rooms"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { type: "number", min: "1", max: "5" },
                        domProps: { value: _vm.number_of_rooms },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.number_of_rooms = $event.target.value
                          }
                        }
                      }),
                      _vm._v(" "),
                      _vm.local_errors.number_of_rooms
                        ? _c("div", { staticClass: "text-danger" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.local_errors.number_of_rooms[0]) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.reservation_type == "room" || _vm.reservation_type == "both"
              ? _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("label", [_vm._v("Number of guests in each room")]),
                    _vm._v(" "),
                    _vm._l(Number(_vm.number_of_rooms), function(num) {
                      return _c(
                        "div",
                        { staticClass: "margin-bottom-2x row" },
                        [
                          _c("div", { staticClass: "col-sm-6" }, [
                            _vm._v("\n            Room " + _vm._s(num) + " : "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.guests_in_rooms[num - 1],
                                  expression: "guests_in_rooms[num-1]"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "number",
                                name: "guests_in_rooms[]"
                              },
                              domProps: { value: _vm.guests_in_rooms[num - 1] },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.guests_in_rooms,
                                    num - 1,
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.local_errors["guests_in_rooms." + (num - 1)]
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.local_errors[
                                          "guests_in_rooms." + (num - 1)
                                        ][0]
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-sm-6" }, [
                            _vm._v("\n            Guest Name : "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.guests_names[num - 1],
                                  expression: "guests_names[num-1]"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text", name: "guests_names[]" },
                              domProps: { value: _vm.guests_names[num - 1] },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.guests_names,
                                    num - 1,
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _vm.local_errors["guests_names." + (num - 1)]
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.local_errors[
                                          "guests_names." + (num - 1)
                                        ][0]
                                      ) +
                                      "\n            "
                                  )
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    })
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            (!_vm.editMode && _vm.amenities) ||
            (_vm.editMode &&
              _vm.amenities &&
              _vm.search_type != "same_location")
              ? _c("div", {}, [
                  _c("hr"),
                  _vm._v(" "),
                  _c("label", [_vm._v("Amenities")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "row" },
                    _vm._l(_vm.amenities, function(amenity) {
                      return _c("div", { staticClass: "col-md-4 col-sm-6" }, [
                        _c("div", { staticClass: "checkbox" }, [
                          _c("label", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedAmenities,
                                  expression: "selectedAmenities"
                                }
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: amenity.id,
                                checked: Array.isArray(_vm.selectedAmenities)
                                  ? _vm._i(_vm.selectedAmenities, amenity.id) >
                                    -1
                                  : _vm.selectedAmenities
                              },
                              on: {
                                change: function($event) {
                                  var $$a = _vm.selectedAmenities,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = amenity.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectedAmenities = $$a.concat([
                                          $$v
                                        ]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectedAmenities = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectedAmenities = $$c
                                  }
                                }
                              }
                            }),
                            _vm._v(
                              "\n                " +
                                _vm._s(amenity.display_name) +
                                "\n              "
                            )
                          ])
                        ])
                      ])
                    })
                  ),
                  _vm._v(" "),
                  _c("hr")
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.editMode || _vm.search_type == "other_location"
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-primary margin-bottom-6x",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.search()
                      }
                    }
                  },
                  [
                    _vm.search_loading
                      ? _c("span", [
                          _c("i", {
                            staticClass: "fa fa-spinner fa-spin fa-fw"
                          }),
                          _vm._v("\n          Loading...\n        ")
                        ])
                      : _c("span", [_vm._v("Search Locations")])
                  ]
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("search-result", {
            attrs: {
              "search-results": _vm.search_results,
              "edit-mode": _vm.editMode,
              "search-type": _vm.search_type,
              "search-loading": _vm.search_loading,
              "selected-location": _vm.selectedLocation,
              cost: _vm.cost,
              symbol: _vm.symbol,
              airport: _vm.airport,
              "from-date": _vm.from_date,
              "from-time": _vm.from_time,
              "to-date": _vm.to_date,
              "to-time": _vm.to_time,
              "reservation-type": _vm.reservation_type,
              "number-of-spots": _vm.number_of_spots,
              "clear-errors": _vm.clear_errors,
              "selected-currency": _vm.selected_currency,
              "deselect-location": _vm.deselect_location
            },
            on: {
              toggleLoading: _vm.handleLoading,
              setParentLocation: _vm.setSelectedLocation,
              changeExtraFields: _vm.onChangeExtraFields
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {},
            [
              _c("booking-options", {
                attrs: {
                  "reservation-type": _vm.reservation_type,
                  symbol: _vm.symbol,
                  "selected-location": _vm.selectedLocation,
                  "from-date": _vm.from_date,
                  "to-date": _vm.to_date,
                  "search-results": _vm.search_results,
                  "edit-mode": _vm.editMode,
                  "guests-in-rooms": _vm.guests_in_rooms,
                  "guests-names": _vm.guests_names,
                  "number-of-rooms": _vm.number_of_rooms,
                  "nights-to-sleep": _vm.nights_to_sleep,
                  "number-of-spots": _vm.number_of_spots,
                  "airport-id": _vm.airport_id,
                  "coupon-code": _vm.coupon_code,
                  "from-time": _vm.from_time,
                  "to-time": _vm.to_time,
                  "search-type": _vm.search_type,
                  "trip-protection": _vm.activeTripProtection,
                  "active-history": _vm.activeHistory,
                  "extra-fields": _vm.extra_fields,
                  "search-loading": _vm.search_loading,
                  "deselect-option": _vm.deselect_location,
                  "selected-currency": _vm.selected_currency,
                  validateForm: _vm.validateForm
                },
                on: {
                  toggleLoading: _vm.handleLoading,
                  updateCost: _vm.updateCost,
                  updateCouponData: _vm.updateCouponData,
                  searchCompleted: _vm.onSearchCompleted,
                  updateReservationTypes: _vm.setReservationTypes
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-5 hidden-sm hidden-xs" }, [
        _c(
          "div",
          { ref: "affix", staticClass: "calculations" },
          [
            (!_vm.editMode && _vm.currencies) ||
            (_vm.editMode &&
              _vm.currencies &&
              _vm.search_type != "same_location")
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "control-label",
                          attrs: { for: "currency" }
                        },
                        [_vm._v("Preferred Currency ( Optional )")]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selected_currency,
                              expression: "selected_currency"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { name: "currecy", id: "currency" },
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selected_currency = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "" } }, [
                            _vm._v("No Currency")
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.currencies, function(currency) {
                            return _c(
                              "option",
                              { domProps: { value: currency.code } },
                              [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(currency.name) +
                                    "\n                "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    ])
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("calculations", {
              attrs: {
                cost: _vm.cost,
                "reservation-type": _vm.reservation_type,
                costLoading: _vm.cost_loading,
                activeHistory: _vm.activeHistory,
                reservation: _vm.reservation,
                "coupon-code": _vm.coupon_code,
                "coupon-success": _vm.coupon_success,
                "coupon-error": _vm.coupon_error,
                "number-of-rooms": _vm.number_of_rooms,
                "currency-symbol": _vm.symbol,
                "selected-currency": _vm.selected_currency
              },
              on: {
                applyDiscountCode: _vm.applyDiscountCode,
                removeCoupon: _vm.removeCoupon
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "calculationResponsive",
          staticClass: "calculations-responsive"
        },
        [
          (!_vm.editMode && _vm.currencies) ||
          (_vm.editMode && _vm.currencies && _vm.search_type != "same_location")
            ? _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-md-12" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c(
                      "label",
                      {
                        staticClass: "control-label",
                        attrs: { for: "currency" }
                      },
                      [_vm._v("Preferred Currency ( Optional )")]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selected_currency,
                            expression: "selected_currency"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: { name: "currecy", id: "currency" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selected_currency = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("No Currency")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.currencies, function(currency) {
                          return _c(
                            "option",
                            { domProps: { value: currency.code } },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(currency.name) +
                                  "\n            "
                              )
                            ]
                          )
                        })
                      ],
                      2
                    )
                  ])
                ])
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("calculations", {
            attrs: {
              cost: _vm.cost,
              "reservation-type": _vm.reservation_type,
              costLoading: _vm.cost_loading,
              activeHistory: _vm.activeHistory,
              reservation: _vm.reservation,
              "coupon-code": _vm.coupon_code,
              "coupon-success": _vm.coupon_success,
              "coupon-error": _vm.coupon_error,
              "number-of-rooms": _vm.number_of_rooms,
              "currency-symbol": _vm.symbol,
              "selected-currency": _vm.selected_currency
            },
            on: {
              applyDiscountCode: _vm.applyDiscountCode,
              removeCoupon: _vm.removeCoupon
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "toggle-calculations visible-sm visible-xs",
          on: { click: _vm.toggleCalculations }
        },
        [
          _c("i", {
            staticClass: "fa fa-usd",
            attrs: { "aria-hidden": "true" }
          }),
          _vm._v(" "),
          _vm.cost
            ? _c("div", { staticClass: "toggle-calculations--amount" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("currency")(
                        _vm.cost.reservation.grand_total,
                        _vm.symbol
                      )
                    ) +
                    "\n    "
                )
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _c("confirm", { ref: "deleteConfirm", attrs: { type: "danger" } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12" }, [
      _c("p", {}),
      _c("h4", [_vm._v("Location Information:")]),
      _vm._v(" "),
      _c("p")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("td", [_vm._v("Distance From Airport")]),
      _vm._v(" "),
      _c("th", [_vm._v("-")])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-ae9275b8", { render: render, staticRenderFns: staticRenderFns })
  }
}