var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "ul",
      {
        staticClass: "nav nav-tabs",
        class: { "nav-justified": _vm.justified },
        attrs: { role: "tablist" }
      },
      _vm._l(_vm.titles, function(title, i) {
        return _c(
          "li",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: title.permits,
                expression: "title.permits",
                arg: "allow"
              }
            ],
            class: { active: _vm.active == title.title },
            attrs: { role: "presentation" },
            on: {
              click: function($event) {
                _vm.activateTab(title)
              }
            }
          },
          [
            _c(
              "a",
              {
                attrs: {
                  href: "#" + title.title.replace(" ", "_"),
                  "aria-controls": title.title,
                  role: "tab",
                  "data-toggle": "tab"
                }
              },
              [_vm._v(_vm._s(title.title))]
            )
          ]
        )
      })
    ),
    _vm._v(" "),
    _c("div", { staticClass: "tab-content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6261480f", { render: render, staticRenderFns: staticRenderFns })
  }
}