import Vue from 'vue'
import VueHtml5Editor from 'vue-html5-editor'
import Vue2Filters from 'vue2-filters'
import { createApp } from './app';
import { beforeRouteUpdateMixin, beforeRouteEnterMixin } from './entry.mixins'

Vue.use(VueHtml5Editor);
Vue.use(Vue2Filters);

Vue.mixin(beforeRouteEnterMixin())
Vue.mixin(beforeRouteUpdateMixin())

const {app, store, router} = createApp();

console.log('client loaded successfully');

if (window.__INITIAL_STATE__ != 'init_state') {
  store.replaceState(window.__INITIAL_STATE__)
  console.log('__INITIAL_STATE__', window.__INITIAL_STATE__);
}

router.onReady(() => {
  router.beforeResolve((to, from, next) => {
    console.log('before resolve', to, from);

    const matched = router.getMatchedComponents(to)
    const prevMatched = router.getMatchedComponents(from)

    // we only care about non-previously-rendered components,
    // so we compare them until the two matched lists differ
    let diffed = false
    const activated = matched.filter((c, i) => {
      return diffed || (diffed = (prevMatched[i] !== c))
    })

    if (!activated.length) {
      return next()
    }

    Promise.all(activated.map(c => {
      if (c.asyncData) {
        return c.asyncData({
          app,
          router,
          store,
          route: to,
        })
      }
    })).then(() => {

      // stop loading indicator

      next()
    }).catch(next)
  });

  console.log('--- app mounted ---');
  app.$mount('#app');
});
