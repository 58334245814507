import { http } from 'services'

// initial state
const state = () => ({
  parking_amenities: [],
  room_amenities: [],
  facility_amenities: [],
  all: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['amenities.getParkingAmenities'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    if (state.parking_amenities.length) {
      return commit('GET_PARKING_AMENITIES');
    }

    return (http.get('amenities?type=parking&_all=true')).then ((res) => {
      commit('GET_PARKING_AMENITIES', res);
    })
    .catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },

  ['amenities.getRoomAmenities'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    return (http.get('amenities?type=room&_all=true'))
    .then ((res) => {
      commit('GET_ROOM_AMENITIES', res);
    })
    .catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },

  ['amenities.getFacilityAmenities'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    return (http.get('amenities?type=facility&_all=true'))
    .then ((res) => {
      commit('GET_FACILITY_AMENITIES', res);
    })
    .catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },

  ['amenities.getAll'] ({ commit, state }, params) {
    commit('CLEAR_ERRORS')
    if (!params._q) params._q = null  
    return (http.get('all-amenities', {
      params
    }))
    .then ((res) => {
      commit('GET_ALL_AMENITIES', res);
    })
    .catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },

  ['amenities.sync'] ({ commit, state }) {
    commit('CLEAR_ERRORS')
    return (http.get('amenities/sync'))
    .then ((res) => {})
    .catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
  },

  ['amenities.update'] ({ commit },{id, display_name, icon}) {
    
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();

    formData.append('display_name',display_name);
    if (icon.length) {
      formData.append("icon", icon[0]);  
    }
    
    return (http.post(`amenities/${id}`, formData, config)).then ((res) => {
      commit('AMENITIES_UPDATE', res);
    }).catch ((res) => {
      commit('AMENITIES_ERROR', res);
    })
    
  },

}

// mutations
const mutations = {
  GET_PARKING_AMENITIES (state, res) {
    if (res)
      state.parking_amenities = res.data
  },

  GET_ROOM_AMENITIES (state, res) {
    if (res)
      state.room_amenities = res.data
  },

  GET_FACILITY_AMENITIES (state, res) {
    if (res)
      state.facility_amenities = res.data
  },

  GET_ALL_AMENITIES (state, res) {
    if (res)
      state.all = res.data
  },

  AMENITIES_UPDATE (state, res) {
    console.log('res', res)
    _.forEach(state.all.data, (item, i) => {
      console.log(item)
      if (item.id == res.data.id) {
        state.all.data[i] = _.assign(state.all.data[i], res.data)
      }
    });
    console.log('commiting upload image')
    state.all = _.cloneDeep(state.all);
  },

  //ERRORS
  AMENITIES_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  },

  
}

export default {
  state,
  getters,
  actions,
  mutations
}
