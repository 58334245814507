import { funcModal } from 'components/modals'
import  toastr  from 'toastr'

const components = {
  funcModal
}

const computed = {
  reservation () {
    return this.$store.state.reservations.active;
  }
}

const filters = {
}

const methods = {
  open () {
    this.reservation_email = this.reservation.reserved_by
    this.$refs.modal.open();
  },
  confirm() {
    this.loading = true
    this.errors = {};
    let data = {
      reservation_email: this.reservation_email
    }

    this.$store.dispatch('reservations.sendToEmail', {id: this.reservation.id, data: data}).then((res) => {
      this.loading = false

      if (this.$store.state.reservations.errors) {
        if (!_.isEmpty(this.$store.state.reservations.errors.errors)) {
          this.errors = _.cloneDeep(this.$store.state.reservations.errors.errors);
        }
        toastr.error(this.$store.state.reservations.errors.message);
      } else {
        toastr.success('Send to email secceeded')
        this.$refs.modal.close();
      }
    })

  }
}

export default {
  name: "view-resend-email-modal",
  components,
  methods,
  computed,
  filters,
  data () {
    return {
      errors: {},
      loading: false,
      reservation_email: ""
    }
  }
}
