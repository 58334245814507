import { http } from 'services'
// initial state
const state = () => ({
  active: {},
  all: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['coupons.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('coupons', {
      params
    })).then ((res) => {
      commit('GET_ALL_COUPONS', res);
    }).catch ((res) => {
      commit('COUPONS_ERROR', res);
    })
  },

  //get single location by id
  ['coupons.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')


    // return;
    return (Promise.all([
      http.get(`coupons/${id}`)
    ])).then(([
      port
    ]) => {
      commit('GET_COUPON', port);
    }).catch ((res) => {
      commit('COUPONS_ERROR', res);
    });
  },

  ['coupons.add'] ({commit}, data) {
    commit('CLEAR_ERRORS');

    return (http.post(`coupons`, data)).then ((res) => {
      commit('NEW_COUPON', res);
    }).catch ((res) => {
      commit('COUPONS_ERROR', res);
    })
  },

  ['coupons.edit'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`coupons/${id}`, data)).then ((res) => {
      commit('EDIT_COUPON', {res});
    })
    .catch ((res) => {
      commit('COUPONS_ERROR', res);
    })
  },

  ['coupons.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`coupons/${id}`)).then ((res) => {
      commit('REMOVE_COUPON', res);
    }).catch ((res) => {
      commit('COUPONS_ERROR', res);
    })
  },

  ['coupons.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`coupons/${id}?_undelete=true`)).then ((res) => {
      commit('UNDO_REMOVE_COUPON', res);
    }).catch ((res) => {
      commit('COUPONS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_COUPONS (state, res) {
    state.all = res.data;
  },

  REMOVE_COUPON (state, res) {
    console.log('state.all.data', state.all.data, res.data);
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.coupon.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_COUPON (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_COUPON (state, res) {
    state.active = _.assign(state.active, res.data);

    state.active = _.cloneDeep(state.active);
  },

  NEW_COUPON (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  EDIT_COUPON (state, {res}) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  COUPONS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
