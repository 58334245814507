import layout from 'layouts/default'
import { hasPermits } from 'directives'
import toastr from 'toastr'

const components = {
  layout,
}

const directives = {
  hasPermits
}

const computed = {
  languages () {
    return _.cloneDeep(this.$store.state.multilingual.langs);
  },

  faqCategories () {
    return _.cloneDeep(this.$store.state.faqs.categories);
  },

  formData () {
    return _.cloneDeep(this.$store.state.faqs.active);
  },
}


const methods = {
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('faqs.edit', {
      id: this.$route.params.id,
      data: this.formData,
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.faqs.errors) {
        this.errors = _.cloneDeep(this.$store.state.faqs.errors.errors);
        toastr.error(this.$store.state.faqs.errors.message);
      } else {
        toastr.success('Faq has been updated!');
      }
    })
  },
 
  wysiwygContentChanged: function(val) {
    this.formData.answer = val
  },

  getCategories () {
    let params = {_all: true};
    if (this.language) {
      console.log('languagexxx', this.language);
      params.language = this.language;
    }
    this.$store.dispatch('faqs.getAllCategories', params);
  }
}

export default {
  name: 'edit-faq',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      errors: {},
      langauge: null
    }
  },
  created () {
    this.loading = true;
    Promise.all([
      this.$store.dispatch('faqs.get', this.$route.params.id)
    ]).then(() => {
      this.loading = false;
      if (this.formData) {
        this.language = this.formData.category.language.id;  
        this.$store.dispatch('faqs.getAllCategories', {_all: true, language: this.language});
      }
      
      
    })
  }
}
