import toastr from 'toastr'
import moment from 'moment'
import layout from 'layouts/default'
import datepicker from 'components/datepicker'
import sSelect from 'components/sSelect'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
  datepicker,
  sSelect
}

const computed = {
  locations () {
    let empty = [{
      text: '-- Select Location --',
      value: null
    }];

    if (!_.isEmpty(this.$store.state.locations.all)) {
      return empty.concat(this.$store.state.locations.all.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      }))
    }

    return empty;
  },
}

const methods = {
  onSubmitFom () {
    this.errors = {}
    this.submit_loading = true;
    let data = _.cloneDeep(this.form);
    data.start_date = moment(data.start_date, ['DD/MMM/YYYY']).format('YYYY-MM-DD 00:00:00');
    data.expiry_date = moment(data.expiry_date, ['DD/MMM/YYYY']).format('YYYY-MM-DD 00:00:00');
    this.$store.dispatch('coupons.add', data).then(() => {
      this.submit_loading = false;
      if (this.$store.state.coupons.errors) {
        this.errors = _.cloneDeep(this.$store.state.coupons.errors.errors);
        toastr.error(this.$store.state.coupons.errors.message);
      } else {
        toastr.success('A new coupon has been created');
        this.$router.push({name: 'coupons.all'});
      }
    })
  },
  onSelectLocation (l) {
    if (this.form.locations.indexOf(l.value) === -1 && l.value != null) {
      this.form.locations.push(l.value);
      this.choosenLocations.push(l);
    }
    this.selectedLocation = {value: null, text: '-- Select Location --'};
  },
  onRemoveChosenLocation (location) {
    this.choosenLocations = this.choosenLocations.filter(
      (item) => item.value != location.value);
    this.form.locations = this.form.locations.filter(
      (item) => item != location.value);
  }
}

const watch = {
  ['form.all_locations'] (n) {
    if (n == true) {
      this.form.locations = [];
      this.choosenLocations = [];
    }
  }
}

export default {
  name: 'new-coupon',
  components,
  computed,
  methods,
  directives,
  watch,
  data () {
    return {
      loading: true,
      submit_loading: false,
      errors: {},
      form: {
        code: null,
        start_date: null,
        expiry_date: null,
        one_time_use: true,
        minimum_qualifying_days: null,
        fixed: true,
        discount_amount: null,
        all_locations: true,
        locations: []
      },
      selectedLocation: {value: null, text: '-- Select Location --'},
      choosenLocations: []
    }
  },
  created () {
    this.$store.dispatch('locations.getAll', {_all: true}).then(() => {
      this.loading = false
    });
  }
}
