var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    [
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c("div", { staticClass: "table-responsive" }, [
            _c("table", { class: _vm.tableClass }, [
              _c("thead", [
                _c(
                  "tr",
                  _vm._l(_vm.columns, function(column) {
                    return _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: column.permits,
                            expression: "column.permits",
                            arg: "allow"
                          }
                        ],
                        on: {
                          click: function($event) {
                            _vm._sort(column.object_name, column.sortable)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(column.name) + "\n          "
                        ),
                        column.sortable
                          ? _c("span", {
                              staticClass: "glyphicon glyphicon glyphicon-sort",
                              class: {
                                "glyphicon-sort-by-alphabet":
                                  _vm.sort_by === column.object_name &&
                                  _vm.sort_dir === "asc",
                                "glyphicon-sort-by-alphabet-alt":
                                  _vm.sort_by === column.object_name &&
                                  _vm.sort_dir === "desc"
                              }
                            })
                          : _vm._e()
                      ]
                    )
                  })
                )
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.rows, function(row) {
                  return _c(
                    "tr",
                    _vm._l(_vm.columns, function(column) {
                      return _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: column.permits,
                              expression: "column.permits",
                              arg: "allow"
                            }
                          ],
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              column.click(row)
                            },
                            hover: function($event) {
                              $event.preventDefault()
                              column.hover(row)
                            }
                          }
                        },
                        [
                          _vm._.isFunction(column.object_name)
                            ? _c("div", {
                                domProps: {
                                  innerHTML: _vm._s(
                                    column.object_name(row, _vm.$parent)
                                  )
                                }
                              })
                            : _c("div", [
                                _vm._v(
                                  _vm._s(
                                    _vm.objectPath.get(row, column.object_name)
                                  )
                                )
                              ])
                        ]
                      )
                    })
                  )
                })
              )
            ])
          ]),
      _vm._v(" "),
      _vm.pages > 1
        ? _c("pagination", {
            attrs: { "pages-number": _vm.pages },
            on: {
              pageChanged: function(page) {
                return _vm.loadPage(page)
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7782dff2", { render: render, staticRenderFns: staticRenderFns })
  }
}