import toastr from 'toastr'
import tz from 'moment-timezone'
import moment from 'moment'
import { dateFormat, currencySymbol } from 'filters'

const components = {
  toastr
}

const filters = {
}

const computed = {
  location_amenities () {
    return this.$store.state.locations.active.amenities || [];
  },
  reservation_errors () {
    let errors = this.$store.state.reservations.errors;
    if (errors) {
      return errors.errors ? errors.errors : {};
    }

    return {}
  },
  activeHistory () {
    let activeRes =  _.find(this.$store.state.reservations.active.history, h => h.active);

    if (_.isEmpty(activeRes)) {
      return {}
    }
    

    return activeRes;
  },
  locationExtraFields () {
    let extra_fields = [];
    if (!_.isEmpty(this.form.selectedLocation)) {
      return this.form.selectedLocation.extra_fields;
    }
    let diff = _.differenceBy(this.activeHistory.location.extra_fields, this.activeHistory.extra_fields,'id');    
    extra_fields = _.concat(this.activeHistory.extra_fields, diff);
    return extra_fields;
  },

  currenctCurrency () {
    return this.selected_currency == '' ? false : this.selected_currency;
  }
}

const methods = {
  checkHoursBeforeReservation (location, reservationType) {
    let timezone = moment().tz(this.airport.timezone.code);
    let timezone_difference = timezone.format('Z');
    let condition = true;

    switch (reservationType) {
      case 'parking':
        condition = timezone.add(location.hours_before_reservation, 'hours')
            .isAfter(moment(this.fromDate + " " + this.fromTime, ['DD/MMM/YYYY hh:mm A'])
            .format('YYYY-MM-DDTHH:mm:SS' + timezone_difference))
        break;
      case 'room':
      case 'both':
        condition = false;
      break;
    }
    console.log('condition', condition);
    return condition;
  },

  selectLocation (location) {
    this.form.errors = {};
    this.form.search_result_loading = true;
    if (this.checkHoursBeforeReservation(location, this.reservationType)) {
        this.form.errors.hours_before_reservation = [`This location does not accept
          reservations that start within
          ${location.hours_before_reservation} hours.
          Please search and choose from our
          other ${this.airport.name} locations`];
        this.form.search_result_loading = false;
        return;
    }
    Promise.all([
        this.$store.dispatch('locations.getAmenities', location.id),
        this.$store.dispatch('locations.getTypes', {
          id: location.id,
          params: {reservation_type: this.reservationType}
        })
      ]).then(() => {
        this.form.search_result_loading = false;
        if (this.$store.state.locations.errors) {

          for (let i in this.$store.state.locations.errors.errors) {
            toastr.error(this.$store.state.locations.errors.errors[i][0]);
          }
        } else {

          this.form.selectedLocation = _.clone(location);
          this.$store.dispatch('reservations.clearCosts');

          this.$set(this.form.selectedLocation, 'amenities', _.cloneDeep(this.$store.state.locations.active.amenities));
          this.$set(this.form.selectedLocation, 'types', _.cloneDeep(this.$store.state.locations.active.types));

          if (this.form.selectedLocation.extra_fields) {
            _.forEach(this.form.selectedLocation.extra_fields, (item, i) => {
              this.$set(this.extra_fields, item.name, [""]);
            });
          }

          this.$parent.$emit('selectLocation', this.form.selectedLocation);
          this.$emit('setParentLocation', this.form.selectedLocation);
        }
      }) 
    
  },

  onChangeExtraFields () {
    this.$emit('changeExtraFields', this.extra_fields);
  },

  getEmptyExtraFileds () {
    let extra_fields = [];
    let location_extra_fields = _.cloneDeep(this.activeHistory.location.extra_fields);
    let filled_extra_fields = _.cloneDeep(this.activeHistory.extra_fields);
    let diff = _.differenceBy(location_extra_fields, filled_extra_fields,'id');
    extra_fields = _.concat(filled_extra_fields, diff);
    _.forEach(extra_fields, (item) => {
      this.$set(this.extra_fields, item.name, item.value || [""]);
    })
  },

  decreaseNumberOfSpots () {
    for (let i in this.locationExtraFields) {
      if (this.locationExtraFields[i].per_car) {
        while (this.extra_fields[this.locationExtraFields[i].name].length > this.numberOfSpots) {
          this.extra_fields[this.locationExtraFields[i].name].pop();
        }
     }
    }
    this.$emit('changeExtraFields', this.extra_fields);
  },
}


const watch = {
  searchType (n) {
    if (n == 'same_location') {
      _.forEach(this.activeHistory.extra_fields, (item) => {
        this.$set(this.extra_fields, item.name, item.value || ['']);
      })
    }
  },

  searchLoading (n) {
    if (n && this.deselectLocation) {
      this.form.location_id = null;
    }
  },

  clearErrors (n) {
    this.form.errors = {};
  },

  selectedCurrency (n, o) {
    if (n == o) return;
    this.selected_currency = n;
  },

  selectedLocation (n, o) {
    // if (n == o) return;
    this.form.selectedLocation = n;
    this.form.location_id = n.id;
  },

  numberOfSpots (n, o) {
    if (n == o) return;
    if (n < o) {
      this.decreaseNumberOfSpots();
    }
  },
}

export default {
  name: "search-results",
  props: [
    'searchResults',
    'editMode',
    'searchLoading',
    'searchType',
    'symbol',
    'airport',
    'fromDate',
    'fromTime',
    'toDate',
    'toTime',
    'reservationType',
    'numberOfSpots',
    'clearErrors',
    'selectedCurrency',
    'selectedLocation',
    'deselectLocation',
    
  ],
  components,
  methods,
  computed,
  filters,
  watch,
  data () {
    return {
      currencySymbol,
      form: {
        selectedLocation: this.selectedLocation || {},
        booking_options: [],
        location_id: !this.selectedLocation ? null : this.selectedLocation.id,
        search_result_loading: false,
        errors: {},
      },
      selected_currency: this.selectedCurrency,
      extra_fields: {}
    }
  },
  created () {
    console.log('inside search result component')
  },
  mounted() {
    console.log('inside search result component mounted');
    if (!_.isEmpty(this.form.selectedLocation) || this.editMode) {
      this.getEmptyExtraFileds();
    }
  }
}
