import env from 'constants/env'
import layout from 'layouts/default'
import {tabs, tab} from 'components/tabs'
import { hasPermits } from 'directives'
import toastr from 'toastr'

let components = {
  layout,
  tabs,
  tab
}

if (!isServer) {
  components.tinymce = require('components/tinymce').default;
}

const directives = {
  hasPermits,
}

const computed = {
  langs () {
    return this.$store.state.multilingual.langs;
  },
  formData () {
    let page = _.cloneDeep(this.$store.state.pages.active);
    page.translations = _.filter(page.translations, (item) => {
      if (!item.language) return false;
      return item.language.code == this.lang
    });

    if (page.translations[0])
      page = _.assign(page, page.translations[0]);

    return page;
  },

  translations () {
    return this.$store.state.pages.active.translations
  }
}

const methods = {
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('pages.edit', {
      id: this.$route.params.id,
      data: this.formData,
      lang: this.lang
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.pages.errors) {
        this.errors = _.cloneDeep(this.$store.state.pages.errors.errors);
        toastr.error(this.$store.state.pages.errors.message);
      } else {
        toastr.success("Page has been updated!");
      }
    })
  },
  getPage () {
    this.loading = true;
    this.$store.dispatch('pages.get', this.$route.params.id).then(() => {
      this.loading = false;
    });
  }
}

export default {
  name: 'edit-page',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      activeTab: null,
      lang: env.default.lang,
      errors: {}
    }
  },
  created () {
    this.getPage();
  }
}
