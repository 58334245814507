import layout from 'layouts/default'
import pagination from 'components/pagination'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'

const components = {
  layout,
  pagination,
  confirm
}

const directives = {
  hasPermits
}

const methods = {
  loadPage (page) {
    this.loading = true;
    this.$store.dispatch('roles.getAll', {
      page, _q: this.searchKey,
      type: this.type !== 'all' ? this.type : null
    }).then (() => {
      this.loading = false;
    });
  },
  search (searchKey) {
    this.searchKey = searchKey;
    this.loadPage(1);
  },
  removeRole (item) {
    this.$refs.deleteConfirm.confirm('Are you sure?', () => {
      this.roles_loadings[item.id] = true

      this.$store.dispatch('roles.removeRole', item.id).then(() => {
        this.roles_loadings[item.id] = false

        if (this.$store.state.roles.errors) {
          this.errors = _.cloneDeep(this.$store.state.roles.errors.errors);
          if (this.errors.hasOwnProperty('message')) {
            toastr.error(this.errors.message);
          }
        }
      })
    });
  }
}

const computed = {
  roles () {
    let roles = _.cloneDeep(this.$store.state.roles.all);
    _.each(roles.data, (role) => {
      this.roles_loadings[role.id] = false;
    });
    this.roles_loadings = _.clone(this.roles_loadings);
    return roles;
  }
}

export default {
  name: 'all-roles',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      loading: true,
      searchKey: null,
      roles_loadings: {},
      type: 'all'
    }
  },
  created () {
    this.loadPage (this.$route.query.page || 1);
  }
}
