import moment from 'moment'
import layout from 'layouts/default'
import env from 'constants/env'
import { hasPermits } from 'directives'
import datepicker from 'components/datepicker'
import { exportReport } from 'components/modals'
import deferredRevenues from './deferredRevenues'
import deferredRevenuesEmails from './deferredRevenuesEmails'
import { permits } from 'services'
import autocomplete from 'components/autocomplete'
import commission from './commission'

const directives = {
  hasPermits
}

let components = {
    layout, datepicker,autocomplete,commission,
    exportReport, deferredRevenues, deferredRevenuesEmails,
}

let methods = {
    onSelectReport (report) {
        if (report) {
            this.show_run = true;
        }

        this.report_type = report;
        this.filters = Object.assign({}, this.default_filters);
    },
    
    showFilter (filter) {
        if (!this.report_type) return false;

        switch (filter) {
            case 'dates':
                return ['location', 'credit', 'deferredRevenues'].indexOf(this.report_type) == -1
            case 'reservation_id':
            case 'status':
            case 'modification':
            case 'due_at_location':
            case 'commission_dates':
                return ['commission'].indexOf(this.report_type) > -1
            case 'trip_protection':
                return ['tripprotection'].indexOf(this.report_type) > -1
            case 'location':
                return ['location', 'commission', 'blackout', 'reviews', 'arrivals'].indexOf(this.report_type) > -1
            case 'customer':
                return ['credit', 'commission', 'arrivals'].indexOf(this.report_type) > -1
            case 'timezone':
                return this.filters.filter_date_on == "created_at"
        }
    },
    
    onSelectLocation ({item, input}) {  
        let location_id = item;
        if (item) {
            location_id = item.id;
        }    
        this.filters.location_id = location_id
    },

    setFilters () {
        this.errors = {};

        let filters = _.cloneDeep(this.filters);
        if (this.showFilter('dates')) {
            if (filters.from_date) {
                filters.from_date = moment(filters.from_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
            } else {
                this.$set(this.errors, 'from_date', ['From date field is required']);
            }
            
            if (filters.to_date) {
                filters.to_date = moment(filters.to_date, ['MM/DD/YYYY']).format('YYYY-MM-DD');
            } else {
                this.$set(this.errors, 'to_date', ['To date field is required']);
            }
        }
        
        if (this.report_type == 'deferredRevenues') {
            if (filters.from) {
                filters.from_date = moment(this.filters.from, [this.dateFormat]).startOf('month').format('YYYY-MM-DD');
                delete filters.from;
            }
            if (filters.to) {
                filters.to_date = moment(this.filters.to, [this.dateFormat]).endOf('month').format('YYYY-MM-DD');
                delete filters.to;
            }
          }
        return filters;
    },

    run () {
        let filters = this.setFilters();

        if (!_.isEmpty(this.errors)) return;

        this.$refs.table.run(filters);
    },

    exportReport (ext) {
        let filters = this.setFilters();
        if (!_.isEmpty(this.errors)) return;
        
        let url = this.$refs.table.url + '?ext=' + ext;
        let params = '';
        _.each(filters, function (value, key) {
            if (value)
              params += '&' + (key + '=' + value);
        });

        url += params;

        this.$refs.export.open(url, ext);
    },

    nextMonth (e) {
        e.preventDefault();
        this.filters.from = moment(this.filters.from, [this.dateFormat]).add(1, 'month').startOf('month').format(this.dateFormat);
        this.filters.to = moment(this.filters.from, [this.dateFormat]).endOf('month').format(this.dateFormat);    
      },
    
    prevMonth (e) {
        e.preventDefault();
        let prev = moment(this.filters.from, [this.dateFormat]).subtract(1, 'month')
        this.filters.from = prev.startOf('month').format(this.dateFormat);
        this.filters.to = prev.endOf('month').format(this.dateFormat);
    },

    editDate (date) {
        this.filters.from = moment(date.from).format('YYYY-MM-DD');
        this.filters.to = moment(date.to).format('YYYY-MM-DD');
    }
}

let computed = {
    trip_protections () {
        return this.$store.state.tripProtections.all;
    }
}

let watch = {
    ['filters.from'] (n, o) {
        let from_date_unformated = moment(this.filters.from, [this.dateFormat])
        let to_date_unformated = moment(from_date_unformated);
        let from = _.cloneDeep(from_date_unformated.startOf('month').format(this.dateFormat));
        let to = _.cloneDeep(to_date_unformated.endOf('month').format(this.dateFormat));
        this.filters.from = from;
        this.filters.to = to;
      },
}

export default {
    name: "reports",
    components,
    methods,
    computed,
    watch,
    directives,
    data () {
        let dateFormat = 'MMMM/YYYY';
        let from_date_unformated = moment().subtract(1,'months').startOf('month');
        let from = from_date_unformated.format(dateFormat);
        let to_date_unformated = moment().subtract(1,'months').endOf('month');
        let to = to_date_unformated.format(dateFormat);
        return {
            errors: {},
            env: env.default,
            processing: false,
            loading: false,
            syncing: false,
            show_run: false,
            report_type: null,
            default_filters: {
                from_date: null,
                to_date: null,
                filter_date_on: 'to_date', //from_date, to_date
                location_id: null,
                reservation_id: null,
                customer_name: null,
                active: null, //true, false
                modified: null, //true, false
                cancelled: null, //true, false
                due_at_location: null, //true, false
                trip_protection_id: null,
                timezone: 'EST',
                from,
                to,
            },
            filters: {},
            date_picker_settings: {
                changeMonth: true,
                changeYear: true,
                showButtonPanel: true,
                dateFormat: 'MM/yy',
            },
            dateFormat,
        }     
    },

    mounted () {
        this.filters = Object.assign({}, this.default_filters);
        if (permits.hasPermits('super_admin')) {
            this.$store.dispatch('deferredRevenuesEmails.getAll');
        }
    }
}