var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Resend Reservation Via Email" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _vm.loading
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { disabled: _vm.loading, type: "button" }
                    },
                    [
                      _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                      _vm._v("\n    SENDING...\n  ")
                    ]
                  )
                : _c(
                    "button",
                    {
                      staticClass: "btn btn-success",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.confirm($event)
                        }
                      }
                    },
                    [_vm._v("\n    Send\n  ")]
                  ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("div", { staticClass: "margin-top-3x" }, [
          _c("label", { attrs: { for: "" } }, [_vm._v("Email:")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.reservation_email,
                expression: "reservation_email"
              }
            ],
            staticClass: "form-control",
            domProps: { value: _vm.reservation_email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.reservation_email = $event.target.value
              }
            }
          })
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-43c0183b", { render: render, staticRenderFns: staticRenderFns })
  }
}