import toastr from 'toastr'
import layout from 'layouts/default'
import pagination from 'components/pagination'
import confirm from 'components/modals/confirm'
import draggable from 'components/draggable'
import { hasPermits } from 'directives'
import { viewFaqCategory } from './../partials'

const components = {
  layout,
  pagination,
  confirm,
  draggable,
  viewFaqCategory
}

const directives = {
  hasPermits
}

const methods = {
  showFaqCategoryDetails (item) {
    this.view_loadings[item.id] = true;
    this.view_loadings = _.cloneDeep(this.view_loadings);

    this.$store.dispatch('faqCategories.get', item.id).then(() => {
      this.view_loadings[item.id] = false;
      let errors = this.$store.state.faqs.errors;
      if (errors) {
        toastr.error(errors.message);
      } else {
        this.$refs.view.open();
      }

    });

    return false;
  },

  loadFaqCategories () {
    let data = {};
    this.loading = true;

    if (this.filter == 'deleted') {
      data._only_deleted = 'true';
    } else if (this.filter == 'all') {
      data._with_deleted = 'true';
    }
    if (this.searchKey) {
      data._q = this.searchKey;
    }

    this.$store.dispatch('faqCategories.getAll', data).then (() => {
      this.loading = false;
    });
  },
  updateFaqOrder (list) {
    this.loading = true;
    this.$store.dispatch('faqCategories.updateAll', {data: list}).then(() => {
      this.loading = false;
    })
    ;
  },
  search (searchKey) {
    this.searchKey = searchKey;
    this.loadFaqCategories();
  },

  removeFaqCategory (item) {
    this.$refs.confirmDelete.confirm(
    'are you sure that you want to delete this FAQ Category?',
    () => {
      this.remove_loadings[item.id] = true;
      this.remove_loadings = _.cloneDeep(this.remove_loadings);

      this.$store.dispatch('faqCategories.remove', item.id).then(() => {
        this.remove_loadings[item.id] = false;
        console.log('this.$store.state.faqCategories.errors', this.$store.state.faqCategories.errors);
        let errors = this.$store.state.faqCategories.errors;
        if (errors) {
          toastr.error(errors.message);
        } else {
          toastr.success('FAQ category has been successfully deleted!');
        }
      });
    });
  },

  undoRemove (item) {
    this.$refs.confirmUndoDelete.confirm(
    'Are you sure that you want to undelete this FAQ Category?',
    () => {
      this.remove_loadings[item.id] = true;
      this.remove_loadings = _.cloneDeep(this.remove_loadings);

      this.$store.dispatch('faqCategories.undoRemove', item.id).then(() => {
        this.remove_loadings[item.id] = false;
        console.log('this.$store.state.faqCategories.errors', this.$store.state.faqCategories.errors);
        let errors = this.$store.state.faqCategories.errors;
        if (errors) {
            toastr.error(errors.message);
        }
      });
    });
  },

  filterChanged () {
    console.log(this.filter);
    this.loadFaqCategories();
  }
}

const computed = {
  faqCategories () {
    return _.cloneDeep(this.$store.state.faqCategories.all)
  }
}

export default {
  name: 'FAQ-Categories',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      loading: true,
      searchKey: null,
      view_loadings: {},
      remove_loadings: {},
      filter: 'active',
    }
  },
  created () {
    this.loadFaqCategories ();
  },
  mounted () {

  }
}
