import layout from 'layouts/default'
import {editReservationModule} from './partials'

const components = {
  layout,
  editReservationModule
}

export default {
  name: 'edit-reservation',
  components,
  data () {
    return {

    }
  },
}
