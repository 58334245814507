import { funcModal } from 'components/modals'
import  toastr  from 'toastr'
import confirm from 'components/modals/confirm'
import { stripeService } from 'services'

const components = {
  funcModal,
  confirm,
}

const computed = {
  reservation () {
    return this.$store.state.reservations.active;
  },

  activeHistory () {
    return _.find(this.$store.state.reservations.active.history, function (h) {return h.active == 1;});
  }
}

const filters = {
}

const methods = {
  open () {
    this.$refs.modal.open();
    window.top.postMessage({name: 'popup', tyoe: 'revertCancel'}, "*");
  },

  confirm() {
    this.loading = true
    this.errors = {};

    let data = {
      id: this.reservation.id,
      charging_customer:this.charging_customer
    };

    this.$store.dispatch('reservations.revert_remove', data).then((res) => {
      this.loading = false;

      let errors;
      if (errors = this.$store.state.reservations.errors) {
        if (errors.message) {
          toastr.error(errors.message);
        } else {
          toastr.error(errors.error);
        }
      } else {
        toastr.success('Reservation has been reverted cancelation');
        let reservation = this.$store.state.reservations.active;
        let reslabFailedMessage = reservation.reslab_failed_message ? reservation.reslab_failed_message : false;
        if (reslabFailedMessage) {
          this.$refs.warningConfirm.confirm(reslabFailedMessage, () => {this.$refs.modal.close();}, () => {this.$refs.modal.close();}, false)
        } else {
          this.$refs.modal.close();
        }
      }       
    });
  },
  
}

export default {
  name: "view-revert-cancel-modal",
  components,
  methods,
  computed,
  filters,
  data () {
    return {
      errors: {},
      loading: false,
      charging_customer: true,
    }
  },
  mounted () {
  }
}
