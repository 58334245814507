import layout from 'layouts/default'
import toastr from 'toastr'
import {editReservationModule} from './partials'

const components = {
  layout,
  editReservationModule
}

const computed = {
  reservation () {
    let res =  _.cloneDeep(this.$store.state.reservations.active);
    return res
  }
}

export default {
  name: 'edit-reservation',
  components,
  computed,
  data () {
    return {
      wHeight: 0,
    }
  },
  created () {
  },

  mounted () {
    const interval = setInterval(() => {
      let height = document.body.scrollHeight;
      if (this.$refs.editRes && height != this.wHeight) {
        this.wHeight = height;     
        let message = { 
          height: height + 30,
          width: document.body.scrollWidth 
        };	
    
        // window.top refers to parent window
        window.top.postMessage(message, "*");
      }   
    }, 300);


    toastr.error = function() {
      let args = Array.from(arguments);
      if (typeof args[0] === 'string' || args[0] instanceof String) {
        let message = {
          name: 'error',
          errorMessage: args[0]
        }
        window.top.postMessage(message, "*");
      }
    }
  },
}
