var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "View Reservation Details", backdrop: false },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "tabs",
            [
              _c("tab", { attrs: { title: "Current State" } }, [
                _vm.activeHistory
                  ? _c(
                      "table",
                      { staticClass: "table table-bordered margin-top-2x" },
                      [
                        _c(
                          "tbody",
                          [
                            _c("tr", [
                              _c("th", [_vm._v("Reservation Number")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(_vm.reservation.reservation_number)
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Reservation Type")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm.activeHistory.reservation_type == "both"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                  Rooms & Parkings\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.activeHistory.reservation_type == "room"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                  Rooms\n                "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.activeHistory.reservation_type == "parking"
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                  Parkings\n                "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Creation Date")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.reservation.created_at,
                                      "DD/MMM/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Latest Update")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.activeHistory.created_at,
                                      "DD/MMM/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Location Name")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm.activeHistory.location
                                      ? _vm.activeHistory.location.name
                                      : ""
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.activeHistory.bundle_type
                              ? _c("tr", [
                                  _c("th", [_vm._v("Bundle")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("table", { staticClass: "table" }, [
                                      _c("tr", [
                                        _c("th", [_vm._v("Bundle Name: ")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.activeHistory.bundle_info
                                                  .name
                                              ) +
                                              "\n                    "
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("th", [_vm._v("Includes: ")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.activeHistory.bundle_info
                                                  .number_of_sleeping_nights
                                              ) +
                                              "\n                      Nights &\n                      " +
                                              _vm._s(
                                                _vm.activeHistory.bundle_info
                                                  .number_of_parking_days
                                              ) +
                                              "\n                      Days of Parking\n                    "
                                          )
                                        ])
                                      ])
                                    ])
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm._l(_vm.activeHistory.rooms, function(room, i) {
                              return _vm.activeHistory.rooms.length
                                ? _c("tr", [
                                    _c("th", [_vm._v("Room " + _vm._s(i + 1))]),
                                    _vm._v(" "),
                                    _c("td", [
                                      _c("table", { staticClass: "table" }, [
                                        _c("tr", [
                                          _c("th", [_vm._v("Room Type: ")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(room.room_type_name) +
                                                "\n                    "
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Guest Name: ")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                      " +
                                                _vm._s(room.guest_name) +
                                                "\n                    "
                                            )
                                          ])
                                        ])
                                      ])
                                    ])
                                  ])
                                : _vm._e()
                            }),
                            _vm._v(" "),
                            _vm.activeHistory.number_of_spots > 0
                              ? _c("tr", [
                                  _c("th", [_vm._v("Parking")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c("table", { staticClass: "table" }, [
                                      _c("tr", [
                                        _c("th", [_vm._v("Number of Spots: ")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.activeHistory
                                                  .number_of_spots
                                              ) +
                                              "\n                    "
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("th", [_vm._v("Parking Type: ")]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.activeHistory
                                                  .parking_type_name
                                              ) +
                                              "\n                    "
                                          )
                                        ])
                                      ]),
                                      _vm._v(" "),
                                      _c("tr", [
                                        _c("th", [
                                          _vm._v("Number of Parking Days: ")
                                        ]),
                                        _vm._v(" "),
                                        _c("td", [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.activeHistory
                                                  .number_of_parking_days
                                              ) +
                                              "\n                    "
                                          )
                                        ])
                                      ])
                                    ])
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Subtotal")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.activeHistory.subtotal,
                                      _vm.symbol(_vm.reservation.currency_code)
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Total Fees")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.activeHistory.total_fees,
                                      _vm.symbol(_vm.reservation.currency_code)
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Grand Total")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.activeHistory.grand_total,
                                      _vm.symbol(_vm.reservation.currency_code)
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("From")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.activeHistory.from_date,
                                      "DD/MMM/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("To")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      _vm.activeHistory.to_date,
                                      "DD/MMM/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Reserved By")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm.activeHistory.first_name +
                                        " " +
                                        _vm.activeHistory.last_name
                                    ) +
                                    "\n                -\n                " +
                                    _vm._s(_vm.reservation.reserved_by) +
                                    "\n              "
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Contact Email")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.activeHistory.email))
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Contact Phone")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(_vm._s(_vm.activeHistory.phone))
                              ])
                            ])
                          ],
                          2
                        )
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "tab",
                { attrs: { title: "History" } },
                _vm._l(_vm.reservation.history, function(item) {
                  return _vm.reservation.history
                    ? _c(
                        "table",
                        { staticClass: "table table-bordered margin-top-2x" },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Creation Date")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.created_at,
                                      "DD/MMM/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Contact Email")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(item.email))])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("From")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.from_date,
                                      "DD/MMM/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("To")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.to_date,
                                      "DD/MMM/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Subtotal")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      item.subtotal,
                                      _vm.symbol(_vm.reservation.currency_code)
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Total Fees")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      item.total_fees,
                                      _vm.symbol(_vm.reservation.currency_code)
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            item.channel_discount
                              ? _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      "\n                Channel Discount\n              "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "-" +
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.channel_discount,
                                            _vm.symbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.partial_refund
                              ? _c("tr", [
                                  _c("th", [
                                    _vm._v(
                                      "\n                Partial Refund\n              "
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "-" +
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.partial_refund,
                                            _vm.symbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            item.long_term_discount
                              ? _c("tr", [
                                  _c("th", [_vm._v("Long Term Discounts")]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _vm._v(
                                      "-" +
                                        _vm._s(
                                          _vm._f("currency")(
                                            item.long_term_discount,
                                            _vm.symbol(
                                              _vm.reservation.currency_code
                                            )
                                          )
                                        )
                                    )
                                  ])
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Grand Total")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      item.grand_total,
                                      _vm.symbol(_vm.reservation.currency_code)
                                    )
                                  )
                                )
                              ])
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                })
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-12bb2d01", { render: render, staticRenderFns: staticRenderFns })
  }
}