import _ from 'lodash'
import toastr from 'toastr'
import { funcModal } from 'components/modals'
import fileUpload from 'components/fileUpload'
import hasPermits from 'directives/hasPermits'

const filters = {

}

const directives = {
  hasPermits
}
const watch = {
  toggleModal (value) {
      if (value) 
      this.isOpen = value;

      if (this.isOpen) {
        this.open();
      } else {
        this.close();
      }
  },

  amenity (value) {
    if (value) {
      this.form = value;
    }
  }
}

const components = {
  funcModal,
  fileUpload
}

const methods = {
  submit (modal) {
    let errors = false;
    this.errors = {};

    //validating totals
    let data = _.cloneDeep(this.form)
    this.errors = {};
    this.files_processing = true;
    this.$store.dispatch('amenities.update', {
      id: this.form.id,
      display_name: this.form.display_name,
      icon: this.icon,
    }).then(() => {
      this.files_processing = false;
      console.log('this.$store.state.amenities.errors',this.$store.state.amenities.errors)
      if (this.$store.state.amenities.errors) {
        this.errors = _.cloneDeep(this.$store.state.amenities.errors.errors);
        toastr.error(this.$store.state.amenities.errors.message);
      } else {
        toastr.success('Data has been saved');
        this.$refs.file_upload.removeFiles();
        this.close();
      }
    });
  },
  
  iconToUpload (files) {
    if (files.length) {
      this.icon = files;
    } else {
      this.icon = [];
    }
  },
  open () {
    this.$refs.modal.open();
  },

  close () {
    this.$refs.modal.close();
  }
}

export default {
  name: 'edit-amenity',
  props: ['amenity','toggleModal'],
  methods,
  components,
  watch,
  directives,
  data() {
    return {
      errors: {},
      form: this.amenity,
      icon: [],
      files_processing: false,
      isOpen: this.toggleModal,
    }
  }
}
