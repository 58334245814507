<template>
    <a class="btn btn-default" href="#" @click.prevent="goBack">
        <i class="fa fa-angle-left" aria-hidden="true"></i>
        back
    </a>
</template>
<script>
export default {
    name: 'page-go-back',
    methods: {
        goBack() {
            window.history.back();
        }
    }
}
</script>