import toastr from 'toastr'
import layout from 'layouts/default'
import { currencySymbol } from 'filters'
import datatable from 'components/datatable'
import datepicker from 'components/datepicker'
import confirm from 'components/modals/confirm'
import { viewReservation, resendEmail } from './partials'
import { hasPermits } from 'directives'
import moment from 'moment'

const directives = {
  hasPermits
}

const components = {
  layout,
  datatable,
  confirm,
  viewReservation,
  resendEmail,
  datepicker
}

const watch = {

}

const methods = {
  showReservationDetails (item_id) {
    this.loadings[item_id] = true
    this.$store.dispatch('reservations.get', item_id).then(() => {
      this.loadings[item_id] = false;
      let errors;
      if (errors = this.$store.state.reservations.errors) {
        if (errors.message) {
          toastr.error(errors.message);
        } else {
          toastr.error(errors.error);
        }
      } else {
        this.$refs.view.open();
      }
    });

    return false;
  },

  applyFilter() {
    let filter = _.clone(this.filter)

    filter.created_at = filter.created_at ? moment(filter.created_at, ['DD/MMM/YYYY']).format('YYYY-MM-DD') : null
    filter.from_date = filter.from_date ? moment(filter.from_date, ['DD/MMM/YYYY']).format('YYYY-MM-DD') : null
    filter.to_date = filter.to_date ? moment(filter.to_date, ['DD/MMM/YYYY']).format('YYYY-MM-DD') : null

    this.dataTableFilters = filter

    if (this.show) 
      this.$refs.reservationsListRef.resetPageNumber(1)

    this.show = true
  
  },

  openReservationResendEmailModal(item_id) {
    this.loadings[item_id] = true
    this.$store.dispatch('reservations.get', item_id).then(() => {
      this.loadings[item_id] = false;
      let errors;
      if (errors = this.$store.state.reservations.errors) {
        if (errors.message) {
          toastr.error(errors.message);
        } else {
          toastr.error(errors.error);
        }
      } else {
        this.$refs.resendEmail.open();
      }
    });

    return false;
  }
}

export default {
  name: 'all-reservations',
  components,
  methods,
  directives,
  watch,
  data () {
    return {
      loading: false,
      searchKey: null,
      loadings: {},
      show: false,
      selectedPage: 1,
      filter: {
        _q: null,
        created_at: null,
        from_date: null,
        to_date: null,
        status: null,
        cancelled: null,
        _sort:'-id'
      },
      columns: [
            {name: "ID", object_name: "reservation_number", sortable: true},
            {name: "Reserved By", object_name: "reserved_by", sortable: true},
            {name: "From", object_name: (obj, parent) => {
              return moment(obj.history[0].from_date, ['YYYY-MM-DD HH:mm:ss']).format('DD/MMM/YYYY hh:mm A');
            }},
            {name: "To", object_name: (obj, parent) => {
              return moment(obj.history[0].to_date, ['YYYY-MM-DD HH:mm:ss']).format('DD/MMM/YYYY hh:mm A');
            }},
            {name: "Subtotal", object_name: (obj, parent) => {
              return parent.$options.filters.currency(obj.history[0].subtotal, currencySymbol(obj.currency_code));
            }},
            {name: "Total Fees", object_name: (obj, parent) => {
              return parent.$options.filters.currency(obj.history[0].total_fees, currencySymbol(obj.currency_code));
            }},
            {name: "Grand Total", object_name: (obj, parent) => {
              return parent.$options.filters.currency(obj.history[0].grand_total, currencySymbol(obj.currency_code));
            }},
            {name: "Location", object_name: "history.0.location.name"},
            {name: "# Of Parking", object_name: "history.0.number_of_spots"},
            {name: "# Of Rooms", object_name: "history.0.number_of_rooms"},
            {name: "Cancelled", object_name: (obj, parent) => {
              return obj.cancelled ? 'Yes' : 'No';
            }},
            {
              name: "Edit",
              permits: ['reservation_update'],
              object_name: (obj, parent) => {
                return `<a href="#">
                  <i class="fa fa-pencil-square-o" aria-hidden="true"></i>
                </a>`;
              },
              click: (obj) => {
                this.$router.push({
                  name: 'reservations.edit',
                  params: {
                    id: obj.id
                  }
                });
              }
            },
            {
              name: "View",
              object_name: (obj, parent) => {
                return `<a href="#">
                  <i class="fa fa-eye" aria-hidden="true"></i>
                </a>`;
              },
              click: (obj) => {
                this.showReservationDetails(obj.id);
              }
            },
            {
              name: "Resend Email",
              object_name: (obj, parent) => {
                return `<a href="#">
                  <i class="fa fa-envelope" aria-hidden="true"></i>
                </a>`;
              },
              click: (obj) => {
                this.openReservationResendEmailModal(obj.id);
              }
            },
            
          ],
        dataTableFilters: {}
    }
  },
  created () {

  },
  mounted () {
    let created_reservation = sessionStorage.getItem('__new_reservation__');

    if (created_reservation = JSON.parse(created_reservation)) {
      this.showReservationDetails(created_reservation.id);
      sessionStorage.setItem('__new_reservation__', JSON.stringify(null));
    }
  }
}
