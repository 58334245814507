import { http } from 'services'
import _ from 'lodash'

// initial state
const state = () => ({
  all: [],
  active: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['reviews.getAll'] ({ commit }, params) {
    if (!params._q) params._q = null

    commit('CLEAR_ERRORS')

    return (http.get('reviews', {
      params
    })).then ((res) => {
      commit('GET_ALL_REVIEWS', res);
    })
    .catch ((res) => {
      commit('REVIEWS_ERROR', res);
    })
  },
  ['reviews.editReview'] ({ commit }, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put('reviews/' + id , data))
    .then ((res) => {
      commit('EDIT_REVIEW', res);
    })
    .catch ((res) => {
      commit('REVIEWS_ERROR', res);
    })
  },
  ['reviews.getReview'] ({ dispatch, commit, state }, id) {
    commit('CLEAR_ERRORS')
      return (http.get('reviews/' + id))
      .then ((res) => {
        commit('GET_REVIEW', res);
      })
      .catch ((res) => {
        commit('REVIEWS_ERROR', res);
      })
    // })
  }
}

// mutations
const mutations = {
  GET_ALL_REVIEWS (state, res) {

    state.all = res.data;
  },

  GET_REVIEW (state, res) {
    state.active = res.data
  },

  EDIT_REVIEW (state, res) {
    state.active = res.data

    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  //ERRORS
  REVIEWS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
