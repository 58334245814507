import { hasPermits } from 'directives'
import sSelect from 'components/sSelect'

const directives = {
  hasPermits
}

const components = {
  sSelect
}

const computed = {
  countries () {
    return [{name: "Select Country", id: ""}].concat(this.$store.state.address.countries);
  },

  channelName () {
    return isAffiliate ? this.$store.state.affiliates.active.name : "TT";
  },
  
  states () {
    let states = [{name: "Select State", id: ""}];
    if (this.country) {

      let indx = _.findIndex(this.countries, item => item.id == this.country);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }
    console.log(states);
    return states;
  },
  distancePlaceholder() {
    let unit = '';
    if (this.form.unit) {
      unit = this.form.unit == 'mile' ? 'miles' : 'kilometers';
    }
    return `Distance in ${unit}`;
  }
}

const methods = {
  onChangeCountry () {
    this.formData.country_id = this.country;
    this.state = "";
  },
  onChangeState () {
    this.formData.state_id = this.state;
  },
  onChangeAirport (port) {
    this.selectedAirport = port;
    this.formData.airports = [port.value];
  },
  changeNetparkKey (files) {
    this.$set(this.form, 'netpark_key', files[0]);
  }
}

const watch = {
  formData (n) {
    this.form = n
    if (this.form.airports.length) {
      this.form.distance_from_airport = this.form.airports[0].pivot.distance_from_airport;
      let selectedAirportIndx = _.findIndex(this.ports, port => port.value == this.form.airports[0].id);
      this.onChangeAirport(this.ports[selectedAirportIndx]);
    }
  }
}

export default {
  name: 'general-tab',
  props: ['formData', 'errors'],
  directives,
  computed,
  methods,
  watch,
  components,
  data () {

    let ports = [{text: "-- Select Airport --", value: null}];

    return {
      country: _.cloneDeep(this.formData.country_id),
      state: _.cloneDeep(this.formData.state_id),
      form: this.formData,
      ports,
      channel_name: isAffiliate ? this.$store.state.affiliates.active.name : "TT",
      selectedAirport: ports[0]
    }
  },
  created () {
    this.$store.dispatch('ports.getAll', {_all: true}).then(() => {
      this.ports = this.ports.concat(this.$store.state.ports.all.map(item => ({text: item.name, value: item.id})));
      if (this.form.airports.length) {
        this.form.distance_from_airport = this.form.airports[0].pivot.distance_from_airport;

        let selectedAirportIndx = _.findIndex(this.ports, p => p.value == this.form.airports[0].id);
        this.onChangeAirport(this.ports[selectedAirportIndx]);
      }
    });
  }
}
