import objectPath from 'object-path'
import pagination from 'components/pagination'
import { http } from 'services'
import { hasPermits } from 'directives'

const components = {
  pagination
}

const directives = {
  hasPermits
}

export default {
  name: 'datatable',
  components,
  directives,
    props: {
    ajaxUrl: {
      type: String,
      default: null
    },
    tableClass: {
            type: String,
            default: 'table'
        },
    columns: [Object, Array],
    data: [Object, Array, String, Function],
    filters: {
            type: [Object, Array],
            default: null
        },
    sort: {
      type: Function,
    },
    page: {
      type: Number,
    }
  },
    data() {
        return {
      sort_by: null,
          sort_dir: null,
      rows: [],
      current_page: 1,
      pages: 1,
      objectPath,
      loading: false
    }
    },
    computed: {
    numberOfPages: () => {
      return 10;
    }
    },
  watch: {
    filters: function(value) {
      this.buildRows()
    },
    columns (n, o) {
      if (n == o) return;

      this.setCols();
    }
  },
    methods: {
    _sort(object_name, sortable) {

      if (!sortable) {
        return;
      }

      this.$emit('sortChanged', this.sort_by, this.sort_dir)

      if (this.sort_by == object_name) {
        this.sort_dir = this.sort_dir == 'asc' ? 'desc' : 'asc';
      } else {
        this.sort_by = object_name
        this.sort_dir = 'asc'
      }

      if (this.ajaxUrl) {
        this.buildRows()
        return;
      }

      return _.orderBy(this.rows, [this.sort_by], [this.sort_dir]);
    },

    buildRows() {
      if (this.ajaxUrl) {
        this.getDataFromAjax()
      } else {
        this.rows = this.data;
      }
    },

    loadPage(page) {
      this.current_page = page
      this.getDataFromAjax();
    },

    resetPageNumber(page) {
      let query = Object.assign({}, this.$route.query);
      query.page = page;
      this.$router.replace({ query });
      this.current_page = page
    },

    getDataFromAjax() {

      // Build get params
      var params = ''
      if (this.ajaxUrl.indexOf('?') > -1) {
        params = '&page=' + this.current_page
      } else {
        params = '?page=' + this.current_page
      }

      if (this.sort_by) {
        params += '&_sort=' + (this.sort_dir == 'desc' ? '-' : '') + this.sort_by;
      }

      if (this.filters) {
        _.each(this.filters, function(value, key) {
          if (value)
            params += '&' + (key + '=' + value);
        });
      }

      this.loading = true;
      http.get(this.ajaxUrl + params)
      .then ((res) => {
        this.loading = false
        this.rows = res.data.data
        this.pages = res.data.last_page
      })
    },
    setCols () {
      for (let i in this.columns) {
        if (!_.isFunction(this.columns[i].click)) {
          this.columns[i].click = () => {};
        }
        if (!_.isFunction(this.columns[i].hover)) {
          this.columns[i].hover = () => {};
        }
      }
    }
    },
    created(){
    this.buildRows()
    this.setCols();
    }
}
