import env from 'constants/env'
import toastr from 'toastr'
import layout from 'layouts/default'
import { tabs, tab } from 'components/tabs'
import { general, fees, notes, amenities, notesForm } from '../partials'
import { hasPermits } from 'directives'
import moment from 'moment'


const directives = {
  hasPermits
}

const components = {
  layout,
  tabs,
  tab,
  general,
  fees,
  notes,
  amenities,
  notesForm,
}

const methods = {
  getLocation () {
    this.loading = true;
    this.$store.dispatch('locations.get', this.$route.params.id).then(() => {
      this.loading = false;
    });
  },
  showSaveModal () {
    this.$refs.notes.open();
  },
  errorWhenSaveLocation (errors) {
    this.errors = errors;
  }
}

const computed = {
  langs () {
    console.log('this.$store.state.multilingual.langs', this.$store.state.multilingual.langs);
    return this.$store.state.multilingual.langs;
  },

  formData () {
    let location = _.cloneDeep(this.$store.state.locations.active);
    console.log('location', location);
    location.translations = _.filter(location.translations, (item) => {
      if (!item.language) return false;
      return item.language.code == this.lang
    });

    console.log("location.translations", location.translations);

    if (location.translations[0])
      location = _.assign(location, location.translations[0]);

    return location;
  },

  translations () {
    return this.$store.state.locations.active.translations
  }
}

export default {
  name: 'edit-location',
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      activeTab: null,
      errors: {},
      lang: env.default.lang,
    }
  },
  created () {
    this.getLocation();
    this.$store.dispatch('address.getCountries');
  }
}
