var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {},
    _vm._l(_vm.orderByActive(), function(item, i) {
      return _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-sm-12" }, [
          _c("h2", { staticClass: "header-title" }, [
            i == 0 ? _c("span", [_vm._v("(Current State)")]) : _vm._e(),
            _vm._v(" "),
            i == 0 && _vm.cancelled
              ? _c("span", { staticClass: "text-danger" }, [
                  _vm._v("Cancelled at")
                ])
              : _c("span", [_vm._v("Created at")]),
            _vm._v(
              " " +
                _vm._s(
                  _vm._f("dateFormat")(item.created_at, "DD/MMM/YYYY hh:mm A")
                ) +
                "\n        by " +
                _vm._s(item.email) +
                "\n      "
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-8" }, [
          _c("table", { staticClass: "table table-bordered" }, [
            _c(
              "tbody",
              [
                _c("tr", [
                  _c("td", [_vm._v("Location")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.location.name) +
                        "\n              "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Airport")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.airport.name) +
                        "\n              "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Check In")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            item.from_date,
                            "DD/MMM/YYYY hh:mm A"
                          )
                        ) +
                        "\n              "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Check Checkout")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                " +
                        _vm._s(
                          _vm._f("dateFormat")(
                            item.to_date,
                            "DD/MMM/YYYY hh:mm A"
                          )
                        ) +
                        "\n              "
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Number Of Parking Spaces")]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      "\n                " +
                        _vm._s(item.number_of_spots) +
                        "\n              "
                    )
                  ])
                ]),
                _vm._v(" "),
                item.number_of_rooms
                  ? _c("tr", [
                      _c("td", [_vm._v("Number Of Rooms")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.number_of_rooms) +
                            "\n              "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.bundle_id
                  ? _c("tr", [
                      _c("td", [_vm._v("Bundle Type")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.bundle_info.name) +
                            "\n              "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                item.parking_type
                  ? _c("tr", [
                      _c("td", [_vm._v("Parking Type")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n                " +
                            _vm._s(item.parking_type_name) +
                            "\n              "
                        )
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(item.rooms, function(room, Ri) {
                  return item.rooms
                    ? _c("tr", [
                        _c("td", [_vm._v("Room Type " + _vm._s(Ri + 1))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n                " +
                              _vm._s(room.room_type_name) +
                              "\n              "
                          )
                        ])
                      ])
                    : _vm._e()
                })
              ],
              2
            )
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-sm-4" }, [
          _c("h4", {}, [_vm._v("\n          PRICES\n        ")]),
          _vm._v(" "),
          _c("table", { staticClass: "table" }, [
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("Subtotal")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("currency")(
                          item.subtotal,
                          _vm.symbol(item.location.currency_code)
                        )
                      ) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Total Fees")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("currency")(
                          item.total_fees,
                          _vm.symbol(item.location.currency_code)
                        )
                      ) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              item.channel_discount
                ? _c("tr", [
                    _c("th", [
                      _vm._v("\n                Discount\n              ")
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "-" +
                          _vm._s(
                            _vm._f("currency")(
                              item.channel_discount,
                              _vm.symbol(item.location.currency_code)
                            )
                          )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              item.long_term_discount
                ? _c("tr", [
                    _c("th", [
                      _vm._v(
                        "\n                Long Term Discount\n              "
                      )
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "-" +
                          _vm._s(
                            _vm._f("currency")(
                              item.long_term_discount,
                              _vm.symbol(item.location.currency_code)
                            )
                          )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              item.partial_refund
                ? _c("tr", [
                    _c("th", [
                      _vm._v("\n                Partial Refund\n              ")
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "-" +
                          _vm._s(
                            _vm._f("currency")(
                              item.partial_refund,
                              _vm.symbol(item.location.currency_code)
                            )
                          )
                      )
                    ])
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Total Amount")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("currency")(
                          item.grand_total,
                          _vm.symbol(item.location.currency_code)
                        )
                      ) +
                      "\n              "
                  )
                ])
              ]),
              _vm._v(" "),
              _c("tr", [
                _c("th", [_vm._v("Paid Amount")]),
                _vm._v(" "),
                _c("td", [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm._f("currency")(
                          item.transaction_amnt,
                          _vm.symbol(item.location.currency_code)
                        )
                      ) +
                      "\n              "
                  )
                ])
              ])
            ])
          ])
        ])
      ])
    })
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-9c40ddb8", { render: render, staticRenderFns: staticRenderFns })
  }
}