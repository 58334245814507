var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.editMode && _vm.searchType !== "other_location"
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary margin-bottom-6x",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.checkChanges($event)
                }
              }
            },
            [
              _vm.check_avaliability_loading
                ? _c("span", [
                    _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" }),
                    _vm._v("\n        Loading...\n      ")
                  ])
                : _c("span", [_vm._v("Check Changes")])
            ]
          )
        ])
      : _c("div", [
          !_vm._.isEmpty(_vm.selected_location)
            ? _c("div", [
                _vm.selected_location.error_minimum_booking_days
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "Sorry select more than " +
                          _vm._s(_vm.selected_location.minimum_booking_days) +
                          " days."
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.selected_location.error_minimum_booking_days &&
                _vm._.isEmpty(_vm.locationTypes)
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v("No types to show")
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "col-sm-12" }, [
                  _c(
                    "h3",
                    {
                      staticClass: "text-primary margin-bottom-4x margin-top-2x"
                    },
                    [_vm._v("Booking Options")]
                  ),
                  _vm._v(" "),
                  _vm.allPricesLoaded
                    ? _c(
                        "ul",
                        { staticClass: "booking-list" },
                        [
                          _vm._l(_vm.bundleTypes, function(type) {
                            return _vm.reservationType == "both" &&
                              !_vm.combinationIsCheaper(type)
                              ? _c(
                                  "li",
                                  { staticClass: "booking-item checkbox" },
                                  [
                                    _c("label", [
                                      !type.sold_out
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.reservation_types
                                                    .bundle_type,
                                                expression:
                                                  "reservation_types.bundle_type"
                                              }
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: type.id,
                                              checked: _vm._q(
                                                _vm.reservation_types
                                                  .bundle_type,
                                                type.id
                                              )
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  _vm.$set(
                                                    _vm.reservation_types,
                                                    "bundle_type",
                                                    type.id
                                                  )
                                                },
                                                _vm.onBundleTypeChange
                                              ]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("table", { staticClass: "table" }, [
                                        _c("tr", [
                                          _c("th", [_vm._v("Room Type")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                type.room_type
                                                  ? type.room_type.name
                                                  : ""
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Parking Type")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                type.parking_type
                                                  ? type.parking_type.name
                                                  : ""
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Includes")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  type.number_of_room_days +
                                                    " Night" +
                                                    (type.number_of_room_days >
                                                      1 ||
                                                    type.number_of_room_days ==
                                                      0
                                                      ? "s"
                                                      : "")
                                                ) +
                                                " & " +
                                                _vm._s(
                                                  type.number_of_parking_days +
                                                    " Day" +
                                                    (type.number_of_parking_days >
                                                      1 ||
                                                    type.number_of_parking_days ==
                                                      0
                                                      ? "s"
                                                      : "")
                                                ) +
                                                " of Parking\n                  "
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _vm._m(0, true),
                                          _vm._v(" "),
                                          _c("td", [
                                            type.loading
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                      Calculating...\n                    "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          type.price,
                                                          _vm.currentSymbol
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        type.sold_out
                                          ? _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "sold-out-txt",
                                                  attrs: { colspan: "2" }
                                                },
                                                [_vm._v("Sold Out")]
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ]
                                )
                              : _vm._e()
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.combinations, function(type) {
                            return !_vm.bundleIsCheaper(type)
                              ? _c(
                                  "li",
                                  { staticClass: "booking-item checkbox" },
                                  [
                                    _c("label", [
                                      !type.sold_out
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.reservation_types
                                                    .combination_type,
                                                expression:
                                                  "reservation_types.combination_type"
                                              }
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: type,
                                              checked: _vm._q(
                                                _vm.reservation_types
                                                  .combination_type,
                                                type
                                              )
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  _vm.$set(
                                                    _vm.reservation_types,
                                                    "combination_type",
                                                    type
                                                  )
                                                },
                                                _vm.onCombinationTypeChange
                                              ]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("table", { staticClass: "table" }, [
                                        _c("tr", [
                                          _c("th", [_vm._v("Room Type")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                type.room_type
                                                  ? type.room_type.name
                                                  : ""
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Parking Type")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                type.parking_type
                                                  ? type.parking_type.name
                                                  : ""
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Includes")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  type.number_of_room_days +
                                                    " Night" +
                                                    (type.number_of_room_days >
                                                      1 ||
                                                    type.number_of_room_days ==
                                                      0
                                                      ? "s"
                                                      : "")
                                                ) +
                                                " & " +
                                                _vm._s(
                                                  type.number_of_parking_days +
                                                    " Day" +
                                                    (type.number_of_parking_days >
                                                      1 ||
                                                    type.number_of_parking_days ==
                                                      0
                                                      ? "s"
                                                      : "")
                                                ) +
                                                " of Parking\n                  "
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _vm._m(1, true),
                                          _vm._v(" "),
                                          _c("td", [
                                            type.loading
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                      Calculating...\n                    "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          type.price,
                                                          _vm.currentSymbol
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        type.sold_out
                                          ? _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "sold-out-txt",
                                                  attrs: { colspan: "2" }
                                                },
                                                [_vm._v("Sold Out")]
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ]
                                )
                              : _vm._e()
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.parkingTypes, function(type) {
                            return _vm.reservationType == "parking"
                              ? _c(
                                  "li",
                                  { staticClass: "booking-item checkbox" },
                                  [
                                    _c("label", [
                                      !type.sold_out
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.reservation_types
                                                    .parking_type,
                                                expression:
                                                  "reservation_types.parking_type"
                                              }
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: type.id,
                                              checked: _vm._q(
                                                _vm.reservation_types
                                                  .parking_type,
                                                type.id
                                              )
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  _vm.$set(
                                                    _vm.reservation_types,
                                                    "parking_type",
                                                    type.id
                                                  )
                                                },
                                                _vm.onChangeParkingType
                                              ]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("table", { staticClass: "table" }, [
                                        _c("tr", [
                                          _c("th", [_vm._v("Parking Type")]),
                                          _vm._v(" "),
                                          _c("td", [_vm._v(_vm._s(type.name))])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Includes")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            type.number_of_parking_days
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        type.number_of_parking_days +
                                                          " Day" +
                                                          (type.number_of_parking_days >
                                                            1 ||
                                                          type.number_of_parking_days ==
                                                            0
                                                            ? "s"
                                                            : "")
                                                      ) +
                                                      "\n                      of Parking\n                    "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n                      Fetching...\n                    "
                                                  )
                                                ])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _vm._m(2, true),
                                          _vm._v(" "),
                                          _c("td", [
                                            type.loading
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                      Calculating...\n                    "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          type.price,
                                                          _vm.currentSymbol
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        type.sold_out
                                          ? _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "sold-out-txt",
                                                  attrs: { colspan: "2" }
                                                },
                                                [_vm._v("Sold Out")]
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ]
                                )
                              : _vm._e()
                          }),
                          _vm._v(" "),
                          _vm._l(_vm.roomTypes, function(type) {
                            return _vm.reservationType == "room"
                              ? _c(
                                  "li",
                                  { staticClass: "booking-item checkbox" },
                                  [
                                    _c("label", [
                                      !type.sold_out
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.reservation_types
                                                    .room_type,
                                                expression:
                                                  "reservation_types.room_type"
                                              }
                                            ],
                                            attrs: { type: "radio" },
                                            domProps: {
                                              value: type.id,
                                              checked: _vm._q(
                                                _vm.reservation_types.room_type,
                                                type.id
                                              )
                                            },
                                            on: {
                                              change: [
                                                function($event) {
                                                  _vm.$set(
                                                    _vm.reservation_types,
                                                    "room_type",
                                                    type.id
                                                  )
                                                },
                                                _vm.onChangeRoomType
                                              ]
                                            }
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("table", { staticClass: "table" }, [
                                        _c("tr", [
                                          _c("th", [_vm._v("Room Type")]),
                                          _vm._v(" "),
                                          _c("td", [_vm._v(_vm._s(type.name))])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("Includes")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            type.number_of_room_days
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        type.number_of_room_days +
                                                          " Night" +
                                                          (type.number_of_room_days >
                                                            1 ||
                                                          type.number_of_room_days ==
                                                            0
                                                            ? "s"
                                                            : "")
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n                      Fetching...\n                    "
                                                  )
                                                ])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _vm._m(3, true),
                                          _vm._v(" "),
                                          _c("td", [
                                            type.loading
                                              ? _c("span", [
                                                  _vm._v(
                                                    "\n                      Calculating...\n                    "
                                                  )
                                                ])
                                              : _c("span", [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          type.price,
                                                          _vm.currentSymbol
                                                        )
                                                      ) +
                                                      "\n                    "
                                                  )
                                                ])
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        type.sold_out
                                          ? _c("tr", [
                                              _c(
                                                "th",
                                                {
                                                  staticClass: "sold-out-txt",
                                                  attrs: { colspan: "2" }
                                                },
                                                [_vm._v("Sold Out")]
                                              )
                                            ])
                                          : _vm._e()
                                      ])
                                    ])
                                  ]
                                )
                              : _vm._e()
                          })
                        ],
                        2
                      )
                    : _c("div", { staticClass: "text-center" }, [
                        _c("i", { staticClass: "fa fa-spinner fa-spin fa-lg" }),
                        _vm._v("\n            Loading...\n        ")
                      ])
                ])
              ])
            : _vm._e()
        ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_vm._v("Price "), _c("small", [_vm._v("(Grand Total)")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_vm._v("Price "), _c("small", [_vm._v("(Grand Total)")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_vm._v("Price "), _c("small", [_vm._v("(Grand Total)")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [_vm._v("Price "), _c("small", [_vm._v("(Grand Total)")])])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-327166e6", { render: render, staticRenderFns: staticRenderFns })
  }
}