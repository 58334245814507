var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "View Port Details" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("table", { staticClass: "table table-bordered" }, [
          _c("tbody", [
            _c("tr", [
              _c("th", [_vm._v(" Port Name ")]),
              _vm._v(" "),
              _c("td", [_vm._v(" " + _vm._s(_vm.port.name) + " ")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v(" Country ")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(_vm.port.country ? _vm.port.country.name : "") +
                    " "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v(" State ")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  " " + _vm._s(_vm.port.state ? _vm.port.state.name : "") + " "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v(" Timezone ")]),
              _vm._v(" "),
              _c("td", [
                _vm._v(
                  " " +
                    _vm._s(_vm.port.timezone ? _vm.port.timezone.name : "") +
                    " "
                )
              ])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v(" City ")]),
              _vm._v(" "),
              _c("td", [_vm._v(" " + _vm._s(_vm.port.city) + " ")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v(" Address ")]),
              _vm._v(" "),
              _c("td", [_vm._v(" " + _vm._s(_vm.port.address) + " ")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v(" Phone ")]),
              _vm._v(" "),
              _c("td", [_vm._v(" " + _vm._s(_vm.port.phone) + " ")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v(" Fax ")]),
              _vm._v(" "),
              _c("td", [_vm._v(" " + _vm._s(_vm.port.fax) + " ")])
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [_vm._v(" Friendly URL ")]),
              _vm._v(" "),
              _c("td", [_vm._v(" " + _vm._s(_vm.port.url) + " ")])
            ])
          ])
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-263060d6", { render: render, staticRenderFns: staticRenderFns })
  }
}