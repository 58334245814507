import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'
import { general } from './partials'

const directives = {
  hasPermits
}

const components = {
  layout,
  general
}

const computed = {
  countries () {
    return [{name: "Select Country", id: null}].concat(this.$store.state.address.countries);
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }
    return states;
  },
  timezones () {
    return [{name: "Select Timezone", id: null}].concat(this.$store.state.timezones.timezones);
  }
}

const methods = {
  onSubmitFom () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('ports.add', {
      lang: this.lang,
      data: _.cloneDeep(this.form)
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.ports.errors) {
        this.errors = _.cloneDeep(this.$store.state.ports.errors.errors);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        toastr.success('A new port has been created');
        this.$router.push({name: 'ports.all'});
      }
    })
  },
  onChangeCountry () {
    this.form.state_id = null;
  }
}

export default {
  name: 'new-port',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      lang: 'en',
      errors: {},
      form: {
        // region_id: 1,
        fax: null,
        phone: null,
        url: null,
        timezone_id: null,
        longitude: null,
        latitude: null,
        name: null,
        address: null,
        city: null,
        description: null,
        type: null,
        head_title: null,
        head_description: null,
        head_keywords: null,
        info_description: null,
        head_info_title: null,
        head_info_description: null,
        head_info_keyword: null,
        coupon_description: null,
        head_coupon_title: null,
        head_coupon_description: null,
        head_coupon_keyword: null,
        country_id: null,
        state_id: null,
        code: null,
      }
    }
  },
  mounted () {
  },
  created () {
    this.$store.dispatch('address.getCountries');
    this.$store.dispatch('timezones.getAll');
  }
}
