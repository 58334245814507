import hasPermits from 'directives/hasPermits'
import imageBg from 'components/imageBg'

const directives = {
  hasPermits
}

const computed = {
  affiliate () {
    return _.cloneDeep(this.$store.state.affiliates.active);
  },
}

const components = {
  imageBg
}

const methods = {
  changeRoute (route) {
    this.$store.dispatch('nav.changeRoute', route);
  },
  logout () {
    this.$store.dispatch('user.logout');
    this.$router.replace({name: 'login'});
  }
}

export default {
  methods,
  computed,
  directives,
  components,
  data() {
    return {
      isAffiliate: isAffiliate,
    }
  },
  created () {
    this.changeRoute(this.$route.name);
    this.$store.dispatch('multilingual.getLangs');
    this.$store.dispatch('multilingual.getRegions');

    if (isAffiliate && !isServer) {
      this.$store.dispatch('affiliates.getByHost', window.location.host);
    }
  }
}
