import { funcModal } from 'components/modals'

const components = {
  funcModal
}

const computed = {
  port () {
    return this.$store.state.ports.active;
  }
}

const methods = {
  open () {
    this.$refs.modal.open();
  }
}

export default {
  name: "view-port-modal",
  components,
  methods,
  computed,
  data () {
    return {}
  }
}
