// import _ from 'lodash'
import toastr from 'toastr'
import layout from 'layouts/default'
import { http, stripeService } from 'services'
import sSelect from 'components/sSelect';
import datepicker from 'components/datepicker';
import moment from 'moment'
import { hasPermits } from 'directives'
import { search, payment, tripProtection, threeDSecure } from './partials'
//CSS
//  import './new.scss'

const components = {
  layout,
  sSelect,
  datepicker,
  search,
  payment,
  tripProtection,
  threeDSecure
}

const directives = {
  hasPermits
}

const computed = {
  airports() {
    var locations = this.$store.state.ports.all.map((item) => {
      return _.merge({text: item.name, value: item.id}, item)
    })

    return locations
  },
  locations() {
    return _.cloneDeep(this.$store.state.locations.all);
  },
  cost() {
    return _.cloneDeep(this.$store.state.reservations.cost);
  },

}

const watch = {

}

const methods = {
  onChangeType (type) {
      this.reservation_type = type;
      this.onSelectTripProtection(null);
  },
  onSelectTripProtection (plan) {
    this.activeTripProtection = plan;
    try {
      if (plan.id) {
        this.formData.trip_protection_id = plan.id;
      }
    } catch(e) {
      this.formData.trip_protection_id = null;
    }

  },
  changeCurrency (currency) {
    this.formData.currency_code = currency;
  },
  onSelectLocation (location) {
    console.log('location', location);
    this.location = location;
    let stripeAccount = this.location.currency_code == 'USD' ? 'osp' : 'tripteo';
    this.setStripeElements(stripeAccount);
  },

  onSubmitForm () {
    this.submit_loading = true;
    this.errors = {}
    console.log('this.stripe', this.stripe);
    console.log('this.elements.card_element', this.elements.card_element);
    this.stripe.createSource(this.elements.card_element).then((result) => {
      if (result.error) {
        if (result.error.code == 'incomplete_expiry') {
          this.$set(this.errors, 'expiration', [result.error.message]);
        } else if (result.error.code == 'incomplete_cvc') {
          this.$set(this.errors, 'cvc', [result.error.message]);
        } else {
          this.$set(this.errors, 'card_number', [result.error.message]);
        }
        this.submit_loading = false;
        // resolve(this.errors);
        return;
      }

      this.formData.three_d_secure = result.source.card.three_d_secure;



      this.stripe.createToken(this.elements.card_element, {
        address_zip: this.formData.zipcode || '',
        name: this.formData.first_name + " " + this.formData.last_name
      }).then((result) => {
        if (result.error) {
          if (result.error.code == 'incomplete_expiry') {
            this.$set(this.errors, 'expiration', [result.error.message]);
          } else if (result.error.code == 'incomplete_cvc') {
            this.$set(this.errors, 'cvc', [result.error.message]);
          } else {
            this.$set(this.errors, 'card_number', [result.error.message]);
          }
          this.submit_loading = false;
          // resolve(this.errors);
          return;
        }

        this.formData.card_number = result.token.card.last4
        this.formData.expiry_month = result.token.card.exp_month
        this.formData.expiry_year = result.token.card.exp_year
        this.formData.card_id = result.token.card.id
        this.formData.brand = result.token.card.brand
        this.formData.card_token = result.token.id
        this.submit_loading = false;
        this.sendData()
      });
    })
  },

  sendData () {
    this.formData.reserved_by = this.formData.email;
    this.errors = {}
    this.submit_loading = true;
    if (this.activeTripProtection && this.activeTripProtection.price == null) {
      this.formData.trip_protection_id = null;
    }

    let data = _.cloneDeep(this.formData);
    data.name_on_card = `${data.first_name} ${data.last_name}`;
    data.url = this.threeDsecure.redirectUrl;

    if (this.threeDsecure.source_id) {
      data.source_id = this.threeDsecure.source_id;
    }

    let totals = _.cloneDeep(this.$store.state.locations.totals);
    let search_token = null;
    _.forEach(totals, (item, index) => {
      if (item.id == this.formData.location_id) {
        search_token = item.token;
        return;
      }
    })
    data.search_token = search_token;
    return (this.$store.dispatch('reservations.add', data)).then(() => {
      this.threeDsecure.source_id = null;
      this.$set(this, 'submit_loading', false);
      let errors = this.$store.state.reservations.errors;
      if (errors) {

        if (errors.status_code == 400 ) {
          if (!_.isEmpty(errors.error_code) && errors.error_code == '3d_secure_required' ) {
            toastr.warning('The card you used requires 3d secure confirmation');
            this.threeDsecure.url = errors.extra_params.return_url;
          } else if (!_.isEmpty(errors.error_code) && errors.error_code == 'hours_before_reservation_error') {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.message);
          }

        } else if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
          toastr.error(errors.message);
        }
        // else {
        //   toastr.error(errors.message);
        // }
      } else {
        toastr.success('A new Reservation has been created');
        sessionStorage.setItem('__new_reservation__', JSON.stringify(this.$store.state.reservations.active));
        this.$router.push({name: 'reservations.all'});
      }
    })
  },

  onCloseThreeDSecure () {
    this.threeDsecure.url = null;
  },

  setStripeElements(stripeAccount = 'osp') {
    if (this.currentStripeAccount == stripeAccount) {
      return;
    }
    this.currentStripeAccount = stripeAccount;
    this.loadingStripe = true;
    let affiliate = isAffiliate ? _.cloneDeep(this.$store.state.affiliates.active) : null;
    stripeService.init(stripeAccount, affiliate).then(({stripe, elements}) => {
      this.loadingStripe = false;
      this.stripe = stripe;

      let classes = {
        base: 'form-control'
      }

      let style = {
        base: {}
      }

      this.elements.card_element = elements.create('cardNumber', {classes, style});
      this.elements.expiration_element = elements.create('cardExpiry', {classes, style});
      this.elements.cvc_element = elements.create('cardCvc', {classes, style});

      this.elements.card_element.mount('#cardNumber-element');
      this.elements.expiration_element.mount('#expiration-element');
      this.elements.cvc_element.mount('#cvc-element');

      this.elements.card_element.addEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.addEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.addEventListener('change', this.onChangeCvcElement);

    });
  },

  onSearchCompleted(result) {
    if (result.number_of_spots) {
      result.number_of_parkings = result.number_of_spots
    }

    this.formData = _.assignIn(this.formData, result)
  },

  couponSuccess(coupon) {
    this.formData.coupon_code = coupon
  },

  couponError() {
    this.formData.coupon_code = null
  },

  onChangeCardElement () {
    this.$set(this.errors, 'card_number', null);
  },

  onChangeExpirationElement () {
    this.$set(this.errors, 'expiration', null);
  },

  onChangeCvcElement () {
    this.$set(this.errors, 'cvc', null);
  },
}

export default {
  name: 'new-reservation',
  components,
  computed,
  methods,
  watch,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      location: {},
      errors: {},
      reservation_type: 'both',
      activeTripProtection: null,
      formData: {
        reserved_by: null,
        location_id: null,
        airport_id: '',
        number_of_parkings: 0,
        coupon_code: null,
        bundle_type: null,
        bundle_id: null,
        parking_type: null,
        room_type: null,
        from_date: null,
        to_date: null,
        phone: null,
        country: null,
        state: 'none',
        address: null,
        email: null,
        first_name: null,
        last_name: null,
        zipcode: null,
        trip_protection_id: null,
        currency_code: '',
        card_number: null,
        expiry_month: null,
        expiry_year: null,
        three_d_secure: null,
        card_id: null,
        brand: null,
        card_token: null,
      },
      threeDsecure: {
        source_id: null,
        url: null,
        redirectUrl: null
      },
      currentStripeAccount: '',
      loadingStripe: false,
      stripe: null,
      elements: {
        card_element: null,
        expiration_element: null,
        cvc_element: null,
      }
    }
  },
  created () {
    // this.$store.dispatch('ports.getAll', {_all: true})
    // this.$store.dispatch('locations.getAll', {_all: true})
  },

  mounted () {

    if (isAffiliate && !isServer) {
      this.$store.dispatch('affiliates.getByHost', window.location.host);
    }

    this.threeDsecure.redirectUrl = document.location.protocol + '//' + document.location.hostname + (location.port ? ':'+location.port: '') + '/login'

    window.setSource = (source) => {
      console.log('source', source);
      this.threeDsecure.source_id = source;
      this.threeDsecure.url = null;
      this.sendData();
    }

    if (isAffiliate && !isServer) {
      this.$store.dispatch('affiliates.getByHost', window.location.host).then(() => {
        this.setStripeElements('osp');
      });
    } else {
      this.setStripeElements('osp');
    }
    

    this.$store.dispatch('reservations.clear_active')
  },

  destroyed () {
    console.log('destroy');
    try {
      this.elements.card_element.removeEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.removeEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.removeEventListener('change', this.onChangeCvcElement);
    } catch (e) {}

    try {
      this.elements.card_element.destroy()
      this.elements.expiration_element.destroy()
      this.elements.cvc_element.destroy()
    } catch (e) {}
  }
}
