import layout from 'layouts/default'
import pagination from 'components/pagination'
import { confirm } from 'components/modals'
import { hasPermits } from 'directives'

const filters = {
}

const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  confirm
}

const methods = {
  loadPage (page) {
    this.loading = true;
    this.$store.dispatch('reviews.getAll', {page}).then (() => {
      this.loading = false;
    });
  },

  search (searchKey) {
    this.loadPage(1);
  },

  updateStatus (review, status) {
    let data = _.clone(review)
    data.published = status

    this.$store.dispatch('reviews.editReview', {id: review.id, data: data}).then (() => {
      review.published = status
    });
  }
}

const computed = {
  reviews () {
    let reviews = _.cloneDeep(this.$store.state.reviews.all);
    /*_.each(reviews.data, (user) => {
      this.users_loadings[user.id] = false;
    });
    this.users_loadings = _.clone(this.users_loadings);*/
    return reviews;
  }
}

export default {
  name: 'all-reviews',
  components,
  methods,
  computed,
  filters,
  directives,
  data () {
    return {
      loading: true,
      searchKey: null,
      users_loadings: {}
    }
  },
  created () {
    this.loadPage (this.$route.query.page || 1);
  }
}
