const watch = {
  amenitiesList (n, o) {
    if (n == o) return;

    this.amenities = n;
  }
}

const computed = {
  filteredAmenities () {
    let data = {room:{list:[],name:'Rooms'}, parking: {list:[],name:'Parkings'}, facility: {list:[],name:'Facilities'}};
    _.map(this.amenities, (amenity) => {
      console.log("amenity.type",amenity.type)
      data[amenity.type].list.push(amenity);
    })
    return data;
  }
};

export default {
  name: 'amenities-tab',
  props:['lang','amenitiesList'],
  watch,
  computed,
  data () {
    return {
      amenities: this.amenitiesList || [],
    }
  },
}
