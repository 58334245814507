import env from 'constants/env'
import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

let components = {
  layout,
}

if (!isServer) {
  components.tinymce = require('components/tinymce').default;
}

const computed = {

}

const watch = {
  ["form.title"]: function(val) {
    this.form.title = val
    this.form.slug = this.slugify(val)
  }
}

const methods = {
  onSubmitFom () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('pages.add', {
      lang: this.lang,
      data: _.cloneDeep(this.form)
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.pages.errors) {
        this.errors = _.cloneDeep(this.$store.state.pages.errors.errors);
        toastr.error(this.$store.state.pages.errors.message);
      } else {
        toastr.success('A new page has been created');
        this.$router.push({name: 'pages.all'});
      }
    })
  },

  slugify(text) {
    return text.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');            // Trim - from end of text
  }
}

export default {
  name: 'new-page',
  components,
  computed,
  methods,
  watch,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      lang: env.default.lang,
      errors: {},
      form: {
        slug: null,
        title: null,
        content: null,
        seo_title: null,
        seo_description: null,
        seo_keywords: null
      },
      
    }
  },
  mounted () {
  },
  created () {
  }
}
