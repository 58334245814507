
const methods = {
}

export default {
  name: "draggable",
  props: ['parentSelector', 'itemsSelector', 'listSelector'],
  methods,
  data () {
    return {
      
    }
  },
  mounted () {
    this.$nextTick(() => {
     $(this.parentSelector).sortable({
        revert: false,
        items: this.itemsSelector,
        appendTo: "parent",
        // helper: "clone",
        stop: ( event, ui )  => {
          let itemsList = [];
          let list = document.querySelectorAll(this.listSelector);
          let index = list.forEach((item,i) => {
            itemsList.push({id: item.dataset.pk, order_number: i});
          })
          
          this.$emit('updateListOrder', itemsList);
        }
      });
    })
    
  }
}
