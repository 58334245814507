export default {
  name: 'env',
  api: 'http://localhost:9085/backend/',
  affiliateApi: 'http://localhost:9085/backend/',
  backendUrl: 'http://localhost:9085/',
  googleKey: 'AIzaSyBZt5tUvyXDjRE4A8h3u2SqhXeDUxqq7As',
  stripeKey: 'pk_test_v2JL4LbCs1zbwmKq4qRLXikF00saisxr3Y',
  ospStripeKey: 'pk_test_516CC4DHUFZQ2qqlkDKoKBj3ctce0jLDEv1murtzUe81OiMFNriaHKnLhDjw7NSLtDFfY0mPAY7TRk2QT5FQFIKJs004ixg2wC6',
  lang: 'en'
}
