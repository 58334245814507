var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row margin-top-4x" }, [
    _c("div", { staticClass: "col-sm-12" }, [
      _c("h3", { staticClass: "text-info" }, [_vm._v("AMENITIES LIST")]),
      _vm._v(" "),
      _vm.amenities.length
        ? _c(
            "div",
            _vm._l(_vm.filteredAmenities, function(value, key) {
              return _c(
                "ul",
                { staticClass: "list-group col-sm-4" },
                [
                  _c("h3", [
                    _vm._v(
                      "\n            " + _vm._s(value.name) + "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _vm._l(value.list, function(amenity) {
                    return value.list.length
                      ? _c("li", { staticClass: "list-unstyled" }, [
                          _c("h5", [
                            _c("i", { staticClass: "fa fa-check" }),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("capitalize")(amenity.display_name)
                                ) +
                                "\n            "
                            )
                          ])
                        ])
                      : _c("li", [
                          _vm._v(
                            "\n            There are no amenities yet.\n          "
                          )
                        ])
                  })
                ],
                2
              )
            })
          )
        : _c("div", { staticClass: "text-warning col-sm-12" }, [
            _vm._v("\n        There are no amenities yet\n      ")
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-876bd54e", { render: render, staticRenderFns: staticRenderFns })
  }
}