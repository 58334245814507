import { funcModal } from 'components/modals'
import { currencySymbol } from 'filters'
import confirm from 'components/modals/confirm'
import  toastr  from 'toastr'

const components = {
  confirm,
  funcModal
}

const computed = {
  reservation () {
    return this.$store.state.reservations.active;
  },

  channelName () {
    return isAffiliate ? this.$store.state.affiliates.active.name : "TT";
  },

  activeHistory () {
    return _.find(this.$store.state.reservations.active.history, function (h) {return h.active == 1;});
  },

  toRefundAmount () {
    if (this.only_sj) {
      return this.activeHistory.sj_commission + this.activeHistory.sj_fees + this.activeHistory.sj_tax;
    }

    return this.activeHistory.sj_commission + this.activeHistory.sj_fees + this.activeHistory.sj_tax + (this.activeHistory.due_at_location_total ? 0 : this.activeHistory.location_commission);
  }
}

const filters = {
}

const methods = {
  open () {
    this.$refs.modal.open();
    window.top.postMessage({name: 'popup', tyoe: 'partialRefund'}, "*");
  },

  symbol (code) {
    return currencySymbol(code);
  },

  confirm() {
    this.loading = true
    this.errors = {};
    let data = {
      refund_amnt: this.refund_amnt,
      only_sj: this.only_sj
    }

    this.$store.dispatch('reservations.partialRefund', {id: this.reservation.id, data: data}).then((res) => {
      this.loading = false

      if (this.$store.state.reservations.errors) {
        if (!_.isEmpty(this.$store.state.reservations.errors.errors)) {
          this.errors = _.cloneDeep(this.$store.state.reservations.errors.errors);
        }
        toastr.error(this.$store.state.reservations.errors.message);
      } else {
        toastr.success('Partial refund secceeded')
        let reslabFailedMessage = res.data.reslab_failed_message ? res.data.reslab_failed_message : false;
        if (reslabFailedMessage) {
          this.$refs.warningConfirm.confirm(reslabFailedMessage, () => {this.$refs.modal.close();}, () => {this.$refs.modal.close();}, false)
        } else {
          this.$refs.modal.close();
        }
        this.$store.dispatch('reservations.getRefundList',{id:this.reservation.id});
      }
    })

  }
}

export default {
  name: "view-partial-refund-modal",
  components,
  methods,
  computed,
  filters,
  data () {
    return {
      errors: {},
      loading: false,
      refund_amnt: null,
      only_sj: false
    }
  }
}
