import moment from 'moment';

const watch = {
  notesHistory (n, o) {
    if (n == o) return;

    this.notes = n;
  }
}

const methods = {
  toDate (time) {
    return moment(time).format('DD/MMM/YYYY');
  },
}

export default {
  name: 'notes-tab',
  props:['lang','notesHistory'],
  watch,
  methods,
  data () {
    return {
      notes: this.notesHistory || [],
    }
  },
}
