import moment from 'moment'
import env from 'constants/env'
import datatable from 'components/datatable'
import { user } from 'services'
import { currencySymbol } from 'filters'

let components = {
    datatable
}

let methods = {
    run (filters) {
        this.filters = filters;
        this.show_table = true;
    },
    
}

let computed = {
}

let watch = {
}

export default {
    name: "deferred-revenues-report",
    methods,
    components,
    computed,
    watch,
    data: () => ({
        filters: {},
        show_table: false,
        url: env.default.api + 'reports/deferred-revenues',
        columns: [],
    }),
    mounted () {
        this.columns = [
            {name: "Reservation #", object_name: "reservation_number", sortable: true},
            {name: "Location", object_name: "location_name", sortable: true},
            {name: "Airport", object_name: "airport", sortable: true},
            {name: "Start Date", object_name: (obj) => {
              return moment(obj.from_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
            }, sortable: true},
            {name: "End Date", object_name: (obj) => {
              return moment(obj.to_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
            }, sortable: true},
            {name: "Creation Date", object_name: (obj) => {
                return moment(obj.booking_date, ['YYYY-MM-DD HH:mm:ss']).format('MM/DD/YYYY');
            }, sortable: true},
            {name: "Customer Name", object_name:  (obj) => {
                return obj.first_name + " " + obj.last_name ;
            }, sortable: true},
            {name: "Customer Email", object_name: "email"},
            {name: "# Of Parking", object_name: "number_of_spots"},
            {name: "# Of Rooms", object_name: "number_of_rooms"},
            {name: "Currency", object_name: "currency_code", sortable: true},
            {name: "Subtotal", object_name: (obj) => {
              return obj.subtotal + currencySymbol(obj.currency_code);
            }},
            {name: "SJ Fees", object_name: (obj) => {
                return obj.sj_fees + currencySymbol(obj.currency_code);
            }},
            {name: "Fees", object_name: (obj) => {
              return obj.total_fees.toFixed(2) + currencySymbol(obj.currency_code);
            }},
            {name: "Grand Total", object_name: (obj) => {
              return obj.grand_total + currencySymbol(obj.currency_code);
            }},
            {name: "SJ Commission", object_name: (obj) => {
                return obj.sj_commission + currencySymbol(obj.currency_code);
            }},
            {name: "Location Commission", object_name: (obj) => {
                return obj.location_commission + currencySymbol(obj.currency_code);
            }},
            {name: "SJ Tax", object_name: (obj) => {
                return obj.sj_tax + currencySymbol(obj.currency_code);
            }},
            {name: "Taxes", object_name: (obj) => {
                return obj.total_tax + currencySymbol(obj.currency_code);
            }},
            {name: "Discount", object_name: (obj) => {
                return obj.total_discount + currencySymbol(obj.currency_code);
            }},
            {name: "Refunded", object_name: (obj) => {
                return obj.partial_refund + currencySymbol(obj.currency_code);
            }},
            {name: "Due at Location", object_name: (obj) => {
                return obj.due_at_location_total + currencySymbol(obj.currency_code);
            }},
            {name: "Cancelled", object_name: (obj) => {
              return obj.cancelled ? 'Yes' : 'No';
            }, sortable: true},
            {name: "Stripe Fees", object_name: (obj) => {
                return obj.stripe_net_fees + currencySymbol(obj.currency_code);
            }},
            {name: "Coupon Name", object_name: "coupon_code"},
        ]      
    }   
}