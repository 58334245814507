import layout from 'layouts/default'
import sSelect from 'components/sSelect';
import datepicker from 'components/datepicker';
import { tab, tabs } from 'components/tabs'
import {
  partialRefund, cancelRefund,revertCancel,
  search, payment,
  updateModal, history, tripProtection} from '../../partials'
import { hasPermits } from 'directives'
import confirm from 'components/modals/confirm'
import toastr from 'toastr'

const directives = {
  hasPermits
}

const components = {
  layout,
  sSelect,
  datepicker,
  partialRefund,
  cancelRefund,
  revertCancel,
  search,
  payment,
  updateModal,
  tab,
  tabs,
  history,
  tripProtection,
  confirm
}

const computed = {
  reservation () {
    let res =  _.cloneDeep(this.$store.state.reservations.active);
    return res
  },
  reservationType() {
    return this.formData.history[0].reservation_type == "both" ? "parking_and_rooms" : this.formData.history
    [0].reservation_type;
  },
  activeHistory () {
    let activeResHistory =  _.find(this.reservation.history, h => h.active);

    return activeResHistory
  },
}

const methods = {
  onSelectTripProtection (plan) {
    this.activeTripProtection = plan;
    try {
      if (plan.id) {
        this.formData.trip_protection_id = plan.id;
      }
    } catch(e) {
      this.formData.trip_protection_id = null;
    }
    
  },

  onSelectLocation (location) {
    this.location = location;
  },

  searchCompleted(result) {
    console.log('search completed', result);
    this.formData = _.assign({}, this.formData, result)
    if (result.reservation_type != 'both' && result.reservation_type == 'parking') {
      delete this.formData.room_type
    } else if (result.reservation_type != 'both' && result.reservation_type == 'room') {
      delete this.formData.parking_type
    } else if (result.reservation_type == 'both' && result.bundle_type) {
      delete this.formData.parking_type
      delete this.formData.room_type
    }
  },

  couponError(error) {
    this.formData.coupon_code = null
    this.coupon_error_msg = error
  },

  openPartialRefundModal() {
    this.$refs.partialRefund.open();
  },

  openCancelRefundModal() {
    this.$refs.cancelRefund.open();
  },

  openRevertCancelReservationModal() {
    this.$refs.revertCancel.open();
  },

  openUpdateReservationModal() {
    this.$refs.updateModal.open();
  }
}

export default {
  name: 'edit-reservation-module',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      revert_loading: false,
      errors: {},
      activeTab: null,
      location: null,
      activeTripProtection: null,
      formData: {
        reserved_by: null,
        location_id: null,
        airport_id: '',
        bundle_type: '',
        number_of_spots: 0,
        coupon_code: null,
        parking_type: 0,
        from_date: null,
        to_date: null,
        phone: null,
        country: null,
        state: 'none',
        address: null,
        email: null,
        first_name: null,
        last_name: null,
        zipcode: null,
        card_id: null,
        card_number: '',
        expiry_month: '',
        expiry_year: '',
        cvc: '',
        amount_to_pay: 0,
        trip_protection_id: null,
        currency_code: null,
      }
    }
  },
  created () {
    this.loading = true;
    this.$store.dispatch('reservations.get', this.$route.params.id).then(() => {
      this.loading = false;
      let activeResHistory =  _.find(this.reservation.history, h => h.active);
      this.formData = _.assign(this.formData, activeResHistory, this.reservation);
      _.forEach(this.formData.extra_fields, (item) => {
        this.formData[item.name] = item.value;
      });
      if (this.formData.trip_price != null) {
        this.activeTripProtection = this.formData.trip_price.trip_protection;
        this.activeTripProtection.price = this.formData.trip_price.price;
        this.formData.trip_protection_id = this.formData.trip_price.trip_protection_id;
      }
      this.$store.dispatch('reservations.getRefundList',{id:this.reservation.id});
    });
    
    // this.$store.dispatch('ports.getAll', {_all: true})
    // this.$store.dispatch('locations.getAll', {_all: true})
  }
}
