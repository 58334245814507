import toastr from 'toastr'
import { funcModal } from 'components/modals'
import { http } from 'services'
import { currencySymbol } from 'filters'

const components = {
  funcModal
}

const computed = {
  reservation () {
    return this.$store.state.reservations.active;
  },

  activeHistory () {
    return _.find(this.$store.state.reservations.active.history, function (h) {return h.active == 1;});
  },

  refundList () {
    return _.cloneDeep(this.$store.state.reservations.refund_list);
  }
}

const filters = {
}

const methods = {
  open () {
    this.$refs.modal.open();
    window.top.postMessage({name: 'popup', tyoe: 'cancel'}, "*");
  },

  symbol (code) {
    return currencySymbol(code);
  },

  confirm() {
    this.loading = true

    this.$store.dispatch('reservations.remove', this.reservation.id).then((res) => {
      if (this.$store.state.reservations.errors) {
        let errors = this.$store.state.reservations.errors;
        if (!_.isEmpty(errors.errors)) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        this.$refs.modal.close();
        toastr.success('Reservation has been cancelled');
      }

      this.loading = false
    })
  }
}

export default {
  name: "view-cancel-refund-modal",
  components,
  methods,
  computed,
  filters,
  data () {
    return {
      loading: false
    }
  }
}