import _ from 'lodash'
import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const components = {
  layout
}

const directives = {
  hasPermits
}

const computed = {
  // permissions () {
  //   return this.$store.state.roles.permissions;
  // },
  // form () {
  //
  //   return role;
  // }
}

const methods = {
  submit () {
    this.processing = true;
    this.errors = {};


    this.$store.dispatch('roles.editRole', {id: this.$route.params.id, data: this.form})
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.roles.errors;
      if (errors) {
        if (!_.isEmpty(errors.errors)) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  }
}

export default {
  name: 'edit-role',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: true,
      processing: false,
      errors: {},
      form: {
        type: 'admin',
        permissions: [],
      },
      permissions: {}
    }
  },
  created () {
    this.$store.dispatch('roles.getRole', this.$route.params.id).then(() => {
      this.loading = false;
      let role = _.cloneDeep(this.$store.state.roles.active);
      role.permissions = _.map(role.permissions, (item) => {
        return item.id
      });

      this.form = _.assign(this.form, role);
      console.log('this.form', this.form);
      this.permissions = _.cloneDeep(this.$store.state.roles.permissions);
    });

  }
}
