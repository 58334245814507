import env from 'constants/env'
import fileUpload from 'components/fileUpload'
import toastr from 'toastr'
import { hasPermits } from 'directives'


const directives = {
  hasPermits
}

const components = {
  fileUpload
}

const computed = {
  countries () {
    return [{name: "Select Country", id: null}].concat(this.$store.state.address.countries);
  },

  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }
    return states;
  },

  timezones () {
    return [{name: "Select Timezone", id: null}].concat(this.$store.state.timezones.timezones);
  },

  photos () {
    return this.form.photos ? this.form.photos.map((item) => ({
        filename: item.filename,
        id: item.id,
        featured: item.featured,
      })) : [];
  },
}

const methods = {
  onChangeCountry () {
    this.form.state_id = null;
  },
  imagesToUpload (files) {
    if (files.length) {

      let maxFileSize = 2 * 1024 * 1024; // 2MB
      if (files[0].size >= maxFileSize) {
        toastr.error("Maximum file size (2MB) has been exceeded!!")
        this.$refs.file_upload.removeFiles()
        return;
      }

      this.images = files;
    } else {
      this.images = [];
    }
  },

  uploadImages () {
    this.local_errors = {};
    this.files_processing = true;
    this.$store.dispatch('ports.uploadImages', {
      id: this.$route.params.id,
      files: this.images
    }).then(() => {
      this.files_processing = false;

      if (this.$store.state.ports.errors) {
        this.local_errors = _.cloneDeep(this.$store.state.ports.errors.errors);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        toastr.success('Data has been saved');
        this.$refs.file_upload.removeFiles();
        $('[data-toggle="tooltip"]').tooltip();
      }
    });
  },

  removePhoto (image) {
    this.need_to_save = true;
    this.images.push(image);
    this.$store.dispatch('ports.removeImage',{image});
  },

  setFeatured (image) {

    this.local_errors = {}
    this.processing = true;
    this.$store.dispatch('ports.setFeaturedImage', {airport_id:this.$route.params.id, id:image.id}).then(() => {
      this.processing = false;
      if (this.$store.state.ports.errors) {
        this.local_errors.images = [this.$store.state.ports.errors.message];
        // toastr.error(this.local_errors.images[0]);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        toastr.success('Data has been saved');
      }
    });
  },

  submit () {
    this.local_errors = {}
    this.processing = true;
    
    this.$store.dispatch('ports.deleteImages', {
      id: this.$route.params.id,
      photos: _.cloneDeep(this.images),
    }).then(() => {
      this.processing = false;
      if (this.$store.state.ports.errors) {
        this.local_errors = _.cloneDeep(this.$store.state.ports.errors.errors);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        toastr.success('Data has been deleted');
        this.need_to_save = false;
      }
    })
    
  }
}

const watch = {
  formData (n, o) {
    if (n == o) return;

    this.form = n;
  }
}

export default {
  name: 'general-tab',
  props: ['formData', 'errors','editMode'],
  computed,
  methods,
  components,
  watch,
  directives,
  data () {
    return {
      form: this.formData,
      files_processing:false,
      processing:false,
      need_to_save: false,
      images: [],
      local_errors : this.errors,
      lang: env.default.lang,
    }
  },
  mounted () {
    $('[data-toggle="tooltip"]').tooltip();
  },
}
