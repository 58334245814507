import toastr from 'toastr'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const methods = {
  submit () {
    this.errors = {};
    this.processing = true;
    this.$store.dispatch('locations.newFee', this.form).then(() => {
      this.processing = false;
      
      if (this.$store.state.locations.errors) {
        this.errors = this.$store.state.locations.errors.errors;
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('data has been saved successfully');
        this.formActive = false;
        this.form = {
          name: null,
          amount: null,
          fixed: true,
          associated_to: "room",
          daily: false,
          location_id: this.$route.params.id
        }
      }
    });
  },

  handleFeesType (event) {
    let type = this.form.daily;
    if (!type) {
      this.form.fixed = true;
    }
  }
}

export default {
  name: 'new-fee',
  methods,
  directives,
  data() {
    return {
      formActive: false,
      processing: false,
      errors: {},
      form: {
        name: null,
        amount: null,
        fixed: true,
        associated_to: "room",
        daily: false,
        location_id: this.$route.params.id
      }
    }
  }
}
