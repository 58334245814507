var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.plans && _vm.plans.length && !_vm.selectedPlan
    ? _c("div", {}, [
        _c("h2", { staticClass: "header-title" }, [_vm._v("TRIP PROTECTION")]),
        _vm._v(" "),
        _c(
          "ul",
          { staticClass: "trip-protection-plans" },
          [
            _vm._l(_vm.plans, function(plan) {
              return _c("li", { key: plan.id }, [
                _c(
                  "a",
                  {
                    class: { active: _vm.selectedPlan == plan.id },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.selectPlan(plan)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "tripProtection-content" }, [
                      _c("h3", [_vm._v(_vm._s(plan.name))]),
                      _vm._v(" "),
                      _c("div", {
                        domProps: { innerHTML: _vm._s(plan.description) }
                      })
                    ]),
                    _vm._v(" "),
                    plan.price
                      ? _c("div", { staticClass: "tripProtection-price" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm._f("currency")(plan.price, _vm.symbol)
                              ) +
                              "\n        "
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ])
            }),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  class: { active: _vm.selectedPlan == null },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.selectPlan(_vm.freePlan)
                    }
                  }
                },
                [
                  _c("div", { staticClass: "tripProtection-content" }, [
                    _c("h3", [_vm._v(_vm._s(_vm.freePlan.description))])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "tripProtection-price" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm._f("currency")(_vm.freePlan.price, _vm.symbol)
                        ) +
                        "\n        "
                    )
                  ])
                ]
              )
            ])
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-30b0a3da", { render: render, staticRenderFns: staticRenderFns })
  }
}