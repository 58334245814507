import { funcModal } from 'components/modals'
import { tab, tabs } from "components/tabs"
import toastr from 'toastr'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}
const components = {
  funcModal,
  tab,
  tabs
}

const computed = {
  // langs () {
  //   return this.$store.state.multilingual.langs;
  // },

  fees() {
    return this.$store.state.locations.active.fees;
  },

  language () {
    let langs = this.$store.state.multilingual.langs;

    for (let i in langs) {
      if (this.lang == langs[i].code) {
        return langs[i];
      }
    }

    return null;
  }
}

const methods = {
  open (id) {
    this.fee_id = id
    this.getFee();
    this.$refs.modal.open();
  },

  getFee() {
    if (!this.fee_id) return;
    let _FEES = _.cloneDeep(this.fees)
    let indx = _.findIndex(_FEES, (item) => item.id == this.fee_id);
    let _LANG = this.lang;

    if(indx > -1) {
      let _fee = _FEES[indx]

      let _indx = _.findIndex(_fee.translations, (trans) => {
        if (!trans.language) return false;
        return trans.language.code == _LANG;
      });

      if(_indx > -1) {
        this.fee = _.assign(_fee, _fee.translations[_indx]);
      } else {
        this.fee = _fee
      }
    }
  },

  save() {

    this.loading = true

    let fee = _.cloneDeep(this.fee);
    delete fee.translations;

    this.$store.dispatch('locations.updateFee', {
      id: fee.id, data: fee, lang: this.lang
    }).then(() => {

      this.loading = false;

      
      if (this.$store.state.locations.errors) {
        this.errors = this.$store.state.locations.errors.errors;
        toastr.error(this.$store.state.locations.errors.message);
      } else {
        toastr.success('fee updated successfully');
        this.$refs.modal.close();
      }
    })
  },

  handleFeesType (event) {
    let type = this.fee.daily;
    if (!type) {
      this.fee.fixed = true;
    }
  }
}

export default {
  name: "edit-fee-modal",
  props: ['lang'],
  components,
  methods,
  computed,
  directives,
  data () {
    return {
      loading: false,
      fee_id: null,
      errors: {},
      fee: {}
    }
  },
  mounted() {
  }
}
