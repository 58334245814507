import { http } from 'services'

// initial state
const state = () => ({
  all: [],
  active: null,
  categories: [],
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //get all ports (params: {_q: search name, page})
  ['faqs.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('faqs?_all=true&_sort=order_number', {
      params
    })).then ((res) => {
      commit('GET_ALL_FAQS', res);
    }).catch ((res) => {
      commit('FAQ_ERROR', res);
    })
  },

  ['faqs.getAllCategories'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null
    if (!params._all) params._all = true

    commit('CLEAR_ERRORS')

    return (http.get('faq-categories', {
      params
    })).then ((res) => {
      commit('GET_ALL_FAQ_CATEGORIES', res);
    }).catch ((res) => {
      commit('FAQ_ERROR', res);
    })
  },

  ['faqs.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')

    return (http.get(`faqs/${id}`)).then ((res) => {
      commit('GET_FAQ', res);
    }).catch ((res) => {
      commit('FAQ_ERROR', res);
    })
  },


  //update faq
  ['faqs.edit'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`faqs/${id}`, data)).then ((res) => {
      commit('EDIT_FAQ', res);
    })
    .catch ((res) => {
      console.log(res)
      commit('FAQ_ERROR', res);
    })
  },

  ['faqs.updateAll'] ({dispatch, commit, state}, {data}) {
    commit('CLEAR_ERRORS');
    
    return (http.post(`faqs/update-all?_all=true&_sort=order_number`, {items: data})).then ((res) => {
      commit('GET_ALL_FAQS', res);
    }).catch ((res) => {
      console.log(res)
      commit('FAQ_ERROR', res);
    })
  },

  ['faqs.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`faqs/${id}`)).then ((res) => {
      commit('REMOVE_FAQ', res);
    }).catch ((res) => {
      commit('FAQ_ERROR', res);
    })
  },

  ['faqs.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`faqs/${id}?_undelete=true`)).then ((res) => {
      commit('UNDO_REMOVE_FAQ', res);
    }).catch ((res) => {
      commit('FAQ_ERROR', res);
    })
  },

  ['faqs.add'] ({commit}, {data}) {
    commit('CLEAR_ERRORS');

    return (http.post(`faqs`, data)).then ((res) => {
      commit('NEW_FAQ', res);
    }).catch ((res) => {
      commit('FAQ_ERROR', res);
    })
  }
}

// mutations
const mutations = {
  GET_ALL_FAQS (state, res) {
    let sorted = _.sortBy(res.data, ['order_number']);
    state.all = sorted;
    state.all = _.cloneDeep(state.all);
  },

  GET_ALL_FAQ_CATEGORIES (state, res) {
    state.categories = res.data;
    state.categories = _.cloneDeep(state.categories);
  },

  REMOVE_FAQ (state, res) {
    console.log('resxxx remove', res);
    let data = _.filter(state.all, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(data);
  },

  UNDO_REMOVE_FAQ (state, res) {
    let indx = _.findIndex(state.all, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all[indx] = _.assign(state.all[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_FAQ (state, res) {
    state.active = _.assign(state.active, res.data);

    state.active = _.cloneDeep(state.active);
  },

  EDIT_FAQ (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_FAQ (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  FAQ_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
