var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h2", { staticClass: "header-title" }, [_vm._v("CALCULATIONS")]),
    _vm._v(" "),
    _vm.costLoading
      ? _c("div", { staticClass: "text-center col-sm-12 margin-top-2x" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c("div", [
          !_vm._.isEmpty(_vm.activeHistory)
            ? _c("div", [
                _c("h4", [_vm._v("OLD CALCULATIONS")]),
                _vm._v(" "),
                _c("table", { staticClass: "table total-calc" }, [
                  _c("tr", [
                    _c("th", [_vm._v("Number Of Parking Days")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(_vm._s(_vm.activeHistory.number_of_parking_days))
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.activeHistory.reservation_type != "parking"
                    ? _c("tr", [
                        _c("th", [_vm._v("Number Of Sleeping Nights")]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm.activeHistory.nights_to_sleep == "both"
                                ? 2
                                : 1
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Subtotal")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.activeHistory.subtotal,
                            _vm.currentCurrencySymbol
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Fees Total")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.activeHistory.total_fees,
                            _vm.currentCurrencySymbol
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.activeHistory.trip_price &&
                  _vm.activeHistory.trip_price.price
                    ? _c("tr", [
                        _c("th", [
                          _vm._v("Trip Protection "),
                          _c("small", [
                            _vm._v(
                              _vm._s(
                                _vm.activeHistory.trip_price.trip_protection
                                  .name
                              )
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.activeHistory.trip_price.price,
                                _vm.currentCurrencySymbol
                              )
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.reservation.coupon && _vm.reservation.coupon.code
                    ? _c("tr", [
                        _c("th", [
                          _c("i", {
                            staticClass: "fa fa-times",
                            staticStyle: { cursor: "pointer" },
                            attrs: { "aria-hidden": "true" },
                            on: {
                              click: function($event) {
                                _vm.removeCoupon()
                              }
                            }
                          }),
                          _vm._v(
                            "\n            Coupon (" +
                              _vm._s(_vm.reservation.coupon.code) +
                              ") Total\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "-" +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.activeHistory.channel_discount,
                                  _vm.currentCurrencySymbol
                                )
                              )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeHistory.long_term_discount
                    ? _c("tr", [
                        _c("th", [
                          _vm._v("\n            Long Term Discount\n          ")
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "-" +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.activeHistory.long_term_discount,
                                  _vm.currentCurrencySymbol
                                )
                              )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeHistory.partial_refund
                    ? _c("tr", [
                        _c("th", [
                          _vm._v("\n            Partial Refund\n          ")
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            "-" +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.activeHistory.partial_refund,
                                  _vm.currentCurrencySymbol
                                )
                              )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.activeHistory.total_tax
                    ? _c("tr", [
                        _c("th", [_vm._v("Taxes")]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.activeHistory.total_tax,
                                _vm.currentCurrencySymbol
                              )
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Grand Total")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.activeHistory.grand_total,
                            _vm.currentCurrencySymbol
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.activeHistory.due_at_location_total
                    ? _c("tr", [
                        _c("th", [_vm._v("Due At Check-in:")]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-right" }, [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.activeHistory.due_at_location_total,
                                _vm.currentCurrencySymbol
                              )
                            )
                          )
                        ])
                      ])
                    : _vm._e()
                ]),
                _vm._v(" "),
                !_vm._.isEmpty(_vm.cost)
                  ? _c("h4", [_vm._v("NEW CALCULATIONS")])
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm._.isEmpty(_vm.cost)
            ? _c(
                "table",
                { staticClass: "table total-calc new-calc" },
                [
                  _c("tr", [
                    _c("th", [_vm._v("Number Of Parking Days")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s(_vm.cost.total_days.parking.number_of_days))
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.reservationType != "parking"
                    ? _c("tr", [
                        _vm._m(0),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(_vm.cost.total_days.room.number_of_days) +
                              "\n          "
                          ),
                          _vm.cost.extra_total > 0
                            ? _c("div", [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("currency")(
                                        _vm.cost.extra_total,
                                        _vm.currentCurrencySymbol
                                      )
                                    ) +
                                    "\n          "
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.numberOfRooms) +
                                "\n          "
                            )
                          ])
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [
                      _vm._v("Subtotal\n          "),
                      _vm.cost.diff && _vm.cost.diff.subtotal != 0
                        ? _c("span", { staticClass: "normal" }, [
                            this.cost.diff.subtotal > 0
                              ? _c("span", [_vm._v("+")])
                              : _vm._e(),
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.cost.diff.subtotal,
                                  _vm.currentCurrencySymbol
                                )
                              ) + "\n          "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.cost.reservation.sub_total,
                            _vm.currentCurrencySymbol
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.cost.reservation.fees, function(fee) {
                    return _c("tr", [
                      _c(
                        "th",
                        [
                          _c("popover", {
                            attrs: {
                              text: fee.name,
                              options: { content: fee.name }
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm._f("currency")(
                              fee.total,
                              _vm.currentCurrencySymbol
                            )
                          )
                        )
                      ])
                    ])
                  }),
                  _vm._v(" "),
                  _vm.cost.reservation.coupon &&
                  _vm.cost.reservation.coupon.code
                    ? _c("tr", [
                        _c("th", [
                          _c("i", {
                            staticClass: "fa fa-times",
                            staticStyle: { cursor: "pointer" },
                            attrs: { "aria-hidden": "true" },
                            on: {
                              click: function($event) {
                                _vm.removeCoupon()
                              }
                            }
                          }),
                          _vm._v(
                            "\n          Coupon (" +
                              _vm._s(_vm.cost.reservation.coupon.code) +
                              ") Total\n        "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "-" +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.cost.reservation.channel_discount,
                                  _vm.currentCurrencySymbol
                                )
                              )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cost.reservation.long_term_discount
                    ? _c("tr", [
                        _c("th", [
                          _vm._v("\n            Long Term Discount\n          ")
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "-" +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.cost.reservation.long_term_discount,
                                  _vm.currentCurrencySymbol
                                )
                              )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cost.reservation.tax_total
                    ? _c("tr", [
                        _c("th", [_vm._v("Tax Total")]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.cost.reservation.tax_total,
                                _vm.currentCurrencySymbol
                              )
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.cost.reservation.trip_protection
                    ? _c("tr", [
                        _c("th", [
                          _vm._v("Trip Protection "),
                          _c("small", [
                            _vm._v(
                              _vm._s(_vm.cost.reservation.trip_protection.name)
                            )
                          ]),
                          _vm._v(" "),
                          _vm.activeHistory
                            ? _c("span", { staticClass: "normal" }, [
                                _vm._v("\n          (\n            "),
                                _vm.cost.diff.parking &&
                                _vm.cost.diff.parking.tripProtection > 0
                                  ? _c("span", [_vm._v("+")])
                                  : _vm._e(),
                                _vm._v(
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.cost.diff.parking.tripProtection,
                                      _vm.currentCurrencySymbol
                                    )
                                  ) + "\n          )\n        "
                                )
                              ])
                            : _vm._e()
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.cost.reservation.trip_protection_total,
                                _vm.currentCurrencySymbol
                              )
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [
                      _vm._v("New Grand Total\n          "),
                      _vm.cost.diff && _vm.cost.diff.grand_total != 0
                        ? _c("span", { staticClass: "normal" }, [
                            this.cost.diff.grand_total > 0
                              ? _c("span", [_vm._v("+")])
                              : _vm._e(),
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.cost.diff.grand_total,
                                  _vm.currentCurrencySymbol
                                )
                              ) + "\n          "
                            )
                          ])
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.cost.reservation.grand_total,
                            _vm.currentCurrencySymbol
                          )
                        )
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.cost.reservation.total_after_cancellation_policy !=
                  _vm.cost.reservation.grand_total
                    ? _c("tr", [
                        _c("th", [
                          _vm._v("After Applying Cancellation Policy")
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("currency")(
                                _vm.cost.reservation
                                  .total_after_cancellation_policy,
                                _vm.currentCurrencySymbol
                              )
                            )
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("    Adjustments for now")]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-right" }, [
                      _vm.cost.reservation.sold_out
                        ? _c("span", { staticClass: "text-danger" }, [
                            _vm._v("(Sold out)")
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cost.reservation.amount_to_pay > 0
                        ? _c("span", [_vm._v("+")])
                        : _vm._e(),
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.cost.reservation.amount_to_pay,
                            _vm.currentCurrencySymbol
                          )
                        ) + "\n        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _vm.cost.reservation.due_at_location
                    ? _c("tr", [
                        _c("th", [_vm._v("    Due At Check-in:")]),
                        _vm._v(" "),
                        _vm.cost.diff
                          ? _c("td", { staticClass: "text-right" }, [
                              _vm.cost.diff.due_at_location > 0
                                ? _c("span", [_vm._v("+")])
                                : _vm._e(),
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.cost.diff.due_at_location,
                                    _vm.currentCurrencySymbol
                                  )
                                ) + " "
                              ),
                              _c("br"),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      _vm.cost.reservation.due_at_location,
                                      _vm.currentCurrencySymbol
                                    )
                                  )
                              )
                            ])
                          : _c("td", { staticClass: "text-right" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.cost.reservation.due_at_location,
                                    _vm.currentCurrencySymbol
                                  )
                                )
                              )
                            ])
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "field margin-bottom-4x" }, [
      _vm._m(1),
      _c("div", { staticClass: "input-group" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.coupon_code,
              expression: "coupon_code"
            }
          ],
          staticClass: "form-control",
          attrs: {
            type: "text",
            disabled: _vm.couponSuccess || (!_vm.cost && !_vm.activeHistory)
          },
          domProps: { value: _vm.coupon_code },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.coupon_code = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "input-group-btn" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              attrs: {
                disabled:
                  _vm.couponSuccess || (!_vm.cost && !_vm.activeHistory),
                type: "button"
              },
              on: {
                click: function($event) {
                  _vm.applyDiscountCode()
                }
              }
            },
            [
              _vm.costLoading
                ? _c("span", [
                    _c("i", { staticClass: "fa fa-spinner fa-spin fa-fw" })
                  ])
                : _c("span", [_vm._v("Apply!")])
            ]
          )
        ])
      ]),
      _vm._v(" "),
      _vm.couponError
        ? _c("div", { staticClass: "text-danger" }, [
            _vm._v("\n        " + _vm._s(_vm.couponError) + "\n      ")
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p")
    ]),
    _vm._v(" "),
    !_vm._.isEmpty(_vm.activeHistory)
      ? _c(
          "div",
          { staticClass: "margin-top-6x" },
          [
            _c("h4", [_vm._v("USED CREDIT CARDS:")]),
            _vm._v(" "),
            _vm._l(_vm.reservation.history, function(history, index) {
              return history.credit_card_info
                ? _c("div", { key: history.id }, [
                    _vm._v(
                      "\n      " +
                        _vm._s(history.credit_card_info.card_number) +
                        " : " +
                        _vm._s(
                          _vm._f("currency")(
                            history.transaction_amnt -
                              (_vm.reservation.history[index - 1]
                                ? _vm.reservation.history[index - 1]
                                    .transaction_amnt
                                : 0),
                            _vm.currentCurrencySymbol
                          )
                        ) +
                        " "
                    ),
                    _c("br")
                  ])
                : _vm._e()
            })
          ],
          2
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", [
      _vm._v("Number Of Sleeping Nights\n          "),
      _vm._v(" "),
      _c("div", [_vm._v("\n                Number Of Rooms\n          ")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_c("span", [_vm._v("Do you have discount code?")])])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-11c669f4", { render: render, staticRenderFns: staticRenderFns })
  }
}