var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.form.search_result_loading
      ? _c("div", { staticClass: "text-center col-sm-12 margin-top-2x" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.form.search_result_loading &&
    _vm._.size(_vm.searchResults) > 0 &&
    (!_vm.editMode || _vm.searchType == "other_location")
      ? _c("div", [
          _c("table", { staticClass: "table" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "tbody",
              _vm._l(_vm.searchResults, function(location) {
                return _vm._.size(_vm.searchResults)
                  ? _c("tr", [
                      _c("td", [
                        _c("label", { staticClass: "full-width" }, [
                          !location.sold_out &&
                          !isNaN(location.price) &&
                          (!location.warning ||
                            (location.warning &&
                              location.warning
                                .split(",")[0]
                                .includes("Minimum Booking Days")))
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.form.location_id,
                                    expression: "form.location_id"
                                  }
                                ],
                                attrs: { type: "radio" },
                                domProps: {
                                  value: location.id,
                                  checked: _vm._q(
                                    _vm.form.location_id,
                                    location.id
                                  )
                                },
                                on: {
                                  change: [
                                    function($event) {
                                      _vm.$set(
                                        _vm.form,
                                        "location_id",
                                        location.id
                                      )
                                    },
                                    function($event) {
                                      _vm.selectLocation(location)
                                    }
                                  ]
                                }
                              })
                            : _vm._e()
                        ])
                      ]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(location.name))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(location.distance_from_airport) + " mile(s)"
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(location.due_at_location ? "Yes" : "No"))
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        location.sold_out || location.price == "Sold Out"
                          ? _c("span", { staticClass: "text-danger" }, [
                              _vm._v(
                                "\n                        Sold Out\n                      "
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !isNaN(location.price)
                          ? _c("span", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm._f("currency")(
                                      location.price,
                                      _vm.currencySymbol(
                                        _vm.currenctCurrency
                                          ? _vm.currenctCurrency
                                          : location.currency_code
                                      )
                                    )
                                  ) +
                                  "\n                      "
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(location.price) +
                                  "\n                      "
                              )
                            ]),
                        _vm._v(" "),
                        location.warning
                          ? _c("div", { staticClass: "text-warning" }, [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(location.warning) +
                                  "\n                      "
                              )
                            ])
                          : _vm._e()
                      ])
                    ])
                  : _vm._e()
              })
            )
          ]),
          _vm._v(" "),
          _vm.form.location_id && _vm.location_amenities.length
            ? _c("div", {}, [
                _c("hr"),
                _vm._v(" "),
                _c("h3", { staticClass: "text-primary margin-bottom-4x" }, [
                  _vm._v("Amenities")
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "row" },
                  _vm._l(_vm.location_amenities, function(amenity) {
                    return _c("div", { staticClass: "col-md-4 col-sm-6" }, [
                      _c("i", {
                        staticClass: "fa fa-check",
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v(
                        "\n              " +
                          _vm._s(amenity.display_name) +
                          "\n            "
                      )
                    ])
                  })
                ),
                _vm._v(" "),
                _c("hr")
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    (!_vm._.isEmpty(_vm.extra_fields) && _vm.form.location_id) ||
    (!_vm._.isEmpty(_vm.activeHistory) && !_vm._.isEmpty(_vm.extra_fields))
      ? _c("div", { staticClass: "col-sm-12" }, [
          _c("h3", { staticClass: "text-primary margin-bottom-4x" }, [
            _vm._v("\n        Required Inputs\n      ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.locationExtraFields, function(field) {
              return _c(
                "div",
                { staticClass: "col-12 col-md-6 margin-bottom-2x regular" },
                [
                  _vm.reservationType != "room" &&
                  field.per_car &&
                  parseInt(_vm.numberOfSpots) > 1
                    ? _c(
                        "div",
                        _vm._l(parseInt(_vm.numberOfSpots), function(i) {
                          return _c("div", { key: i }, [
                            _c("div", { staticClass: "regular" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(i) +
                                  "\n                "
                              ),
                              i == 1 ? _c("span", [_vm._v("st")]) : _vm._e(),
                              _vm._v(" "),
                              i == 2 ? _c("span", [_vm._v("nd")]) : _vm._e(),
                              _vm._v(" "),
                              i == 3 ? _c("span", [_vm._v("rd")]) : _vm._e(),
                              _vm._v(" "),
                              i > 3 ? _c("span", [_vm._v("th")]) : _vm._e(),
                              _vm._v(" "),
                              _c("label", [_vm._v(_vm._s("  " + field.label))])
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.extra_fields[field.name][i - 1],
                                  expression: "extra_fields[field.name][i-1]"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: { type: "text" },
                              domProps: {
                                value: _vm.extra_fields[field.name][i - 1]
                              },
                              on: {
                                input: [
                                  function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.extra_fields[field.name],
                                      i - 1,
                                      $event.target.value
                                    )
                                  },
                                  _vm.onChangeExtraFields
                                ]
                              }
                            })
                          ])
                        })
                      )
                    : _c("div", [
                        _c("label", { staticClass: "regular" }, [
                          _vm._v(_vm._s(field.label))
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.extra_fields[field.name][0],
                              expression: "extra_fields[field.name][0]"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: { type: "text" },
                          domProps: { value: _vm.extra_fields[field.name][0] },
                          on: {
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.extra_fields[field.name],
                                  0,
                                  $event.target.value
                                )
                              },
                              _vm.onChangeExtraFields
                            ]
                          }
                        })
                      ]),
                  _vm._v(" "),
                  _vm.reservation_errors[field.name]
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.reservation_errors[field.name][0]) +
                            "\n          "
                        )
                      ])
                    : _vm._e()
                ]
              )
            })
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm._.isEmpty(_vm.form.errors.hours_before_reservation)
      ? _c("div", { staticClass: "col-sm-12" }, [
          _vm.form.errors.hours_before_reservation
            ? _c("div", { staticClass: "text-danger" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.form.errors.hours_before_reservation[0]) +
                    "\n      "
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Select")]),
        _vm._v(" "),
        _c("th", { attrs: { width: "30%" } }, [_vm._v("Location Name")]),
        _vm._v(" "),
        _c("th", [_vm._v("Distance")]),
        _vm._v(" "),
        _c("th", [_vm._v("Due At Check-in")]),
        _vm._v(" "),
        _c("th", [
          _vm._v("Grand Total "),
          _c("small", [_vm._v("(starting from)")])
        ])
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7bb85c03", { render: render, staticRenderFns: staticRenderFns })
  }
}