import env from 'constants/env'
import layout from 'layouts/default'
import {tabs, tab} from 'components/tabs'
import { hasPermits } from 'directives'
import toastr from 'toastr'

const components = {
  layout,
  tabs,
  tab,
}

const directives = {
  hasPermits
}

const computed = {
  langs () {
    return this.$store.state.multilingual.langs;
  },
  currencies () {
    return [].concat(this.$store.state.currencies.all);
  },
  formData () {
    let tripProtection = _.cloneDeep(this.$store.state.tripProtections.active);
    tripProtection.translations = _.filter(tripProtection.translations, (item) => {
      if (!item.language) return false;
      return item.language.code == this.lang
    });

    if (tripProtection.translations[0])
      tripProtection = _.assign(tripProtection, tripProtection.translations[0]);

    this.trip_protection_prices = tripProtection.trip_protection_prices
    return tripProtection;
  },

  translations () {
    return this.$store.state.tripProtections.active.translations
  }
}

const watch = {
  ['trip_protection_prices']: function(value) {
    this.formData.trip_protection_prices = value
  }
}

const methods = {
  onSubmitForm () {
    const encoder = new TextEncoder();
    const description_byte_size = encoder.encode(this.formData.description).length;
    const short_description_byte_size = encoder.encode(this.formData.short_description).length;

    const max_size = 15 * 1024 * 1024;

    if (description_byte_size > max_size || short_description_byte_size > max_size) {
      toastr.error('Content size exceeds the limit of 16 MB.');
      return;
    }
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('tripProtections.edit', {
      id: this.$route.params.id,
      data: this.formData,
      lang: this.lang
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.tripProtections.errors) {
        this.errors = _.cloneDeep(this.$store.state.tripProtections.errors.errors);
        toastr.error(this.$store.state.tripProtections.errors.message);
      } else {
        toastr.success('Data has been updated successfully!');
      }
    })
  },
  gettripProtection () {
    this.loading = true;
    this.$store.dispatch('tripProtections.get', this.$route.params.id).then(() => {
      this.loading = false;
    });
  },
  wysiwygDescriptionChanged: function(val) {
    this.formData.description = val
  },

  wysiwygShortDescriptionChanged: function(val) {
    this.formData.short_description = val
  },

  createPrice() {
    this.trip_protection_prices.push({currency_code: "", price: 0});
  },

  removePrice(index) {
    this.trip_protection_prices.splice(index, 1)
  }
}

export default {
  name: 'edit-trip-protection',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      activeTab: null,
      lang: env.default.lang,
      errors: {},
      trip_protection_prices: []
    }
  },
  created () {
    this.gettripProtection();
    this.$store.dispatch('currencies.getAll');
  }
}
