var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "Reservation Details" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "template",
        { slot: "body" },
        [
          _c(
            "tabs",
            [
              _c("tab", { attrs: { title: "Current State" } }, [
                _vm.current
                  ? _c(
                      "table",
                      { staticClass: "table table-bordered margin-top-2x" },
                      [
                        _c("tbody", [
                          _c("tr", [
                            _c("th", [_vm._v("Reservation Number")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.reservation.reservation_number))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Reservation Info")]),
                            _vm._v(" "),
                            _c(
                              "td",
                              _vm._l(_vm.current.dates, function(date) {
                                return _vm.current
                                  ? _c(
                                      "table",
                                      { staticClass: "table" },
                                      [
                                        _c("tr", [
                                          _c("th", [_vm._v("Type")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(_vm._s(date.type_type))
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("From Date")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  date.from_date,
                                                  "DD/MMM/YYYY hh:mm A"
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _c("tr", [
                                          _c("th", [_vm._v("To Date")]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("dateFormat")(
                                                  date.to_date,
                                                  "DD/MMM/YYYY hh:mm A"
                                                )
                                              )
                                            )
                                          ])
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(date.parking_rates, function(
                                          rate,
                                          i
                                        ) {
                                          return date.parking_rates.length
                                            ? _c("tr", [
                                                _c("th", [
                                                  _vm._v(
                                                    "Parking Rate " +
                                                      _vm._s(i + 1)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    "\n                    ( $" +
                                                      _vm._s(
                                                        rate.price_of_one_unit
                                                      ) +
                                                      "\n                    * " +
                                                      _vm._s(
                                                        rate.number_of_days
                                                      ) +
                                                      " days\n                    * " +
                                                      _vm._s(
                                                        rate.number_of_parkings
                                                      ) +
                                                      " spots ) - " +
                                                      _vm._s(
                                                        rate.daily_or_hourly
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ])
                                              ])
                                            : _vm._e()
                                        }),
                                        _vm._v(" "),
                                        _vm._l(date.room_rates, function(
                                          rate,
                                          i
                                        ) {
                                          return date.room_rates.length
                                            ? _c("tr", [
                                                _c("th", [
                                                  _vm._v(
                                                    "Room Rate " + _vm._s(i + 1)
                                                  )
                                                ]),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _vm._v(
                                                    "\n                  ( $" +
                                                      _vm._s(
                                                        rate.price_of_one_unit
                                                      ) +
                                                      "\n                  * " +
                                                      _vm._s(
                                                        rate.number_of_rooms
                                                      ) +
                                                      " room/s )\n                  "
                                                  ),
                                                  rate.number_of_extra_occupants >
                                                  0
                                                    ? _c("span", [
                                                        _vm._v(
                                                          "\n                    + ( $" +
                                                            _vm._s(
                                                              rate.extra_occupant_price
                                                            ) +
                                                            "\n                    * " +
                                                            _vm._s(
                                                              rate.number_of_extra_occupants
                                                            ) +
                                                            " extra occupants )\n                  "
                                                        )
                                                      ])
                                                    : _vm._e()
                                                ])
                                              ])
                                            : _vm._e()
                                        })
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              })
                            )
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Creation Date")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.reservation.created_at,
                                    "DD/MMM/YYYY hh:mm A"
                                  )
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Latest Update")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm._f("dateFormat")(
                                    _vm.current.created_at,
                                    "DD/MMM/YYYY hh:mm A"
                                  )
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Location Name")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                _vm._s(
                                  _vm.current.location
                                    ? _vm.current.location.name
                                    : ""
                                )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Guest Name")]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.current.guest_name))])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Subtotal")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v("$" + _vm._s(_vm.current.subtotal))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Total Fees")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v("$" + _vm._s(_vm.current.total_fees))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Grand Total")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v("$" + _vm._s(_vm.current.grand_total))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Due at Check-in")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(
                                "$" +
                                  _vm._s(
                                    _vm.activeHistory.due_at_location_total
                                  )
                              )
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Reserved By")]),
                            _vm._v(" "),
                            _c("td", [
                              _vm._v(_vm._s(_vm.reservation.reserved_by))
                            ])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Contact Email")]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.current.email))])
                          ]),
                          _vm._v(" "),
                          _c("tr", [
                            _c("th", [_vm._v("Contact Phone")]),
                            _vm._v(" "),
                            _c("td", [_vm._v(_vm._s(_vm.current.phone))])
                          ])
                        ])
                      ]
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "tab",
                { attrs: { title: "History" } },
                _vm._l(_vm.history, function(item) {
                  return _vm.history
                    ? _c(
                        "table",
                        { staticClass: "table table-bordered margin-top-2x" },
                        [
                          _c("tbody", [
                            _c("tr", [
                              _c("th", [_vm._v("Creation Date")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("dateFormat")(
                                      item.created_at,
                                      "DD/MMM/YYYY hh:mm A"
                                    )
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Reservation Info")]),
                              _vm._v(" "),
                              _c(
                                "td",
                                _vm._l(item.dates, function(date) {
                                  return _vm.current
                                    ? _c(
                                        "table",
                                        { staticClass: "table" },
                                        [
                                          _c("tr", [
                                            _c("th", [_vm._v("Type")]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(_vm._s(date.type_type))
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("th", [_vm._v("From Date")]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    date.from_date,
                                                    "DD/MMM/YYYY hh:mm A"
                                                  )
                                                )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c("th", [_vm._v("To Date")]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("dateFormat")(
                                                    date.to_date,
                                                    "DD/MMM/YYYY hh:mm A"
                                                  )
                                                )
                                              )
                                            ])
                                          ]),
                                          _vm._v(" "),
                                          _vm._l(date.parking_rates, function(
                                            rate,
                                            i
                                          ) {
                                            return date.parking_rates.length
                                              ? _c("tr", [
                                                  _c("th", [
                                                    _vm._v(
                                                      "Parking Rate " +
                                                        _vm._s(i + 1)
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      "\n                    ( $" +
                                                        _vm._s(
                                                          rate.price_of_one_unit
                                                        ) +
                                                        "\n                    * " +
                                                        _vm._s(
                                                          rate.number_of_days
                                                        ) +
                                                        " days\n                    * " +
                                                        _vm._s(
                                                          rate.number_of_parkings
                                                        ) +
                                                        " spots ) - " +
                                                        _vm._s(
                                                          rate.daily_or_hourly
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ])
                                                ])
                                              : _vm._e()
                                          }),
                                          _vm._v(" "),
                                          _vm._l(date.room_rates, function(
                                            rate,
                                            i
                                          ) {
                                            return date.room_rates.length
                                              ? _c("tr", [
                                                  _c("th", [
                                                    _vm._v(
                                                      "Room Rate " +
                                                        _vm._s(i + 1)
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c("td", [
                                                    _vm._v(
                                                      "\n                    ( $" +
                                                        _vm._s(
                                                          rate.price_of_one_unit
                                                        ) +
                                                        "\n                    * " +
                                                        _vm._s(
                                                          rate.number_of_rooms
                                                        ) +
                                                        " room/s )\n                    "
                                                    ),
                                                    rate.number_of_extra_occupants >
                                                    0
                                                      ? _c("span", [
                                                          _vm._v(
                                                            "\n                      + ( $" +
                                                              _vm._s(
                                                                rate.extra_occupant_price
                                                              ) +
                                                              "\n                      * " +
                                                              _vm._s(
                                                                rate.number_of_extra_occupants
                                                              ) +
                                                              " extra occupants )\n                    "
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ])
                                                ])
                                              : _vm._e()
                                          })
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                })
                              )
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Location Name")]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  _vm._s(
                                    item.location
                                      ? _vm.current.location.name
                                      : ""
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Contact Email")]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(item.email))])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Subtotal")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("$" + _vm._s(item.subtotal))])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Total Fees")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("$" + _vm._s(item.total_fees))])
                            ]),
                            _vm._v(" "),
                            _c("tr", [
                              _c("th", [_vm._v("Grand Total")]),
                              _vm._v(" "),
                              _c("td", [_vm._v("$" + _vm._s(item.grand_total))])
                            ])
                          ])
                        ]
                      )
                    : _vm._e()
                })
              )
            ],
            1
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-5ef59602", { render: render, staticRenderFns: staticRenderFns })
  }
}