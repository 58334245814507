import env from 'constants/env'
import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
}

const computed = {
  currencies () {
    return [].concat(this.$store.state.currencies.all);
  },
}

const watch = {
}

const methods = {
  onSubmitFom () {
    const encoder = new TextEncoder();
    const description_byte_size = encoder.encode(this.form.description).length;
    const short_description_byte_size = encoder.encode(this.form.short_description).length;

    const max_size = 15 * 1024 * 1024;

    if (description_byte_size > max_size || short_description_byte_size > max_size) {
      toastr.error('Content size exceeds the limit of 16 MB.');
      return;
    }
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('tripProtections.add', {
      lang: this.lang,
      data: _.cloneDeep(this.form)
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.tripProtections.errors) {
        this.errors = _.cloneDeep(this.$store.state.tripProtections.errors.errors);
        toastr.error(this.$store.state.tripProtections.errors.message);
      } else {
        toastr.success('A new Trip Protections has been created');
        this.$router.push({name: 'tripProtections.all'});
      }
    })
  },

  wysiwygDescriptionChanged: function(val) {
    this.form.description = val
  },

  wysiwygShortDescriptionChanged: function(val) {
    this.form.short_description = val
  },

  createPrice() {
    this.form.trip_protection_prices.push({currency_id: null, price: 0});
  },

  removePrice(index) {
    this.form.trip_protection_prices.splice(index, 1)
  }
}

export default {
  name: 'new-trip-protections',
  components,
  computed,
  methods,
  watch,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      lang: env.default.lang,
      errors: {},
      form: {
        name: null,
        short_description: null,
        description: null,
        number_of_hours: 0,
        unit_type: 'parking',
        before_or_after: 'after',
        trip_protection_prices: [
          {currency_id: null, price: 0}
        ]
      }
    }
  },
  mounted () {
  },
  created () {
    this.$store.dispatch('currencies.getAll');
  }
}
