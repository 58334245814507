var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-sm-8 col-sm-offset-2 margin-top-3x" },
        [
          _c("new-fee", {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["fee_create"],
                expression: "['fee_create']",
                arg: "allow"
              }
            ]
          }),
          _vm._v(" "),
          _vm.fees.length
            ? _c("div", [
                _c("table", { staticClass: "table table-striped" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.fees, function(item) {
                      return _c("tr", [
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.name) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.description) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                item.associated_to == "room"
                                  ? "Rooms"
                                  : "Parkings"
                              ) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.amount) +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.fixed ? "$" : "%") +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.daily ? "Daily" : "One Time") +
                              "\n            "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["fee_update"],
                                expression: "['fee_update']",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _vm.loadings[item.id]
                              ? _c("div", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin"
                                  })
                                ])
                              : _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.editFee(item.id)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-pencil-square-o"
                                    })
                                  ]
                                )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          {
                            directives: [
                              {
                                name: "hasPermits",
                                rawName: "v-hasPermits:allow",
                                value: ["fee_delete"],
                                expression: "['fee_delete']",
                                arg: "allow"
                              }
                            ]
                          },
                          [
                            _vm.loadings[item.id]
                              ? _c("div", [
                                  _c("i", {
                                    staticClass: "fa fa-spinner fa-spin"
                                  })
                                ])
                              : _c(
                                  "a",
                                  {
                                    staticClass: "text-danger",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.removeFee(item.id)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-trash" })]
                                )
                          ]
                        )
                      ])
                    })
                  )
                ])
              ])
            : _c("div", { staticClass: "text-warning" }, [
                _vm._v("\n      There are no fees yet\n    ")
              ])
        ],
        1
      ),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } }),
      _vm._v(" "),
      _c("edit-fee", { ref: "editFeeModal", attrs: { lang: _vm.lang } })
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v(" Name ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" Description ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" For ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" Amount ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" Type ")]),
        _vm._v(" "),
        _c("th", [_vm._v(" calculation ")]),
        _vm._v(" "),
        _c("th"),
        _vm._v(" "),
        _c("th")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-50e8a46f", { render: render, staticRenderFns: staticRenderFns })
  }
}