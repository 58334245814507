import { funcModal } from 'components/modals'
import { escapeScript } from 'filters'
const components = {
  funcModal,
}

const computed = {
  faq () {
    return this.$store.state.faqs.active;
  },
}

const filters = {
  escapeScript
}

const methods = {
  open () {
    this.$refs.modal.open();
  },

  cleanHtmlString (string) {
    return escapeScript(string);
  } 
}

export default {
  name: "view-faq-modal",
  components,
  methods,
  computed,
  filters,
  data () {
    return {}
  }
}
