var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h1", [_c("small", [_vm._v("Locations")])])
      ]),
      _vm._v(" "),
      !_vm.loading
        ? _c("div", { staticClass: "row margin-bottom-3x" }, [
            _c("div", { staticClass: "col-md-3" }, [
              _c("div", { staticClass: "input-group" }, [
                _c("input", {
                  ref: "search",
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Search for..." },
                  domProps: { value: _vm.searchKey },
                  on: {
                    keyup: function($event) {
                      if (!("button" in $event) && $event.keyCode !== 13) {
                        return null
                      }
                      _vm.search(_vm.$refs.search.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "input-group-btn" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.search(_vm.$refs.search.value)
                        }
                      }
                    },
                    [_vm._v("Go!")]
                  )
                ])
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["location_delete"],
                      expression: "['location_delete']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "input-group radio"
                },
                [
                  _c("label", { staticClass: "margin-right-2x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter"
                        }
                      ],
                      attrs: { type: "radio", value: "active" },
                      domProps: { checked: _vm._q(_vm.filter, "active") },
                      on: {
                        change: [
                          function($event) {
                            _vm.filter = "active"
                          },
                          function($event) {
                            _vm.filterChanged()
                          }
                        ]
                      }
                    }),
                    _vm._v("\n            Active\n          ")
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "margin-right-2x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter"
                        }
                      ],
                      attrs: { type: "radio", value: "deleted" },
                      domProps: { checked: _vm._q(_vm.filter, "deleted") },
                      on: {
                        change: [
                          function($event) {
                            _vm.filter = "deleted"
                          },
                          function($event) {
                            _vm.filterChanged()
                          }
                        ]
                      }
                    }),
                    _vm._v("\n            Deleted\n          ")
                  ]),
                  _vm._v(" "),
                  _c("label", { staticClass: "margin-right-2x" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filter,
                          expression: "filter"
                        }
                      ],
                      attrs: { type: "radio", value: "all" },
                      domProps: { checked: _vm._q(_vm.filter, "all") },
                      on: {
                        change: [
                          function($event) {
                            _vm.filter = "all"
                          },
                          function($event) {
                            _vm.filterChanged()
                          }
                        ]
                      }
                    }),
                    _vm._v("\n            All\n          ")
                  ])
                ]
              )
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "hasPermits",
                    rawName: "v-hasPermits:allow",
                    value: ["location_synclocationswithims"],
                    expression: "['location_synclocationswithims']",
                    arg: "allow"
                  }
                ],
                staticClass: "col-md-9"
              },
              [
                _c("div", { staticClass: "text-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      class: { disabled: _vm.syncing },
                      attrs: { disabled: _vm.syncing },
                      on: { click: _vm.syncLocations }
                    },
                    [
                      _c("i", {
                        staticClass: "fa fa-refresh",
                        class: {
                          "fa-spin fa-fw": _vm.syncing
                        },
                        attrs: { "aria-hidden": "true" }
                      }),
                      _vm._v("\n          Sync Locations\n        ")
                    ]
                  )
                ])
              ]
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c(
            "div",
            [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-striped" }, [
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Location Name")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("address")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Phone")]),
                      _vm._v(" "),
                      _c("th", [_vm._v("Edit")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.locations.data, function(item, i) {
                      return _c("tr", [
                        _c("td", [_vm._v(_vm._s(item.name))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.address))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(item.phone))]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c(
                              "router-link",
                              {
                                attrs: {
                                  to: {
                                    name: "locations.edit",
                                    params: { id: item.id }
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-pencil",
                                  attrs: { "aria-hidden": "true" }
                                })
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    })
                  )
                ])
              ]),
              _vm._v(" "),
              _c("pagination", {
                attrs: { "pages-number": _vm.locations.last_page },
                on: {
                  pageChanged: function(page) {
                    return _vm.loadPage(page)
                  }
                }
              })
            ],
            1
          ),
      _vm._v(" "),
      _c("confirm", { ref: "confirmDelete", attrs: { type: "danger" } }),
      _vm._v(" "),
      _c("confirm", { ref: "confirmUndoDelete", attrs: { type: "success" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-47c025c2", { render: render, staticRenderFns: staticRenderFns })
  }
}