import toastr from 'toastr'
import moment from 'moment'
import { currencySymbol, dateFormat } from 'filters'
import {dateTime} from 'services'


const components = {
  toastr
}

const filters = {
  currencySymbol,
  dateFormat
}

const computed = {
  reservation () {
    return _.cloneDeep(this.$store.state.reservations.active)
  },
  allPricesLoaded () {
    return !this.combinationLoading &&
    !this.parkingLoading &&
    !this.roomLoading &&
    !this.bundleLoading
  },

  currentSymbol () {
    return !this.selected_currency ? this.symbol : currencySymbol(this.selected_currency);
  },

  locationTypes () {
    return _.cloneDeep(this.$store.state.locations.active.types);
  }
}

const methods = {
  checkChanges () {
    console.log('xxxx ---->>>>>');
    if (this.validateForm()) {
      this.calculateCosts();
    }
  },
  calculateCosts ({
    parking_type = null,
    room_type = null,
    bundle_type = null,
    finalCost = true
  } = {}) {
    // clear previous cost
    this.cost = null;
    this.$emit('updateCost', this.cost);
    // console.log('calculateCosts hello',this.coupon_code);
    
    parking_type = parking_type || this.reservation_types.parking_type;
    bundle_type = bundle_type || this.reservation_types.bundle_type;
    room_type = room_type || this.reservation_types.room_type;

    if (this.editMode && this.searchType == 'same_location') {

      this.selected_location = {
        id: this.activeHistory.location_id
      }

      parking_type = parking_type || this.activeHistory.parking_type;
      bundle_type = bundle_type || this.activeHistory.bundle_id;

      if (!room_type && this.activeHistory.rooms.length) {
        room_type = room_type || this.activeHistory.rooms[0].room_type;
      }

      this.check_avaliability_loading = true;
    } else {
      if (!this.selected_location.id) {
        return
      }
    }

    this.form.errors = {}
    let _fromTime = null;
    let _toTime = null;
    var data = {
      location_id: this.selected_location.id,
      reservation_type: this.reservationType,
      finalCost
    };

    data = _.assign(data, this.extraFields);
    let dateFormat = "YYYY-MM-DD HH:mm:ss";
    let timeFormat = "HH:mm";

    if (this.reservationType == 'both') {

      if (this.nightsToSleep == 'beginning') {
        _fromTime = this.selected_location.checkin_time
        ? this.selected_location.checkin_time
        : '12:00';

        _toTime = this.toTime;
      }

      if (this.nightsToSleep == 'end') {
        _fromTime = this.fromTime;

        _toTime = this.selected_location.checkout_time 
        ? this.selected_location.checkout_time
        : '12:00';
      }

    }

    if (this.reservationType == 'room' || this.nightsToSleep == 'both') {
      _toTime = this.selected_location.checkout_time 
      ? this.selected_location.checkout_time
      : '12:00';
      
      _fromTime = this.selected_location.checkin_time
      ? this.selected_location.checkin_time
      : '12:00';
    }


    if (this.editMode) {
      if (this.reservationType == 'room' || this.nightsToSleep == 'both') {
        _fromTime = moment(this.activeHistory.from_date, [dateFormat]).format(timeFormat);
        _toTime = moment(this.activeHistory.to_date, [dateFormat]).format(timeFormat);
      }

      if (this.nightsToSleep == 'beginning') {
        _fromTime = moment(this.activeHistory.from_date, [dateFormat]).format(timeFormat);
      }

      if (this.nightsToSleep == 'end') {
        _toTime = moment(this.activeHistory.to_date, [dateFormat]).format(timeFormat);
      }

    }

    if (this.reservationType == 'parking') {
      _fromTime = this.fromTime; 
      _toTime = this.toTime;
    }
    
    data.from_date = moment(`${this.fromDate} ${_fromTime}`, ['DD/MMM/YYYY hh:mm A']).format(`YYYY-MM-DD HH:mm:00`)
    data.to_date = moment(`${this.toDate} ${_toTime}`, ['DD/MMM/YYYY hh:mm A']).format(`YYYY-MM-DD HH:mm:00`);
    
    data.bundle_type = bundle_type;
    data.bundle_id = bundle_type;

    if (this.reservationType == 'room' || this.reservationType == 'both') {
      data.number_of_rooms = this.numberOfRooms;
      data.guests_in_rooms = this.guestsInRooms.slice(0, this.numberOfRooms);
      data.guests_names = this.guestsNames.slice(0, this.numberOfRooms);
      data.room_type = room_type;

    }

    if (this.reservationType == 'parking' || this.reservationType == 'both') {
      data.number_of_spots = this.numberOfSpots;
      data.parking_type = parking_type;
    }

    if (this.reservationType == 'both') {
      data.nights_to_sleep = this.nightsToSleep;
    }

    if (this.selected_currency != null) {
      data.currency_code = this.selected_currency;
    }

    if (this.reservation) {
      data.reservation_id = this.reservation.id
    }

    if (finalCost) {

      data.coupon_code = this.couponCode;

      try {
        if (this.tripProtection.id) {
          data.trip_protection_id = this.tripProtection.id;
        }
      } catch(e) {
        data.trip_protection_id = null;
      }

    }

    this.$emit('toggleLoading',"cost_loading")
    this.$emit('updateCouponData', {
      coupon_error:null,
      coupon_success:false,
      coupon_code:this.couponCode,
    })
    this.coupon_error = null
    this.coupon_success = false;

    console.log('data', data);

    return (this.$store.dispatch('reservations.cost', data)).then((res) => {
      this.check_avaliability_loading = false;
      this.$emit('toggleLoading',"cost_loading")
      if (this.$store.state.reservations.errors ) {
        if (this.$store.state.reservations.errors.status_code == 400) {
          let error_code = !_.isEmpty(this.$store.state.reservations.errors.error_code);
          if ( error_code && this.$store.state.reservations.errors.error_code == "hours_before_reservation_error") {
            toastr.error(this.$store.state.reservations.errors.message);
          } else {
            toastr.error(this.$store.state.reservations.errors.message);
          }
        } else {
          this.errors = _.cloneDeep(this.$store.state.reservations.errors.errors);
          toastr.error(this.$store.state.reservations.errors.message);
        } 
      } else {

        if (finalCost) {
          this.cost = _.cloneDeep(this.$store.state.reservations.cost);

          if (this.cost.reservation.sold_out) {
            toastr.error('Location is sold out');
          }

          if (this.cost.reservation.coupon) {
            if (this.cost.reservation.coupon.error) {
              // this.coupon_code = data.coupon_code = null
              this.$emit('updateCouponData', {
                coupon_error:this.cost.reservation.coupon.message,
                coupon_success:false,
                // coupon_code:null,
              })
            }
          }

          let returnData = _.clone(data);
          returnData.location_id = this.selected_location.id
          returnData.airport_id = this.airportId
          returnData.amount_to_pay = this.cost.reservation.amount_to_pay
          returnData.due_at_location = this.cost.reservation.due_at_location

          if (this.activeHistory) {
            let totalRoomDays = this.activeHistory.number_of_sleeping_nights;
            let totalParkingDays = this.activeHistory.number_of_parking_days;

            this.cost.diff = {
              room: {},
              parking: {}
            }

            if (this.cost.total_days.parking.number_of_days) {
              this.cost.diff.parking = {
                days:  this.cost.total_days.parking.number_of_days - totalParkingDays,
                price:  this.cost.total_days.parking.sub_total - (this.activeHistory.parking_price)
              }
            }

            try {
              this.cost.diff.parking.tripProtection = this.cost.reservation.trip_protection_total - this.activeHistory.trip_price.price
            } catch(e) {
              this.cost.diff.parking.tripProtection = this.cost.reservation.trip_protection_total;
            }

            if (this.cost.total_days.room.number_of_days) {
              this.cost.diff.room = {
                days: this.cost.total_days.room.number_of_days - totalRoomDays,
                price: this.cost.total_days.room.sub_total -  ((this.activeHistory.rooms[0] ? this.activeHistory.rooms[0].room_price : 0) * totalRoomDays)
              }
            }

            this.cost.diff.due_at_location = this.cost.reservation.due_at_location - this.activeHistory.due_at_location_total;
            this.cost.diff.subtotal = this.cost.reservation.sub_total - this.activeHistory.subtotal;
            this.cost.diff.grand_total = this.cost.reservation.grand_total - this.activeHistory.grand_total;
          }

          let extraTotal = 0

          _.each(this.cost.rates, function(rate) {
            _.each(rate.extra, function(extra) {
              extraTotal += Number(extra.total)
            })
          });

          returnData.extra_total = extraTotal
          this.cost.extra_total = extraTotal
          console.log('return Data xxx', returnData);
          this.$emit('searchCompleted', returnData);
          this.$emit('updateCost', this.cost);
        }
      }


    })

  },
  setCombinations () {
    this.combinations = [];
    let promises = [];
    this.roomTypes.forEach((room, i) => {

      this.parkingTypes.forEach((parking, j) => {
        
        this.combinations = this.combinations.concat([{
          parking_type: parking,
          room_type: room,
          sold_out: room.sold_out || parking.sold_out,
          number_of_room_days: this.nightsToSleep == 'both' ? 2 : 1,
          number_of_parking_days: 0,
          loading: true,
          price: null
        }]);
        
        promises.push(this.calculateCosts({
            parking_type: parking.id,
            room_type: room.id,
            finalCost: false})
        );
      })
    });

    if (promises.length) {
      this.combinationLoading = true;
    }

    Promise.all(promises).then(() => {
      this.setTypesCosts('combination');
    });
  },
  compareTypeWithReservationType (type) {
    return (type == 'room' && this.reservationType == 'room')
        || (type == 'parking' && this.reservationType == 'parking')
        || (type == 'bundle' && this.reservationType == 'both');
  },
  setLocationTypes (type, deselect_options = true) {
    if (deselect_options) {
      this.reservation_types.room_type = null;
      this.reservation_types.parking_type = null;
      this.reservation_types.bundle_type = null;
    }
    let promises = [];
    try {
      let types = this.selected_location.types[type];
      this[type + 'Types'] = [];
      types.forEach((item, i) => {

        this[type + 'Types'] = this[type + 'Types'].concat([item]);

        if (item.sold_out) {
          this.$set(this.selected_location.types[type][i], 'sold_out', true);
        }
        
        this.$set(this.selected_location.types[type][i], 'loading', true);
        this.$set(this.selected_location.types[type][i], 'price', null);
        this.$set(this.selected_location.types[type][i], 'number_of_days', null);
        if (this.compareTypeWithReservationType(type)) {
          promises.push(
            this.calculateCosts({
              [type + '_type']: this[type + 'Types'][i].id,
              finalCost: false
            })
          );
        }
      });
    } catch (e) { console.log('Error when setting location type ' + type, e); }

    if (promises.length) {
      this[type + 'Loading'] = true;
    }

    Promise.all(promises).then(() => {
      this.setTypesCosts(type);
    });
  },
  setTypesCosts (type) {

    let selected_type = (type == 'combination') ? 'combinations' : (type + 'Types');

    let costs = this.$store.state.reservations[type + '_costs'];
    if (costs.length) {
      for (let i in costs) {
        for (let j in this[selected_type]) {

          if (
            (selected_type == 'combinations'
            && this.combinations[j].parking_type.id
              == costs[i].request.parking_type
            && this.combinations[j].room_type.id
              == costs[i].request.room_type)
            || (selected_type != 'combinations'
            && this[selected_type][j].id
              == costs[i].request[type + '_type'])
          ) {

            this.$set(this[selected_type][j], 'price', costs[i].reservation.grand_total);
            this.$set(this[selected_type][j], 'sold_out', costs[i].reservation.sold_out);
            if (costs[i].total_days) {
              this.$set(this[selected_type][j], 'number_of_parking_days', costs[i].total_days['parking'].number_of_days);
              this.$set(this[selected_type][j], 'number_of_room_days', costs[i].total_days['room'].number_of_days);
            }
            this.$set(this[selected_type][j], 'loading', false);

            break;
          }

        }
      }
      this[type + 'Loading'] = false;
      // console.log("selected_type", selected_type)
      this.$set(this.form, selected_type ,this[selected_type]);
    }
  },

  combinationIsCheaper (bundle) {
    for (let i in this.combinations) {
      if (!this.combinations[i].price) continue;

      if (
        this.combinations[i].parking_type.id == bundle.parking_type.id
        && this.combinations[i].room_type.id == bundle.room_type.id
        && this.combinations[i].price < bundle.price
      ) {
        return true;
      }
    }

    return false;
  },

  bundleIsCheaper (combination) {
    for (let i in this.bundleTypes) {
      if (!this.bundleTypes[i].price) continue;

      if (
        this.bundleTypes[i].parking_type.id == combination.parking_type.id
        && this.bundleTypes[i].room_type.id == combination.room_type.id
        && this.bundleTypes[i].price <= combination.price
      ) {
        return true;
      }
    }

    return false;
  },

  onCombinationTypeChange () {
    this.reservation_types.room_type = this.reservation_types.combination_type.room_type.id;
    this.reservation_types.parking_type = this.reservation_types.combination_type.parking_type.id;
    this.reservation_types.bundle_type = null;
    this.calculateCosts();
  },

  onBundleTypeChange () {
    this.reservation_types.room_type = null;
    this.reservation_types.parking_type = null;
    this.reservation_types.combination_type = null;
    this.calculateCosts();
  },

  onChangeRoomType () {
    this.reservation_types.parking_type = null;
    this.resetBundleAndCallCalculate();
  },

  onChangeParkingType () {
    this.reservation_types.room_type = null;
    this.resetBundleAndCallCalculate();
  },

  resetBundleAndCallCalculate() {
    this.reservation_types.bundle_type = null;
    this.reservation_types.combination_type = null;
    this.calculateCosts();
  },
  setBookingOptions () {
    this.setLocationTypes('room', this.deselectOption);
    this.setLocationTypes('parking', this.deselectOption);
    this.setLocationTypes('bundle', this.deselectOption);
    this.setCombinations();
  }
}

const watch = {
  selected_location(newValue) {
    if (!_.isEmpty(newValue)) {
      this.setBookingOptions();
    }
  },

  selectedCurrency (n, o) {
    if (n == o) return;
    this.selected_currency = n;
    this.calculateCosts();
    this.setBookingOptions();
  },
  couponCode(newValue) {
    // this.coupon_code = newValue;
    console.log('newValue', newValue);
    this.calculateCosts();
  },
  tripProtection (n, o) {
    if (n == o) return;
    this.calculateCosts();
  },
  selectedLocation (n) {
    this.selected_location = n;
  },
  searchLoading (n) {
    if (n && this.deselectOption) {
      this.reservation_types.parking_type = null;
      this.reservation_types.bundle_type = null;
      this.reservation_types.combination_type = null;
    }
  },
}

export default {
  name: "booking-options",
  props: [
    'reservationType',
    'symbol',
    'selectedLocation',
    'fromDate',
    'toDate',
    'editMode',
    'guestsInRooms',
    'guestsNames',
    'numberOfRooms',
    'nightsToSleep',
    'numberOfSpots',
    'airportId',
    'fromTime',
    'toTime',
    'couponCode',
    'tripProtection',
    'searchType',
    'searchResults',
    'activeHistory',
    'extraFields',
    'searchLoading',
    'selectedCurrency',
    'deselectOption',
    'validateForm'
  ],
  components,
  methods,
  computed,
  filters,
  watch,
  data () {
    return {
      form: {
        combinations: [],
        bundleTypes: [],
        parkingTypes: [],
        roomTypes: [],
      },
      cost: null,
      check_avaliability_loading: false,
      selected_location: this.selectedLocation,
      reservation_types: {
        combination_type: null,
        bundle_type: null,
        parking_type: null,
        room_type: null,
      },
      roomTypes: [],
      parkingTypes: [],
      bundleTypes: [],
      combinations: [],
      selectedAmenities: [],
      roomLoading: false,
      parkingLoading: false,
      bundleLoading: false,
      combinationLoading: false,
      selected_currency: this.selectedCurrency,
    }
  },
  mounted () {
  },
  created () {
    if (!_.isEmpty(this.selected_location)) {
      this.setBookingOptions();
    }
  }
}
