var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("layout", [
    _c("div", { staticClass: "page-header" }, [
      _c("h1", [_c("small", [_vm._v("Reviews")])])
    ]),
    _vm._v(" "),
    _vm.loading
      ? _c("div", { staticClass: "text-center" }, [
          _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
        ])
      : _c(
          "div",
          [
            _c("div", { staticClass: "table-responsive" }, [
              _c("table", { staticClass: "table table-striped" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Review")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Date")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Customer Name")]),
                    _vm._v(" "),
                    _c("th", [_vm._v("Email")]),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        directives: [
                          {
                            name: "hasPermits",
                            rawName: "v-hasPermits:allow",
                            value: ["review_update"],
                            expression: "['review_update']",
                            arg: "allow"
                          }
                        ]
                      },
                      [_vm._v("Edit")]
                    ),
                    _vm._v(" "),
                    _c("th", { staticClass: "text-right" }, [
                      _vm._v("Published")
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.reviews.data, function(item, i) {
                    return _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.title) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.created_at) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.customer.name) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(item.customer.email) +
                            "\n            "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "reviews.edit",
                                  params: { id: item.id }
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "fa fa-pencil",
                                attrs: { "aria-hidden": "true" }
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["review_update"],
                              expression: "['review_update']",
                              arg: "allow"
                            }
                          ],
                          staticClass: "text-right"
                        },
                        [
                          !item.published
                            ? _c("i", {
                                staticClass: "fa fa-check-circle ",
                                attrs: { "aria-hidden": "true" },
                                on: {
                                  click: function($event) {
                                    _vm.updateStatus(item, true)
                                  }
                                }
                              })
                            : _c("i", {
                                staticClass: "fa fa-check-circle",
                                attrs: { "aria-hidden": "true" },
                                on: {
                                  click: function($event) {
                                    _vm.updateStatus(item, false)
                                  }
                                }
                              })
                        ]
                      )
                    ])
                  })
                )
              ])
            ]),
            _vm._v(" "),
            _c("pagination", {
              attrs: { "pages-number": _vm.reviews.last_page },
              on: {
                pageChanged: function(page) {
                  return _vm.loadPage(page)
                }
              }
            })
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-f3c71f4e", { render: render, staticRenderFns: staticRenderFns })
  }
}