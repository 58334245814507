var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("no-ssr", [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("nav", { staticClass: "navbar navbar-default margin-top-3x" }, [
              _c("div", { staticClass: "container-fluid" }, [
                _c(
                  "div",
                  { staticClass: "navbar-header" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "navbar-toggle collapsed",
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#bs-example-navbar-collapse-1",
                          "aria-expanded": "false"
                        }
                      },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Toggle navigation")
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "icon-bar" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "icon-bar" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "icon-bar" })
                      ]
                    ),
                    _vm._v(" "),
                    _vm.isAffiliate
                      ? _c(
                          "div",
                          { staticClass: "affiliate-logo-header" },
                          [
                            !_vm._.isEmpty(_vm.affiliate)
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "affiliate-logo",
                                    attrs: { to: "/" }
                                  },
                                  [
                                    _c("img", {
                                      ref: "affiliateLogo",
                                      attrs: {
                                        src: _vm.affiliate.logo,
                                        alt: ""
                                      }
                                    })
                                  ]
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _c(
                          "router-link",
                          { staticClass: "navbar-brand", attrs: { to: "/" } },
                          [
                            _c("image-bg", {
                              staticClass: "logo",
                              attrs: { src: "/assets/images/trip-teo.svg" }
                            })
                          ],
                          1
                        )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "collapse navbar-collapse",
                    attrs: { id: "bs-example-navbar-collapse-1" }
                  },
                  [
                    _c("ul", { staticClass: "nav navbar-nav" }, [
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["location_list"],
                              expression: "['location_list']",
                              arg: "allow"
                            }
                          ],
                          class: {
                            active:
                              _vm.$store.state.nav.active.indexOf("locations") >
                              -1
                          }
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "locations.all" } } },
                            [
                              _vm._v(
                                "\n                    Locations\n                  "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["airport_list"],
                              expression: "['airport_list']",
                              arg: "allow"
                            }
                          ],
                          class: {
                            active:
                              _vm.$store.state.nav.active.indexOf("ports") > -1
                          }
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "ports.all" } } },
                            [
                              _vm._v(
                                "\n                    Ports\n                  "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "li",
                        {
                          directives: [
                            {
                              name: "hasPermits",
                              rawName: "v-hasPermits:allow",
                              value: ["reservation_list"],
                              expression: "['reservation_list']",
                              arg: "allow"
                            }
                          ],
                          class: {
                            active:
                              _vm.$store.state.nav.active.indexOf(
                                "reservations"
                              ) > -1
                          }
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "reservations.all" } } },
                            [
                              _vm._v(
                                "\n                    Reservations\n                  "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      !_vm.isAffiliate
                        ? _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["coupon_list"],
                                  expression: "['coupon_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.$store.state.nav.active.indexOf(
                                    "coupons"
                                  ) > -1
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "coupons.all" } } },
                                [
                                  _vm._v(
                                    "\n                    Coupons\n                  "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("li", { staticClass: "dropdown" }, [
                        _c(
                          "a",
                          {
                            staticClass: "dropdown-toggle",
                            attrs: {
                              href: "#",
                              "data-toggle": "dropdown",
                              role: "button",
                              "aria-haspopup": "true",
                              "aria-expanded": "false"
                            }
                          },
                          [
                            _vm._v("\n                    More "),
                            _c("i", {
                              staticClass: "fa fa-caret-down",
                              attrs: { "aria-hidden": "true" }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c("ul", { staticClass: "dropdown-menu" }, [
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["role_list"],
                                  expression: "['role_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.$store.state.nav.active.indexOf("roles") >
                                  -1
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "roles.all" } } },
                                [
                                  _vm._v(
                                    "\n                        Roles\n                      "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["user_list"],
                                  expression: "['user_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.$store.state.nav.active.indexOf("users") >
                                  -1
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "users.all" } } },
                                [
                                  _vm._v(
                                    "\n                        Users\n                      "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.isAffiliate
                            ? _c(
                                "li",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: ["customer_list"],
                                      expression: "['customer_list']",
                                      arg: "allow"
                                    }
                                  ],
                                  class: {
                                    active:
                                      _vm.$store.state.nav.active.indexOf(
                                        "customers"
                                      ) > -1
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: { to: { name: "customers.all" } }
                                    },
                                    [
                                      _vm._v(
                                        "\n                        Customers\n                      "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isAffiliate
                            ? _c(
                                "li",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: ["review_list"],
                                      expression: "['review_list']",
                                      arg: "allow"
                                    }
                                  ],
                                  class: {
                                    active:
                                      _vm.$store.state.nav.active.indexOf(
                                        "reviews"
                                      ) > -1
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    { attrs: { to: { name: "reviews.all" } } },
                                    [
                                      _vm._v(
                                        "\n                        Reviews\n                      "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["page_list"],
                                  expression: "['page_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.$store.state.nav.active.indexOf("pages") >
                                  -1
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "pages.all" } } },
                                [
                                  _vm._v(
                                    "\n                          Pages\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.isAffiliate
                            ? _c(
                                "li",
                                {
                                  directives: [
                                    {
                                      name: "hasPermits",
                                      rawName: "v-hasPermits:allow",
                                      value: ["tripprotection_list"],
                                      expression: "['tripprotection_list']",
                                      arg: "allow"
                                    }
                                  ],
                                  class: {
                                    active:
                                      _vm.$store.state.nav.active.indexOf(
                                        "tripProtections"
                                      ) > -1
                                  }
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: { name: "tripProtections.all" }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                          Trip Protections\n                        "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["amenity_list"],
                                  expression: "['amenity_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.$store.state.nav.active.indexOf(
                                    "amenities"
                                  ) > -1
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "amenities.all" } } },
                                [
                                  _vm._v(
                                    "\n                          Amenities\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["carousel_list"],
                                  expression: "['carousel_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.$store.state.nav.active.indexOf(
                                    "carousels"
                                  ) > -1
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "carousels.all" } } },
                                [
                                  _vm._v(
                                    "\n                          Homepage Carousels\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["faqcategory_list"],
                                  expression: "['faqcategory_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.$store.state.nav.active.indexOf(
                                    "faq_categories"
                                  ) > -1
                              }
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  attrs: { to: { name: "faq_categories.all" } }
                                },
                                [
                                  _vm._v(
                                    "\n                          FAQ Categories\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["faq_list"],
                                  expression: "['faq_list']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.$store.state.nav.active.indexOf("faqs") >
                                  -1
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "faqs.all" } } },
                                [
                                  _vm._v(
                                    "\n                          FAQs\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["super_admin"],
                                  expression: "['super_admin']",
                                  arg: "allow"
                                }
                              ],
                              class: {
                                active:
                                  _vm.$store.state.nav.active.indexOf(
                                    "reports"
                                  ) > -1
                              }
                            },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: { name: "reports.all" } } },
                                [
                                  _vm._v(
                                    "\n                          Reports\n                        "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ]),
                    _vm._v(" "),
                    _c("ul", { staticClass: "navbar-right nav navbar-nav" }, [
                      _c(
                        "li",
                        {
                          class: {
                            active:
                              _vm.$store.state.nav.active.indexOf("settings") >
                              -1
                          }
                        },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: { name: "settings" } } },
                            [_vm._v("Personal Settings")]
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("li", [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.logout($event)
                              }
                            }
                          },
                          [_vm._v("Logout")]
                        )
                      ])
                    ])
                  ]
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _vm._t("default"),
        _vm._v(" "),
        !_vm.isAffiliate
          ? _c("div", { staticClass: "footer" }, [
              _vm._v("\n      © TripTeo\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.isAffiliate && _vm.affiliate
          ? _c("div", { staticClass: "footer" }, [
              _vm._v("\n      © " + _vm._s(_vm.affiliate.name) + "\n    ")
            ])
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3f7e4975", { render: render, staticRenderFns: staticRenderFns })
  }
}