import { http } from 'services'

// initial state
const state = () => ({
  all: [],
  active: null,
  errors: null,
})

// getters
const getters = {
}

// actions
const actions = {
  
  ['carousels.getAll'] ({ commit, state }, params) {
    commit('CLEAR_ERRORS')
    if (!params._q) params._q = null  
    return (http.get('carousels', {
      params
    }))
    .then ((res) => {
      commit('GET_ALL_CAROUSELS', res);
    })
    .catch ((res) => {
      commit('CAROUSELS_ERROR', res);
    })
  },

  ['carousels.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')

    return (http.get(`carousels/${id}`))
    .then ((res) => {
      commit('GET_CAROUSEL', res);
    })
    .catch ((res) => {
      commit('CAROUSELS_ERROR', res);
    })
  },

  ['carousels.new'] ({ commit },{data, image}) {
    
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();

    formData.append('text',data.text);
    
    if (data.link !== null) {
      formData.append('link',data.link);
    }
    if (data.title !== null) {
      formData.append('title',data.title);
    }
    if (image.length) {
      formData.append("image", image[0]);  
    }
    
    return (http.post(`carousels/`, formData, config)).then ((res) => {
      commit('CAROUSELS_UPDATE', res);
    }).catch ((res) => {
      commit('CAROUSELS_ERROR', res);
    })
    
  },

  ['carousels.edit'] ({ commit },{data, image, id}) {
    
    commit('CLEAR_ERRORS')
    let config = {
      headers: {
        'Content-Type': ''
      },
      "processData": false,
      "contentType": false,
      "mimeType": "multipart/form-data",
    }
    let formData = new FormData();

    formData.append('text',data.text);
    
    if (data.link !== null) {
      formData.append('link',data.link);
    }
    if (data.title !== null) {
      formData.append('title',data.title);
    }
    if (image.length) {
      formData.append("image", image[0]);  
    }
    
    return (http.post(`carousels/${id}`, formData, config)).then ((res) => {
      commit('GET_ALL_CAROUSELS', res);
    }).catch ((res) => {
      commit('CAROUSELS_ERROR', res);
    })
    
  },

  ['carousels.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`carousels/${id}`)).then ((res) => {
      commit('REMOVE_CAROUSEL', res);
    }).catch ((res) => {
      commit('CAROUSELS_ERROR', res);
    })
  },

  ['carousels.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`carousels/${id}?_undelete=true`)).then ((res) => {
      commit('UNDO_REMOVE_CAROUSEL', res);
    }).catch ((res) => {
      commit('CAROUSELS_ERROR', res);
    })
  },

}

// mutations
const mutations = {
  GET_ALL_CAROUSELS (state, res) {
    if (res)
      state.all = res.data
  },

  GET_CAROUSEL (state, res) {
    if (res)
      state.active = res.data
  },

  REMOVE_CAROUSEL (state, res) {
    state.all.data = _.filter(state.all.data, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(state.all);
  },

  UNDO_REMOVE_CAROUSEL (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  //ERRORS
  CAROUSELS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  },

  
}

export default {
  state,
  getters,
  actions,
  mutations
}
