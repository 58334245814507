var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c("div", { staticClass: "page-header" }, [
        _c("h1", [_c("small", [_vm._v("Reports")])])
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-circle-o-notch fa-spin fa-3x fa-fw" })
          ])
        : _c(
            "div",
            [
              _c("div", { staticClass: "card mb-4" }, [
                _c("div", { staticClass: "card-block" }, [
                  _c("div", { staticClass: "row margin-bottom-3x" }, [
                    _c("div", { staticClass: "col-md-3" }, [
                      _c("label", { attrs: { for: "reportType" } }, [
                        _vm._v("Report Type")
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          staticClass: "form-control",
                          attrs: { id: "reportType" },
                          domProps: { value: _vm.report_type },
                          on: {
                            change: function($event) {
                              _vm.onSelectReport($event.target.value)
                            }
                          }
                        },
                        [
                          _c("option", { domProps: { value: null } }, [
                            _vm._v("-- Choose Report --")
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["super_admin"],
                                  expression: "['super_admin']",
                                  arg: "allow"
                                }
                              ],
                              attrs: { value: "deferredRevenues" }
                            },
                            [_vm._v("Deferred Revenues")]
                          ),
                          _vm._v(" "),
                          _c(
                            "option",
                            {
                              directives: [
                                {
                                  name: "hasPermits",
                                  rawName: "v-hasPermits:allow",
                                  value: ["super_admin"],
                                  expression: "['super_admin']",
                                  arg: "allow"
                                }
                              ],
                              attrs: { value: "commission" }
                            },
                            [_vm._v("Commission Report")]
                          )
                        ]
                      )
                    ])
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "card mb-4" }, [
                _c("div", { staticClass: "card-block" }, [
                  _c("div", { staticClass: "row" }, [
                    _vm.report_type == "deferredRevenues"
                      ? _c("div", { staticClass: "w-100 p-3 margin-top-3x" }, [
                          _c("div", { staticClass: "col-sm-3" }, [
                            _c("div", { staticClass: "form-input" }, [
                              _c(
                                "div",
                                { staticClass: "input-group" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "input-group-addon" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "input-group-text" },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-calendar",
                                            attrs: { "aria-hidden": "true" }
                                          })
                                        ]
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("datepicker", {
                                    staticClass: "form-control dashboard",
                                    attrs: {
                                      "date-format": "MMMM/YYYY",
                                      "min-date": "none",
                                      options: _vm.date_picker_settings,
                                      "hide-days": true
                                    },
                                    model: {
                                      value: _vm.filters.from,
                                      callback: function($$v) {
                                        _vm.$set(_vm.filters, "from", $$v)
                                      },
                                      expression: "filters.from"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.errors.from
                                ? _c("div", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.errors.from[0]) +
                                        "\n                            "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-3 padding-2x text-center" },
                            [
                              _c("div", { staticClass: "form-input" }, [
                                _c("span", [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.prevMonth($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-chevron-left",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "margin-right-3x margin-left-3x"
                                    },
                                    [
                                      _vm._v(
                                        "\n                              " +
                                          _vm._s(this.filters.from) +
                                          "\n                          "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "#" },
                                      on: {
                                        click: function($event) {
                                          $event.preventDefault()
                                          return _vm.nextMonth($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-chevron-right",
                                        attrs: { "aria-hidden": "true" }
                                      })
                                    ]
                                  )
                                ])
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-sm-6" },
                            [_c("deferred-revenues-emails")],
                            1
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("dates") &&
                    _vm.report_type != "deferredRevenues"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-input" },
                              [
                                _c("label", [_vm._v("From Date")]),
                                _vm._v(" "),
                                _c("datepicker", {
                                  staticClass: "form-control",
                                  attrs: {
                                    "min-date": "none",
                                    "display-format": "mm/dd/yy"
                                  },
                                  model: {
                                    value: _vm.filters.from_date,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters, "from_date", $$v)
                                    },
                                    expression: "filters.from_date"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.from_date
                                  ? _c("div", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                                  " +
                                          _vm._s(_vm.errors.from_date[0]) +
                                          "\n                              "
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("dates") &&
                    _vm.report_type != "deferredRevenues"
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-input" },
                              [
                                _c("label", [_vm._v("To Date")]),
                                _vm._v(" "),
                                _c("datepicker", {
                                  staticClass: "form-control",
                                  attrs: {
                                    "min-date": _vm.filters.from_date,
                                    "display-format": "mm/dd/yy"
                                  },
                                  model: {
                                    value: _vm.filters.to_date,
                                    callback: function($$v) {
                                      _vm.$set(_vm.filters, "to_date", $$v)
                                    },
                                    expression: "filters.to_date"
                                  }
                                }),
                                _vm._v(" "),
                                _vm.errors.to_date
                                  ? _c("div", { staticClass: "text-danger" }, [
                                      _vm._v(
                                        "\n                                  " +
                                          _vm._s(_vm.errors.to_date[0]) +
                                          "\n                              "
                                      )
                                    ])
                                  : _vm._e()
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("reservation_id")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c("div", { staticClass: "form-input" }, [
                              _c("label", [_vm._v("Reservation #")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.reservation_id,
                                    expression: "filters.reservation_id"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.filters.reservation_id },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "reservation_id",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("location")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "form-input" },
                              [
                                _c("label", [_vm._v("Location Name")]),
                                _vm._v(" "),
                                _c("autocomplete", {
                                  attrs: {
                                    placeholder: "Search for a location",
                                    label: "name",
                                    "input-class": "form-control",
                                    url: _vm.env.api + "locations?_q="
                                  },
                                  on: { select: _vm.onSelectLocation }
                                })
                              ],
                              1
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("customer")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c("div", { staticClass: "form-input" }, [
                              _c("label", [_vm._v("Customer Name")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filters.customer_name,
                                    expression: "filters.customer_name"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: { type: "text" },
                                domProps: { value: _vm.filters.customer_name },
                                on: {
                                  input: function($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.filters,
                                      "customer_name",
                                      $event.target.value
                                    )
                                  }
                                }
                              })
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("trip_protection")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c("div", { staticClass: "form-input" }, [
                              _c("label", [_vm._v("Trip Protection Plan")]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filters.trip_protection_id,
                                      expression: "filters.trip_protection_id"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filters,
                                        "trip_protection_id",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { domProps: { value: null } }, [
                                    _vm._v("Select Plan")
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(_vm.trip_protections, function(plan) {
                                    return _c(
                                      "option",
                                      {
                                        key: plan.id,
                                        domProps: { value: plan.id }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                      " +
                                            _vm._s(plan.title) +
                                            "\n                                  "
                                        )
                                      ]
                                    )
                                  })
                                ],
                                2
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("commission_dates")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c("div", { staticClass: "form-input" }, [
                              _c("label", [_vm._v("Filter Date On")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radio margin-top-2x" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.filter_date_on,
                                            expression: "filters.filter_date_on"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "created_at"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.filters.filter_date_on,
                                            "created_at"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filter_date_on",
                                              "created_at"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      Creation date\n                                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.filter_date_on,
                                            expression: "filters.filter_date_on"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "from_date"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.filters.filter_date_on,
                                            "from_date"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filter_date_on",
                                              "from_date"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      From date\n                                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.filter_date_on,
                                            expression: "filters.filter_date_on"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "to_date"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.filters.filter_date_on,
                                            "to_date"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.$set(
                                              _vm.filters,
                                              "filter_date_on",
                                              "to_date"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      To date\n                                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("status")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c("div", { staticClass: "form-input" }, [
                              _c("label", [_vm._v("Status")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radio margin-top-2x" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.active,
                                            expression: "filters.active"
                                          }
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: null,
                                          checked: _vm._q(
                                            _vm.filters.active,
                                            null
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.$set(
                                              _vm.filters,
                                              "active",
                                              null
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      All\n                                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.active,
                                            expression: "filters.active"
                                          }
                                        ],
                                        attrs: { type: "radio", value: "true" },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.filters.active,
                                            "true"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.$set(
                                              _vm.filters,
                                              "active",
                                              "true"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      Active\n                                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.active,
                                            expression: "filters.active"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "false"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.filters.active,
                                            "false"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.$set(
                                              _vm.filters,
                                              "active",
                                              "false"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      Completed\n                                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("modification")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c("div", { staticClass: "form-input" }, [
                              _c("label", [_vm._v("Cancelled or Modified")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "checkbox margin-top-2x" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.modified,
                                            expression: "filters.modified"
                                          }
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.filters.modified
                                          )
                                            ? _vm._i(
                                                _vm.filters.modified,
                                                null
                                              ) > -1
                                            : _vm.filters.modified
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.filters.modified,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.filters,
                                                    "modified",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.filters,
                                                    "modified",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.filters,
                                                "modified",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      Modified\n                                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.cancelled,
                                            expression: "filters.cancelled"
                                          }
                                        ],
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.filters.cancelled
                                          )
                                            ? _vm._i(
                                                _vm.filters.cancelled,
                                                null
                                              ) > -1
                                            : _vm.filters.cancelled
                                        },
                                        on: {
                                          change: function($event) {
                                            var $$a = _vm.filters.cancelled,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.filters,
                                                    "cancelled",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.filters,
                                                    "cancelled",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.filters,
                                                "cancelled",
                                                $$c
                                              )
                                            }
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      Cancelled\n                                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("due_at_location")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c("div", { staticClass: "form-input" }, [
                              _c("label", [_vm._v("Due at Location")]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "radio margin-top-2x" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.due_at_location,
                                            expression:
                                              "filters.due_at_location"
                                          }
                                        ],
                                        attrs: { type: "radio" },
                                        domProps: {
                                          value: null,
                                          checked: _vm._q(
                                            _vm.filters.due_at_location,
                                            null
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.$set(
                                              _vm.filters,
                                              "due_at_location",
                                              null
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      All\n                                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.due_at_location,
                                            expression:
                                              "filters.due_at_location"
                                          }
                                        ],
                                        attrs: { type: "radio", value: "true" },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.filters.due_at_location,
                                            "true"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.$set(
                                              _vm.filters,
                                              "due_at_location",
                                              "true"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      Due at location\n                                  "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "label",
                                    { staticClass: "margin-right-1x" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.filters.due_at_location,
                                            expression:
                                              "filters.due_at_location"
                                          }
                                        ],
                                        attrs: {
                                          type: "radio",
                                          value: "false"
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.filters.due_at_location,
                                            "false"
                                          )
                                        },
                                        on: {
                                          change: function($event) {
                                            _vm.$set(
                                              _vm.filters,
                                              "due_at_location",
                                              "false"
                                            )
                                          }
                                        }
                                      }),
                                      _vm._v(
                                        "\n                                      Not due at location\n                                  "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showFilter("timezone")
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-3 col-md-4 col-sm-6 margin-bottom-2x"
                          },
                          [
                            _c("div", { staticClass: "form-input" }, [
                              _c("label", [_vm._v("Timezone")]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.filters.timezone,
                                      expression: "filters.timezone"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  on: {
                                    change: function($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call($event.target.options, function(
                                          o
                                        ) {
                                          return o.selected
                                        })
                                        .map(function(o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.$set(
                                        _vm.filters,
                                        "timezone",
                                        $event.target.multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("option", { attrs: { value: "EST" } }, [
                                    _vm._v("EST")
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "UTC" } }, [
                                    _vm._v("UTC")
                                  ])
                                ]
                              )
                            ])
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.show_run
                      ? _c(
                          "div",
                          { staticClass: "col-sm-6 margin-bottom-2x" },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary",
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    return _vm.run($event)
                                  }
                                }
                              },
                              [
                                _c("i", {
                                  staticClass: "fa fa-paper-plane-o",
                                  attrs: { "aria-hidden": "true" }
                                }),
                                _vm._v(
                                  "\n                      Run Report\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary dropdown-toggle",
                                class: { disabled: _vm.processing },
                                attrs: {
                                  type: "button",
                                  "data-toggle": "dropdown",
                                  "aria-haspopup": "true",
                                  "aria-expanded": "false",
                                  disabled: _vm.processing
                                }
                              },
                              [
                                _vm.processing
                                  ? _c("i", {
                                      staticClass:
                                        "fa fa-circle-o-notch fa-spin"
                                    })
                                  : _c("i", {
                                      staticClass: "fa fa-download",
                                      attrs: { "aria-hidden": "true" }
                                    }),
                                _vm._v(
                                  "\n                      Export\n                  "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "dropdown-menu export-report" },
                              [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        _vm.exportReport("csv")
                                      }
                                    }
                                  },
                                  [_vm._v("CSV")]
                                )
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ])
              ]),
              _vm._v(" "),
              _vm.report_type == "deferredRevenues"
                ? _c("deferred-revenues", {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["super_admin"],
                        expression: "['super_admin']",
                        arg: "allow"
                      }
                    ],
                    ref: "table"
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.report_type == "commission"
                ? _c("commission", {
                    directives: [
                      {
                        name: "hasPermits",
                        rawName: "v-hasPermits:allow",
                        value: ["super_admin"],
                        expression: "['super_admin']",
                        arg: "allow"
                      }
                    ],
                    ref: "table"
                  })
                : _vm._e()
            ],
            1
          ),
      _vm._v(" "),
      _c("exportReport", { ref: "export" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-60162c06", { render: render, staticRenderFns: staticRenderFns })
  }
}