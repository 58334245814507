var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dropdown s-select" }, [
    _c(
      "div",
      {
        staticClass: "toggler",
        attrs: {
          "data-toggle": "dropdown",
          "aria-haspopup": "true",
          "aria-expanded": "false"
        }
      },
      [_vm._v("\n    " + _vm._s(_vm.value.text) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "dropdown-menu", attrs: { "aria-labelledby": "dLabel" } },
      [
        _c("li", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.filter_value,
                expression: "filter_value"
              }
            ],
            staticClass: "form-control margin-bottom-2x",
            attrs: { type: "text", placeholder: "start typing to filter" },
            domProps: { value: _vm.filter_value },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.filter_value = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _vm._l(_vm.filterredOptions, function(option) {
          return _c(
            "li",
            {
              on: {
                click: function($event) {
                  _vm.selectOption(option)
                }
              }
            },
            [_vm._v(_vm._s(option.text))]
          )
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-05a12132", { render: render, staticRenderFns: staticRenderFns })
  }
}