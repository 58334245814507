import { tab, tabs } from "components/tabs"
import { funcModal } from 'components/modals'
import { dateFormat } from 'filters'

const components = {
  funcModal,
  tab, tabs
}

const filters = {
  dateFormat
}

const methods = {
  open () {
    this.$refs.modal.open();
  }
}

const computed = {
  current () {
    console.log("this.reservation", this.reservation);
    return this.reservation ? this.reservation.history.filter((item) => item.active)[0] : null
  },
  history () {
    return this.reservation ? this.reservation.history.filter((item) => !item.active) : [];
  },
  activeHistory () {
    return this.reservation ? this.reservation.history.filter((item) => item.active) : [];
  }
}

export default {
  name: "reservation-details-modal",
  props: ['reservation'],
  components,
  filters,
  methods,
  computed
}
