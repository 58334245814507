import { http } from 'services'
// initial state
const state = () => ({
  all: [],
  active: {},
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['customers.getAll'] ({ commit }, params) {
    if (!params._q) params._q = null

    commit('CLEAR_ERRORS')

    return (http.get('customers', {
      params
    })).then ((res) => {
      commit('GET_ALL_CUSTOMERS', res);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },
  ['customers.newCustomer'] ({ commit }, data) {
    commit('CLEAR_ERRORS')

    return (http.post('customers', data))
    .then ((res) => {
      // commit('NEW_CUSTOMER', res);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },
  ['customers.editCustomer'] ({ commit }, {id, data}) {
    commit('CLEAR_ERRORS')
    return (http.put('customers/' + id , data))
    .then ((res) => {
      commit('EDIT_CUSTOMER', res);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },
  ['customers.removeCustomer'] ({ commit }, id) {
    commit('CLEAR_ERRORS')

    return (http.delete('customers/' + id))
    .then ((res) => {
      commit('REMOVE_CUSTOMER', id);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },
  ['customers.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`customers/${id}?_undelete=true`)).then ((res) => {
      commit('UNDO_REMOVE_CUSTOMER', res);
    }).catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },
  ['customers.getCustomer'] ({ dispatch, commit, state }, id) {
    commit('CLEAR_ERRORS')
    // return dispatch('roles.getAll', {_all: true}).then(() => {
      return (http.get('customers/' + id))
      .then ((res) => {
        commit('GET_CUSTOMER', res);
      })
      .catch ((res) => {
        commit('CUSTOMERS_ERROR', res);
      })
    // })
  },
  ['customers.getCustomerByEmail'] ({ dispatch, commit, state }, email) {
    commit('CLEAR_ERRORS')
    return (http.get('customers/get', {
      params: {email}
    }))
    .then ((res) => {
      commit('GET_CUSTOMER', res);
    })
    .catch ((res) => {
      commit('CUSTOMERS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_CUSTOMERS (state, res) {

    state.all = res.data;
  },

  GET_CUSTOMER (state, res) {
    state.active = res.data
  },

  REMOVE_CUSTOMER (state, id) {
    state.all.data = _.filter(state.all.data, (item) => item.id != id);
  },

  UNDO_REMOVE_CUSTOMER (state, res) {
    let indx = _.findIndex(state.all.data, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all.data[indx] = _.assign(state.all.data[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  EDIT_CUSTOMER (state, res) {
    state.active = res.data
  },
  // NEW_CUSTOMER (state, res) {
  //   // state.all.data =
  // },

  //ERRORS
  CUSTOMERS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
