import toastr from 'toastr'
import confirm from 'components/modals/confirm'
import { funcModal } from 'components/modals'
import { http, stripeService } from 'services'
import { currencySymbol } from 'filters'
import payment from '../payment'
import threeDSecure from '../threeDSecure'

const components = {
  funcModal,
  payment,
  confirm,
  threeDSecure
}

const watch = {
  tripProtection(n, o) {
    this.activePlan = n;
  },
}

const computed = {
  reservation () {
    return this.$store.state.reservations.active;
  },

  activeHistory () {
    return _.find(this.$store.state.reservations.active.history, function (h) {return h.active == 1;});
  },

  cost () {
    return _.cloneDeep(this.$store.state.reservations.cost);
  },
  
  isShorting () {
    return this.activeHistory.number_of_parking_days > this.cost.total_days.parking.number_of_days
      ||  this.activeHistory.number_of_sleeping_nights > this.cost.total_days.room.number_of_days
  }
}

const filters = {
}

const methods = {
  open () {
    this.show_force_refund = true;
    this.force_refund = false;
    this.$refs.modal.open();
    window.top.postMessage({name: 'popup', tyoe: 'updateModal'}, "*");
  },

  symbol (code) {
    return currencySymbol(code);
  },

  initiateStripe () {
    this.loadingStripe = true;
    let affiliate = isAffiliate ? _.cloneDeep(this.$store.state.affiliates.active) : null;
    stripeService.init(this.reservation.stripe_account, affiliate).then(({stripe, elements}) => {
      this.loadingStripe = false;
      this.stripe = stripe;

      let classes = {
        base: 'form-control'
      }

      let style = {
        base: {}
      }

      this.elements.card_element = elements.create('cardNumber', {classes, style});
      this.elements.expiration_element = elements.create('cardExpiry', {classes, style});
      this.elements.cvc_element = elements.create('cardCvc', {classes, style});

      this.elements.card_element.mount('#cardNumber-element');
      this.elements.expiration_element.mount('#expiration-element');
      this.elements.cvc_element.mount('#cvc-element');

      this.elements.card_element.addEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.addEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.addEventListener('change', this.onChangeCvcElement);

    });
  },

  destroyStripe () {
    try {
      this.elements.card_element.removeEventListener('change', this.onChangeCardElement);
      this.elements.expiration_element.removeEventListener('change', this.onChangeExpirationElement);
      this.elements.cvc_element.removeEventListener('change', this.onChangeCvcElement);
    } catch (e) {}

    try {
      this.elements.card_element.destroy()
      this.elements.expiration_element.destroy()
      this.elements.cvc_element.destroy()
    } catch (e) {}
  },

  onChangeCardElement () {
    this.errors.card_number = null;
  },

  onChangeExpirationElement () {
    this.errors.expiration = null;
  },

  onChangeCvcElement () {
    this.errors.cvc = null;
  },

  onUseNewCardClicked () {
    this.use_new_card = true;
    this.$nextTick(() => {
      this.initiateStripe()
    })
  },

  onDoNotUseNewCard () {
    this.use_new_card = false;
    this.destroyStripe()
  },

  onSubmitForm () {
    if (!this.use_new_card) {
      this.confirm();
      return;
    }
    this.$set(this, 'errors', {});
    this.loading = true;
    this.stripe.createSource(this.elements.card_element).then((result) => {
      if (result.error) {
        if (result.error.code == 'incomplete_expiry') {
          this.$set(this.errors, 'expiration', [result.error.message]);
        } else if (result.error.code == 'incomplete_cvc') {
          this.$set(this.errors, 'cvc', [result.error.message]);
        } else {
          this.$set(this.errors, 'card_number', [result.error.message]);
        }

        this.loading = false;
        return;
      }

      this.card_info.three_d_secure = result.source.card.three_d_secure;

      this.stripe.createToken(this.elements.card_element, {
        address_zip: this.card_info.zipcode || '',
        name: this.card_info.first_name  + ' ' + this.card_info.last_name,
      }).then((result) => {
        if (result.error) {
          if (result.error.code == 'incomplete_expiry') {
            this.$set(this.errors, 'expiration', [result.error.message]);
          } else if (result.error.code == 'incomplete_cvc') {
            this.$set(this.errors, 'cvc', [result.error.message]);
          } else {
            this.$set(this.errors, 'card_number', [result.error.message]);
          }

          this.loading = false;
          return;
        }

        this.card_info.card_number = result.token.card.last4
        this.card_info.expiry_month = result.token.card.exp_month
        this.card_info.expiry_year = result.token.card.exp_year
        this.card_info.card_id = result.token.card.id
        this.card_info.brand = result.token.card.brand
        this.card_info.card_token = result.token.id

        this.confirm();
      });
    })
  },

  confirm() {
    this.errors = {};
    let data = _.cloneDeep(this.formData);
    data.reserved_by = data.email || data.reserved_by;
    if (this.use_new_card) {
      this.card_info.name_on_card = `${this.card_info.first_name} ${this.card_info.last_name}`

      for (var i in this.card_info) {
        if (!this.card_info[i]) {
          this.$set(this.errors, i, ['this field is required'])
        } else {
          data[i] = this.card_info[i];
        }
      }

      if (!_.isEmpty(this.errors)) {
        this.loading = false;
        return
      }
    }

    if (!data.room_type) {
      try {
        data.room_type = this.formData.rooms[0].room_type
        // data.guests_in_rooms = []

        // for(let i=0; i < this.formData.rooms.length;i++) {
        //   data.guests_in_rooms.push(this.formData.rooms[i].number_of_guests)
        // }
      } catch (e) { console.log('there are no rooms in this reservation'); }
    }

    if (!data.country) {
      data.country = "none"
    }
    try {
      if (this.activePlan.id && this.activePlan.price) {
        data.trip_protection_id = this.activePlan.id
      } else {
        data.trip_protection_id = null
      }
    } catch (e) {
      data.trip_protection_id = null
    }

    data.url = this.threeDsecure.redirectUrl;

    if (this.threeDsecure.source_id) {
      data.source_id = this.threeDsecure.source_id;
    }

    if (this.reservation.is_started && this.cost && this.isShorting && this.force_refund) {
      if (!data.notes) {
        this.$set(this.errors, 'notes', ['this field is required'])
        this.loading = false;
        return
      }

      data.force_to_update = true;
    }

    this.errors = {}
    this.loading = true;
    this.$store.dispatch('reservations.edit', {id: this.reservation.id, data}).then(() => {
      this.threeDsecure.source_id = null;
      this.loading = false;
      let errors = this.$store.state.reservations.errors;

      if (errors) {
        if (errors.status_code == 400 )  {
          if (!_.isEmpty(errors.error_code) && errors.error_code == '3d_secure_required' ) {
            toastr.warning('The card you used requires 3d secure confirmation');
            this.threeDsecure.url = errors.extra_params.return_url;
          } else {
            toastr.error(errors.message);
          }
        } else if (errors.errors) {
          this.errors = _.cloneDeep(errors.errors);
          toastr.error(errors.message);
        }
      } else {
        toastr.success('Reservation has been updated');
        let reservation = this.$store.state.reservations.active;
        let reslabFailedMessage = reservation.reslab_failed_message ? reservation.reslab_failed_message : false;
        if (reslabFailedMessage) {
          this.$refs.warningConfirm.confirm(reslabFailedMessage, () => {this.finisthUpdate();}, () => {this.finisthUpdate();}, false)
        } else {
          this.finisthUpdate();
        }
      }
    })
  },

  finisthUpdate() {
    if (this.$route.name == 'reservations.editFrame') {
      let message = {
        name: 'updated'
      }
      window.top.postMessage(message, "*");

      this.$router.push({
        name: 'reservations.editFrame',
        params: {
          id: this.$route.params.id
        }
      });
    } else {
      this.$router.push({name: 'reservations.all'});
    }
    this.$refs.modal.close()
  },

  // couponApplied(coupon) {
  //   this.formData.coupon_code = coupon
  // },
  //
  // couponRemoved() {
  //   this.formData.coupon_code = null
  // },

  onCloseThreeDSecure () {
    this.threeDsecure.url = null;
  },

  forceRefund() {
    this.show_force_refund = false;
    this.force_refund = true;
    this.amount_to_pay = this.cost.reservation.grand_total - this.cost.reservation.due_at_location_before_cancellation_policy  - this.activeHistory.transaction_amnt
  }
}

export default {
  name: "view-update-reservation-modal",
  props: ['amount_to_pay', 'formData', 'tripProtection'],
  components,
  methods,
  computed,
  filters,
  watch,
  data () {
    return {
      loading: false,
      use_new_card: false,
      force_refund: false,
      show_force_refund: true,
      errors: {},
      card_info: {
        // I kept `name_on_card` attribute in the form to know that we need it
        // in the sj-backend, we hide it from customer but we handle it beyond the scene
        name_on_card: '',
        first_name: '',
        last_name: '',
        zipcode: null,
        card_number: null,
        expiry_month: null,
        expiry_year: null,
        three_d_secure: null,
        card_id: null,
        brand: null,
        card_token: null,
      },
      coupon_error_msg: null,
      coupon_success: false,
      activePlan: this.tripProtection,
      loadingStripe: false,
      stripe: null,
      elements: {
        card_element: null,
        expiration_element: null,
        cvc_element: null,
      },
      threeDsecure: {
        source_id: null,
        url: null,
        redirectUrl: null
      },
    }
  },

  mounted () {
    this.threeDsecure.redirectUrl = document.location.protocol + '//' + document.location.hostname + (location.port ? ':'+location.port: '') + '/login'

    window.setSource = (source) => {
      console.log('source', source);
      this.threeDsecure.source_id = source;
      this.threeDsecure.url = null;
      this.confirm();
    }
  },

  destroyed () {
    console.log('destroy');
    this.destroyStripe()
  }
}
