import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'
const components = {
  layout
}

const directives = {
  hasPermits
}

const computed = {
  countries () {
    let countries = [{name: "Select Country", id: null}]
      .concat(this.$store.state.address.countries)

    return countries
  },
  states () {
    let states = [{name: "Select State", id: null}];
    if (this.form.country_id) {

      let indx = _.findIndex(this.countries, item => item.id == this.form.country_id);
      if (indx > -1) {
        states = states.concat(this.countries[indx].states);
      }
    }

    return states;
  }
}

const methods = {
  submit () {
    this.errors = {}
    if (this.form.password) {
      if (this.form.password !== this.form.password_confirmation) {
        this.errors.password = ['Password and password confirmation do not match']

        return;
      }
    }

    try {
      if (this.form.roles[0] == '') {
        delete this.form.roles;
      }
    } catch(e) {}

    this.processing = true;
    this.$store.dispatch('customers.newCustomer', this.form)
    .then(() => {
      this.processing = false;
      this.form.roles = [];
      let errors = this.$store.state.customers.errors;
      if (errors) {
          this.errors = _.cloneDeep(errors.errors);
          toastr.error(this.$store.state.customers.errors.message);
      } else {
        this.$router.replace({name: 'customers.all'});
      }
    });
  }
}

export default {
  name: 'new-cusetomer',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      form: {
        first_name: null,
        last_name: null,
        email: null,
        password: null,
        address1: null,
        address2: null,
        zip_code: null,
        country_id: null,
        state_id: null,
      },
      loading: false,
      processing: false,
      errors: {}
    }
  },
  created () {
    this.$store.dispatch('address.getCountries');
  }
}
