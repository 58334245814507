import Login from 'pages/login'
import ResetPassword from 'pages/resetPassword'
import Settings from 'pages/settings'
import {all as AllUsers, new as NewUser, edit as EditUser} from 'pages/users'
import {all as AllCustomers, new as NewCustomer, edit as EditCustomer} from 'pages/customers'
import { all as AllPorts, new as NewPort, edit as EditPort } from 'pages/ports'
import { all as AllPages, new as NewPage, edit as EditPage } from 'pages/pages'
import { all as AllTripProtections, new as NewTripProtection, edit as EditTripProtection } from 'pages/tripProtections'
import { all as AllLocations, edit as EditLocation } from 'pages/locations'
import {all as AllRoles, new as NewRole, edit as EditRole} from 'pages/roles'
import {all as AllCoupons, new as NewCoupon, edit as EditCoupon} from 'pages/coupons'
import { all as AllReservations, new as NewReservation, edit as EditReservation, editFrame as EditReservationFrame} from 'pages/reservations'
import { all as AllReviews, edit as EditReview} from 'pages/reviews'
import { all as AllReports} from 'pages/reports'
import { all as AllAmenities, edit as EditAmenity} from 'pages/amenities'
import { all as AllCarousels, new as NewCarousel, edit as EditCarousel} from 'pages/carousels'
import { all as AllFAQs, new as NewFAQ, edit as EditFAQ} from 'pages/faqs'
import { all as AllFaqCategories, new as NewFaqCategory, edit as EditFaqCategory} from 'pages/faqCategories'
import PageNotFound from 'pages/notfound/pageNotFound'

let commonRoutes = [
  //Locations
  { path: '/locations', name: 'locations.all', component: AllLocations
    , meta:{permits:['location_list']}},
  { path: '/locations/:id', name: 'locations.edit', component: EditLocation
    , meta:{permits:['location_list']}},
  //Airports
  { path: '/ports', name: 'ports.all', component: AllPorts
    , meta:{permits:['port_list']}},
  { path: '/ports/new', name: 'ports.new', component: NewPort
    , meta:{permits:['port_create']}},
  { path: '/ports/:id', name: 'ports.edit', component: EditPort
    , meta:{permits:['port_list']}},
  //Roles
  { path: '/roles', name: 'roles.all', component: AllRoles
    , meta:{permits:['role_list']}},
  { path: '/roles/new', name: 'roles.new', component: NewRole
    , meta:{permits:['role_create']}},
  { path: '/roles/edit/:id', name: 'roles.edit', component: EditRole
    , meta:{permits:['role_list']}},
  //Users
  { path: '/users', name: 'users.all', component: AllUsers
    , meta:{permits:['user_list']}},
  { path: '/users/new', name: 'users.new', component: NewUser
    , meta:{permits:['user_create']}},
  { path: '/users/edit/:id', name: 'users.edit', component: EditUser
    , meta:{permits:['user_list']}},

  // Reservations
  { path: '/reservations', name: 'reservations.all', component: AllReservations
    , meta:{permits:['reservation_list']}},
  { path: '/reservations/new', name: 'reservations.new', component: NewReservation
    , meta:{permits:['reservation_create']}},
  { path: '/reservations/edit/:id', name: 'reservations.edit', component: EditReservation
    , meta:{permits:['reservation_update']}},
  { path: '/reservations/edit-frame/:id', name: 'reservations.editFrame', component: EditReservationFrame
    , meta:{permits:['reservation_update']}},

  //Pages
  { path: '/pages', name: 'pages.all', component: AllPages
    , meta:{permits:['page_list']}},
  { path: '/pages/new', name: 'pages.new', component: NewPage
    , meta:{permits:['page_create']}},
  { path: '/pages/:id', name: 'pages.edit', component: EditPage
    , meta:{permits:['page_list']}},

  // Amenities 
  { path: '/amenities', name: 'amenities.all', component: AllAmenities
    , meta:{permits:['amenity_list']}},

  { path: '/amenities/:id', name: 'amenities.edit', component: EditAmenity
    , meta:{permits:['amenity_list']}}, 

  // Carousels 
  { path: '/carousels', name: 'carousels.all', component: AllCarousels
    , meta:{permits:['carousel_list']}},
  
  { path: '/carousels/new', name: 'carousels.new', component: NewCarousel
    , meta:{permits:['carousel_create']}},

  { path: '/carousels/:id', name: 'carousels.edit', component: EditCarousel
  , meta:{permits:['carousel_update']}},


  //User settings
  { path: '/settings', name: 'settings', component: Settings},

  // FAQs Routes 
  { path: '/faqs', name: 'faqs.all', component: AllFAQs, meta: {permits:['faq_list']}},
  { path: '/faqs/new', name: 'faqs.new', component: NewFAQ, meta: {permits:['faq_create']}},
  { path: '/faqs/:id', name: 'faqs.edit', component: EditFAQ, meta: {permits:['faq_update']}},

  { path: '/faqs-categories', name: 'faq_categories.all', component: AllFaqCategories, meta: {permits:['faqcategory_list']}},
  { path: '/faqs-categories/new', name: 'faq_categories.new', component: NewFaqCategory, meta: {permits:['faqcategory_create']}},
  { path: '/faqs-categories/:id', name: 'faq_categories.edit', component: EditFaqCategory, meta: {permits:['faqcategory_update']}},
  
  //reports
  { path: '/reports', name: 'reports.all', component: AllReports, meta: {permits:['super_admin']}},

  //Main and login
  { path: '/login', name: 'login', component: Login},
  { path: '/reset-password', name: 'resetPassword', component: ResetPassword
    , meta: {public: true}},
  { path: '/', name: 'home', redirect: {name: 'locations.all'} },
  { path: '*', name: 'notfound', component: PageNotFound
    , meta: {public: true}}
]

// in case is affiliate 
let excludeRoutes = [
  //Coupons
  { path: '/coupons', name: 'coupons.all', component: AllCoupons
    , meta:{permits:['coupon_list']}},
  { path: '/coupons/new', name: 'coupons.new', component: NewCoupon
    , meta:{permits:['coupon_create']}},
  { path: '/coupons/:id', name: 'coupons.edit', component: EditCoupon
    , meta:{permits:['coupon_list']}},

  //Trip Protections
  { path: '/trip-protections', name: 'tripProtections.all', component: AllTripProtections
    , meta:{permits:['tripprotection_list']}},
  { path: '/trip-protections/new', name: 'tripProtections.new', component: NewTripProtection
    , meta:{permits:['tripprotection_create']}},
  { path: '/trip-protections/:id', name: 'tripProtections.edit', component: EditTripProtection
    , meta:{permits:['tripprotection_list']}},

  // Reviews
  { path: '/reviews', name: 'reviews.all', component: AllReviews
    , meta:{permits:['review_list']}},
  { path: '/reviews/:id', name: 'reviews.edit', component: EditReview
    , meta:{permits:['review_update']}},


  //Customers
  { path: '/customers', name: 'customers.all', component: AllCustomers
    , meta:{permits:['customer_list']}},
  { path: '/customers/new', name: 'customers.new', component: NewCustomer
    , meta:{permits:['customer_update']}},
  { path: '/customers/edit/:id', name: 'customers.edit', component: EditCustomer
    , meta:{permits:['customer_list']}},
    
]
let tempRoutes = [];
if (!isAffiliate) {
  tempRoutes = commonRoutes.concat(excludeRoutes);
} else {
  tempRoutes = commonRoutes;
}
const routes = tempRoutes;

export default routes
