import env from 'constants/env'
import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
}

const computed = {
  languages () {
    return _.cloneDeep(this.$store.state.multilingual.langs);
  },
}

const watch = {
  
}

const methods = {
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('faqCategories.add', {
      data: _.cloneDeep(this.form)
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.faqCategories.errors) {
        this.errors = _.cloneDeep(this.$store.state.faqCategories.errors.errors);
        toastr.error(this.$store.state.faqCategories.errors.message);
      } else {
        toastr.success('A new FAQ category has been created');
        this.$router.push({name: 'faq_categories.all'});
      }
    })
  },
}

export default {
  name: 'new-faq-category',
  components,
  computed,
  methods,
  watch,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      errors: {},
      form: {
        name: null,
        lang_id: null
      }
    }
  },
  mounted () {
  },
  created () {
    this.loading = true;
    this.$store.dispatch('multilingual.getLangs').then(() => {
      this.loading = false;
    })
    
  }
}
