import layout from 'layouts/default'
import toastr from 'toastr'
import pagination from 'components/pagination'
import confirm from 'components/modals/confirm'
import { hasPermits } from 'directives'

const filters = {

}

const directives = {
  hasPermits
}

const components = {
  layout,
  pagination,
  confirm,
}

const computed = {
  carousels () {
    return _.cloneDeep(this.$store.state.carousels.all);
  }
}

const methods = {
  loadPage (page) {
    this.$route.query.page = page;
    let data = {page, _q: this.searchKey};

    this.loading = true;

    this.$store.dispatch('carousels.getAll', data).then(() => {
      this.loading = false;
    });
  },

  search (searchKey) {
    this.searchKey = searchKey;
    this.loadPage(1);
  },

  removeCarousel (item) {
    this.$refs.confirmDelete.confirm(
    'are you sure that you want to delete this carousel?',
    () => {
      this.$set(this.loadings, item.id, true);

      this.$store.dispatch('carousels.remove', item.id).then(() => {
        this.loadings[item.id] = false;
        
        let errors;
        if (errors = this.$store.state.carousels.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },

  undoRemove (item) {
    this.$refs.confirmUndoDelete.confirm(
    'Are you sure that you want to undelete this carousel?',
    () => {
      this.$set(this.loadings, item.id, true);

      this.$store.dispatch('carousels.undoRemove', item.id).then(() => {
        this.loadings[item.id] = false;
        console.log('this.$store.state.carousels.errors', this.$store.state.carousels.errors);
        let errors;
        if (errors = this.$store.state.carousels.errors) {
          if (errors.message) {
            toastr.error(errors.message);
          } else {
            toastr.error(errors.error);
          }
        }
      });
    });
  },
}

const data = {
  loading: false,
  searchKey: null,
  toggle_modal: false,
  loadings: {},
  syncing: false,
}

export default {
  name: 'all-carousels',
  components,
  computed,
  methods,
  directives,
  filters,
  data: () => data,
  created () {
    this.loadPage(this.$route.query.page || 1);
  }
}
