var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    [
      _c("div", { staticClass: "page-header" }, [
        !_vm.loading
          ? _c("h1", [
              _c("small", [_vm._v("Location: " + _vm._s(_vm.formData.name))])
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "text-center" }, [
            _c("i", { staticClass: "fa fa-spinner fa-spin fa-3x fa-fw" })
          ])
        : _c(
            "form",
            {},
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["location_update"],
                      expression: "['location_update']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "text-right"
                },
                [
                  _vm.submit_loading
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: {
                            disabled: _vm.submit_loading,
                            type: "button"
                          }
                        },
                        [
                          _c("i", {
                            staticClass: "fa fa-spinner fa-spin fa-fw"
                          }),
                          _vm._v("\n        SAVING...\n      ")
                        ]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn btn-success",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.showSaveModal()
                            }
                          }
                        },
                        [_vm._v("\n        SAVE\n      ")]
                      )
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "margin-bottom-4x margin-top-4x" }, [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-sm-12" }, [
                    _c(
                      "ul",
                      { staticClass: "nav nav-pills" },
                      _vm._l(_vm.langs, function(language) {
                        return _c(
                          "li",
                          {
                            class: { active: language.code == _vm.lang },
                            attrs: { role: "presentation" }
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    _vm.lang = language.code
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(language.name) +
                                    "\n              "
                                )
                              ]
                            )
                          ]
                        )
                      })
                    )
                  ])
                ])
              ]),
              _vm._v(" "),
              _c(
                "tabs",
                {
                  on: {
                    tabChange: function(tab) {
                      _vm.activeTab = tab
                    }
                  }
                },
                [
                  _c(
                    "tab",
                    { attrs: { title: "General" } },
                    [
                      _c("general", {
                        attrs: { "form-data": _vm.formData, errors: _vm.errors }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { permits: ["fee_list"], title: "Fees" } },
                    [_c("fees", { attrs: { lang: _vm.lang } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { title: "Amenities" } },
                    [
                      _c("amenities", {
                        attrs: {
                          lang: _vm.lang,
                          "amenities-list": _vm.formData.amenities
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "tab",
                    { attrs: { title: "Notes" } },
                    [
                      _c("notes", {
                        attrs: {
                          lang: _vm.lang,
                          "notes-history": _vm.formData.notes
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
      _vm._v(" "),
      _c("notes-form", {
        ref: "notes",
        attrs: { "form-data": _vm.formData, lang: _vm.lang },
        on: { errorWhenSaveLocation: _vm.errorWhenSaveLocation }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-453da362", { render: render, staticRenderFns: staticRenderFns })
  }
}