var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {}, [
    _c("div", { staticClass: "row margin-top-4x" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("h3", { staticClass: "text-info" }, [_vm._v("GENERAL INFORMATION")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "locationName" } }, [
                _vm._v("Location Name")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.name,
                    expression: "form.name",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "locationName",
                  placeholder: "Location Name"
                },
                domProps: { value: _vm.form.name },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "name", $event.target.value.trim())
                  },
                  blur: function($event) {
                    _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.name
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.name[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "friendlyUrl" } }, [
                _vm._v("Friendly URL")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.url,
                    expression: "form.url",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "friendlyUrl",
                  placeholder: "location-friendly-url"
                },
                domProps: { value: _vm.form.url },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "url", $event.target.value.trim())
                  },
                  blur: function($event) {
                    _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.url
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.url[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "locationName" } }, [
                  _vm._v("Airport")
                ]),
                _vm._v(" "),
                _c("s-select", {
                  attrs: { options: _vm.ports, selected: _vm.selectedAirport },
                  on: { selectedChanged: _vm.onChangeAirport }
                }),
                _vm._v(" "),
                _vm.errors.airports
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.errors.airports[0].replace(
                              "airports",
                              "airport"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.errors["airports.0"]
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm.errors["airports.0"][0].replace(
                              "airports.0",
                              "airport"
                            )
                          ) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "locationPhone" } }, [
                _vm._v("Phone Number")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.phone,
                    expression: "form.phone",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "locationPhone",
                  placeholder: "Location Phone Number"
                },
                domProps: { value: _vm.form.phone },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "phone", $event.target.value.trim())
                  },
                  blur: function($event) {
                    _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.phone
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.phone[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("label", { attrs: { for: "locationDescription" } }, [
                  _vm._v("Location Description")
                ]),
                _vm._v(" "),
                _c("vue-html5-editor", {
                  staticStyle: { height: "100px!important" },
                  attrs: {
                    id: "locationDescription",
                    content: _vm.form.description || ""
                  },
                  on: {
                    change: function(x) {
                      return (_vm.form.description = x)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.description
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.errors.description[0]) +
                          "\n            "
                      )
                    ])
                  : _vm._e()
              ],
              1
            )
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c("h3", { staticClass: "text-info" }, [_vm._v("LOCATION ADDRESS")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Country")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.country,
                      expression: "country"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Country" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.country = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onChangeCountry
                    ]
                  }
                },
                _vm._l(_vm.countries, function(country) {
                  return _c("option", { domProps: { value: country.id } }, [
                    _vm._v(_vm._s(country.name))
                  ])
                })
              ),
              _vm._v(" "),
              _vm.errors.country_id
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.country_id[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("State")]),
              _vm._v(" "),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.state,
                      expression: "state"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: { placeholder: "Country" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.state = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                      _vm.onChangeState
                    ]
                  }
                },
                _vm._l(_vm.states, function(state) {
                  return _c("option", { domProps: { value: state.id } }, [
                    _vm._v(_vm._s(state.name))
                  ])
                })
              ),
              _vm._v(" "),
              _vm.errors.state_id
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.state_id[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("City")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.city,
                    expression: "form.city",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: { type: "text", placeholder: "City name" },
                domProps: { value: _vm.form.city },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "city", $event.target.value.trim())
                  },
                  blur: function($event) {
                    _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.city
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.city[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-md-10" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Distance From Airport")
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.trim",
                        value: _vm.form.distance_from_airport,
                        expression: "form.distance_from_airport",
                        modifiers: { trim: true }
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      placeholder: _vm.distancePlaceholder
                    },
                    domProps: { value: _vm.form.distance_from_airport },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.form,
                          "distance_from_airport",
                          $event.target.value.trim()
                        )
                      },
                      blur: function($event) {
                        _vm.$forceUpdate()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.distance_from_airport
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.errors.distance_from_airport[0]) +
                            "\n                "
                        )
                      ])
                    : _vm._e()
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-md-2" }, [
                _c("div", { staticClass: "form-group" }, [
                  _c("label", { staticClass: "control-label" }, [
                    _vm._v("Unit")
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.unit,
                          expression: "form.unit"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: { name: "unit" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "unit",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "mile" } }, [
                        _vm._v("Mile")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "km" } }, [_vm._v("KM")])
                    ]
                  ),
                  _vm._v(" "),
                  _vm.errors.unit
                    ? _c("div", { staticClass: "text-danger" }, [
                        _vm._v(
                          "\n                  " +
                            _vm._s(_vm.errors.unit[0]) +
                            "\n                "
                        )
                      ])
                    : _vm._e()
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "locationAddress" } }, [
                _vm._v("Address")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.address,
                    expression: "form.address",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "locationAddress",
                  placeholder: "Address"
                },
                domProps: { value: _vm.form.address },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "address", $event.target.value.trim())
                  },
                  blur: function($event) {
                    _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.address
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.address[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row margin-top-2x" }, [
      _c("div", { staticClass: "col-sm-6" }, [
        _c("h3", { staticClass: "text-info" }, [_vm._v("SEO DESCRIPTION")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "headTitle" } }, [
                _vm._v("Head Title")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.head_title,
                    expression: "form.head_title",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "headTitle",
                  placeholder: "Head Title"
                },
                domProps: { value: _vm.form.head_title },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "head_title", $event.target.value.trim())
                  },
                  blur: function($event) {
                    _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.head_title
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.head_title[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "headDescription" } }, [
                _vm._v("Head Description")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.head_description,
                    expression: "form.head_description",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "headDescription",
                  placeholder: "Head Description"
                },
                domProps: { value: _vm.form.head_description },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form,
                      "head_description",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.head_description
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.head_description[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "headKeywords" } }, [
                _vm._v("Head Keywords")
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.form.head_keywords,
                    expression: "form.head_keywords",
                    modifiers: { trim: true }
                  }
                ],
                staticClass: "form-control",
                attrs: {
                  type: "text",
                  id: "headKeywords",
                  placeholder: "Head Keywords"
                },
                domProps: { value: _vm.form.head_keywords },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.form,
                      "head_keywords",
                      $event.target.value.trim()
                    )
                  },
                  blur: function($event) {
                    _vm.$forceUpdate()
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.head_keywords
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.errors.head_keywords[0]) +
                        "\n            "
                    )
                  ])
                : _vm._e()
            ])
          ])
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-sm-6" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "hasPermits",
                rawName: "v-hasPermits:allow",
                value: ["super_admin"],
                expression: "['super_admin']",
                arg: "allow"
              }
            ]
          },
          [
            _c("h3", { staticClass: "text-info" }, [_vm._v("Tax")]),
            _vm._v(" "),
            _c("div", { staticClass: "form-input checkbox" }, [
              _c("label", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.collect_sj_tax,
                      expression: "form.collect_sj_tax"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.form.collect_sj_tax)
                      ? _vm._i(_vm.form.collect_sj_tax, null) > -1
                      : _vm.form.collect_sj_tax
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.form.collect_sj_tax,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 &&
                            _vm.$set(
                              _vm.form,
                              "collect_sj_tax",
                              $$a.concat([$$v])
                            )
                        } else {
                          $$i > -1 &&
                            _vm.$set(
                              _vm.form,
                              "collect_sj_tax",
                              $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                            )
                        }
                      } else {
                        _vm.$set(_vm.form, "collect_sj_tax", $$c)
                      }
                    }
                  }
                }),
                _vm._v(
                  "\n            Collect " +
                    _vm._s(_vm.channelName) +
                    " Tax\n          "
                )
              ]),
              _vm._v(" "),
              _vm.errors.collect_sj_tax
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.errors.collect_sj_tax[0]) +
                        "\n          "
                    )
                  ])
                : _vm._e()
            ])
          ]
        ),
        _vm._v(" "),
        _c("h3", { staticClass: "text-info" }, [_vm._v("OUR COMMISSION")]),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-sm-12" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("p", { staticClass: "margin-top-2x text-lead" }, [
                _vm._v("\n              Parking Commission: \n              "),
                _vm.form.parking_commission_type == "flat"
                  ? _c("strong", [
                      _vm._v(_vm._s(_vm.form.parking_commission) + " day(s)")
                    ])
                  : _c("strong", [
                      _vm._v(_vm._s(100 - _vm.form.parking_commission) + "%")
                    ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "margin-top-2x text-lead" }, [
                _vm._v("\n              Room Commission: "),
                _c("strong", [
                  _vm._v(_vm._s(100 - _vm.form.room_commission) + "%")
                ])
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "margin-top-2x text-lead" }, [
                _vm._v("\n              Bundle Commission: "),
                _c("strong", [
                  _vm._v(_vm._s(100 - _vm.form.bundle_commission) + "%")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-6edd676a", { render: render, staticRenderFns: staticRenderFns })
  }
}