export default {
  name: 'image-bg',
  template: require('./imageBg.html'),
  props: ['src', 'position'],
  mounted () {
    $(this.$refs.cont).css({
      'background-image': `url(${this.$refs.img.src})`,
      'background-size': 'cover',
      'background-position': this.position || 'center'
    })
    $(this.$refs.img).hide();
  }
}
