var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      staticClass: "text-left",
      attrs: { title: "Edit Amenity" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "hasPermits",
                      rawName: "v-hasPermits:allow",
                      value: ["amenity_update"],
                      expression: "['amenity_update']",
                      arg: "allow"
                    }
                  ],
                  staticClass: "btn btn-primary",
                  class: { disabled: _vm.files_processing },
                  attrs: { disabled: _vm.files_processing },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.submit(props)
                    }
                  }
                },
                [
                  _vm.files_processing
                    ? _c("span", [
                        _vm.files_processing
                          ? _c("i", {
                              staticClass: "fa fa-spinner fa-spin fa-fw"
                            })
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v("Save\n    ")
                ]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _c("form", [
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "display_name" } }, [
              _vm._v("Display Name")
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.form.display_name,
                  expression: "form.display_name"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                id: "display_name",
                placeholder: "Display Name"
              },
              domProps: { value: _vm.form.display_name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.form, "display_name", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _vm.errors.display_name
              ? _c("div", { staticClass: "text-danger" }, [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.errors.display_name[0]) +
                      "\n        "
                  )
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("label", [_vm._v("Choose icon to upload")]),
              _vm._v(" "),
              _c("file-upload", {
                ref: "file_upload",
                attrs: { multiple: false, accept: "image/*" },
                on: { filesChanged: _vm.iconToUpload }
              }),
              _vm._v(" "),
              _vm.errors.icon
                ? _c("div", { staticClass: "text-danger" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.errors.icon[0]) + "\n        "
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ])
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-02d9c6ee", { render: render, staticRenderFns: staticRenderFns })
  }
}