import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout
}

const computed = {
  // permissions () {
  //   return this.$store.state.roles.permissions;
  // }
}

const methods = {
  submit () {
    this.errors = {};
    this.processing = true;
    this.$store.dispatch('roles.newRole', this.form)
    .then(() => {
      this.processing = false;
      let errors = this.$store.state.roles.errors;
      if (errors) {
        if (!_.isEmpty(errors.errors)) {
          this.errors = _.cloneDeep(errors.errors);
        }
        toastr.error(errors.message);
      } else {
        this.$router.replace({name: 'roles.all'});
      }
    });
  }
}

export default {
  name: 'new-role',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      form: {
        name: null,
        description: null,
        permissions: [],
        type: 'admin'
      },
      loading: true,
      processing: false,
      errors: {},
      permissions: []
    }
  },
  created () {
    this.$store.dispatch('roles.getPermissions').then(() => {
      this.loading = false;
      this.permissions = _.cloneDeep(this.$store.state.roles.permissions);
    });
  }
}
