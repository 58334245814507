import { currencySymbol } from 'filters'


const watch = {
  selected (val) {
    try {
      if (val.id) {
        this.selectedPlan = val.id;
      }
    } catch(e) {
      this.selectedPlan = null;
    }
  },
  selectedCurrency (n, o) {
    if (n == o) return;
    this.selected_currency = n;
  }
}

const computed = {
  plans () {
    let type = this.reservationType == 'both' ? 'parking_and_room' : this.reservationType;
    let selected_currency = ""

    if (!_.isEmpty(this.location) && _.isEmpty(this.selected_currency) ) { 
      selected_currency = this.location.currency_code.toLowerCase();
    } else if (!_.isEmpty(this.location) && !_.isEmpty(this.selected_currency) ) {
      selected_currency = this.selected_currency.toLowerCase();
    }
    if (_.isEmpty(this.$store.state.tripProtections.all)) return [];
        
    return _.cloneDeep(this.$store.state.tripProtections[type] || []).map(item => {
      let price;
      if (!_.isEmpty(this.location)) {
        try {
          price = item.trip_protection_prices.find(
            x => x.currency.code.toLowerCase() == selected_currency
          ).price;
        } catch(e) {
          // statements
          price = null;
        }
        
      }
      return {
        id: item.id,
        name: item.name,
        description: item.description,
        price
      }
    });
  },

  symbol () {
    if (this.location && _.isEmpty(this.selected_currency)){
      return currencySymbol(this.location.currency_code)
    } else if (this.location && !_.isEmpty(this.selected_currency)) {
      return currencySymbol(this.selected_currency)
    }

    return "$"
  }
}

const methods = {
  selectPlan (plan) {
    
    try {
      this.selectedPlan = null;
        if (plan.id) {
          this.selectedPlan = plan.id;
        }
    } catch(e) {
      this.selectedPlan = null;
    }
    this.$emit('selectPlan', plan);
  }
}

export default {
  name: 'trip-protection-plans',
  props: ['selected', 'location', 'reservationType','selectedCurrency'],
  computed,
  methods,
  watch,
  data () {
    return {
      selectedPlan: null,
      selected_currency: this.selectedCurrency,
      freePlan: {
        price: 0,
        title: 'Free Plan',
        description: 'No, thanks'
      }
    }
  },
  created () {
    this.$store.dispatch('tripProtections.getAll', {_all: true});
    try {
      if (this.selected.id) {
        this.selectedPlan = this.selected.id;
      }
    } catch(e) {
      this.selectedPlan = null;
    }
  }
}
