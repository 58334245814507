import { http } from 'services'
import env from 'constants/env'

class StripeService {
  init (stripeAccount = 'tripteo', affiliate = null) {
    return new Promise(resolve => {
      if (isServer) resolve({stripe: {}, elements: {}})
      let stripeKey = stripeAccount == 'osp'? env.default.ospStripeKey : env.default.stripeKey;
      if (isAffiliate && affiliate) {
        stripeKey = stripeAccount == 'osp'? affiliate.usd_stripe_key : affiliate.cad_stripe_key;
      }
      let script = document.createElement('script');
      let head = document.getElementsByTagName('head')[0];
      script.src = 'https://js.stripe.com/v3/';
      script.async = true;
      script.onload = () => {
        let stripe = Stripe(stripeKey)
        let elements = stripe.elements()
        resolve({stripe, elements})
      }
      head.appendChild(script);
    });
  }
}

export default new StripeService()
