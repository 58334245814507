import env from 'constants/env'
import toastr from 'toastr'
import layout from 'layouts/default'
import {tabs, tab} from 'components/tabs'
import { hasPermits } from 'directives'
import { general } from './partials'

const components = {
  layout,
  tabs,
  tab,
  general
}

const directives = {
  hasPermits
}

const computed = {
  langs () {
    return this.$store.state.multilingual.langs;
  },
  formData () {
    let port = _.cloneDeep(this.$store.state.ports.active);
    port.translations = _.filter(port.translations, (item) => {
      if (!item.language) return false;
      return item.language.code == this.lang
    });

    if (port.translations[0])
      port = _.assign(port, port.translations[0]);

    return port;
  },

  translations () {
    return this.$store.state.ports.active.translations
  }
}

const methods = {
  onSubmitFom () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('ports.edit', {
      id: this.$route.params.id,
      data: this.formData,
      lang: this.lang
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.ports.errors) {
        this.errors = _.cloneDeep(this.$store.state.ports.errors.errors);
        toastr.error(this.$store.state.ports.errors.message);
      } else {
        toastr.success('Port has been updated');
      }
    })
  },
  getPort () {
    this.loading = true;
    this.$store.dispatch('ports.get', this.$route.params.id).then(() => {
      this.loading = false;
    });
  }
}

export default {
  name: 'edit-port',
  components,
  computed,
  methods,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      activeTab: null,
      lang: env.default.lang,
      errors: {}
    }
  },
  created () {
    this.getPort();
    this.$store.dispatch('address.getCountries');
    this.$store.dispatch('timezones.getAll');
  }
}
