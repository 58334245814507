import moment from 'moment'

const components = {
}

const computed = {
  years () {
    let ys = [];
    let now = parseInt(moment().format('YYYY'));
    for (let i = 0; i < 10; i++) {
      ys.push(now + i);
    }

    return ys;
  },
  months () {
    return {
      "January": "01",
      "February": "02",
      "March": "03",
      "April": "04",
      "May": "05",
      "June": "06",
      "July": "07",
      "August": "08",
      "September": "09",
      "October": "10",
      "November": "11",
      "December": "12"
    }
  }
}

const methods = {

}

const watch = {
  errors (n) {
    console.log('errors', n);
  }
}

export default {
  name: "view-reservation-paymet",
  props: ['errors'],
  components,
  methods,
  computed,
  watch,
  data () {
    return {
      // cardInfo: this.card,
    }
  },

  mounted () {

  },
}
