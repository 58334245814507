import _ from 'lodash'
import { http } from 'services'

// initial state
const state = () => ({
  active: {},
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {

  ['affiliates.getByHost'] ({commit, state}, host) {
    commit('CLEAR_ERRORS')
    if (!_.isEmpty(state.active)) {
      return new Promise((resolve) => resolve());
    }
    
    return (http.get(`affiliates/${host}/by-host`)).then((res) => {
      commit('GET_AFFILIATE', res);
    }).catch ((res) => {
      commit('AFFILIATES_ERROR', res);
    });
  }
}

// mutations
const mutations = {

  GET_AFFILIATE (state, res) {
    state.active = res.data;
  },

  //ERRORS
  AFFILIATES_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
