<template>
  <router-view></router-view>
</template>
<style lang="scss" src='sass/master.scss'></style>
<script>
export default {
  name: 'main-component',
  created () {
    console.log("<--- APP CREATED --->");
  }
}
</script>
