import toastr from 'toastr'
import { funcModal } from 'components/modals'

const components = {
  funcModal
}

const methods = {
  submit (email, password) {
    this.errors = {};
    this.loading = true;
    this.$store.dispatch('user.login', {email, password}).then(() => {
      this.loading = false;
      let errors = this.$store.state.user.errors;
      
      if (!_.isEmpty(errors)){
        if (!_.isEmpty(errors.error_code) && errors.error_code == "invalid_credentials") {
          this.errors = _.cloneDeep(errors.errors);
          toastr.error(errors.message);
        }
      } else {
        this.$router.push({ name: 'home'});
      }
    });
  },

  openSendEmail () {
    this.$refs.forgotPass.open();
  },

  submitSendEmail (modal) {
    this.errors = {};
    this.processing = true;
    this.$store.dispatch('user.forgotPassword', this.forgotPass.email).then(() => {
      this.processing = false;
      let errors;
      if (errors = this.$store.state.user.errors) {
        this.errors = errors.errors;
      } else {
        this.forgotPass.email = null;
        toastr.success("Please check your email");
        modal.close();
      }
    });
  }
}

export default {
  name: "login-layout",
  methods,
  components,
  data () {
    return {
      errors: {},
      forgotPass: {
        email: null
      },
      loading: false,
      processing: false
    }
  },
  created () {
  },
  mounted () {
    if (this.$route.query.source && this.$route.query.client_secret) {
      if (window.parent) {
        window.parent.setSource(this.$route.query.source);
      } else {
        toastr.error('Could not reach parent window');
      }
    }
  }
}
