import env from 'constants/env'
import toastr from 'toastr'
import layout from 'layouts/default'
import { hasPermits } from 'directives'

const directives = {
  hasPermits
}

const components = {
  layout,
}

const computed = {
  languages () {
    return _.cloneDeep(this.$store.state.multilingual.langs);
  },

  faqCategories () {
    return _.cloneDeep(this.$store.state.faqs.categories);
  }

}

const watch = {
  
}

const methods = {
  onSubmitForm () {
    this.errors = {}
    this.submit_loading = true;
    this.$store.dispatch('faqs.add', {
      data: _.cloneDeep(this.form)
    }).then(() => {
      this.submit_loading = false;
      if (this.$store.state.faqs.errors) {
        this.errors = _.cloneDeep(this.$store.state.faqs.errors.errors);
        toastr.error(this.$store.state.faqs.errors.message);
      } else {
        toastr.success('A new FAQ has been created');
        this.$router.push({name: 'faqs.all'});
      }
    })
  },
  wysiwygContentChanged: function(val) {
    this.form.answer = val
  },

  getCategories () {
    let params = {_all: true};
    if (this.language) {
      console.log('languagexxx', this.language);
      params.language = this.language;
    }
    this.$store.dispatch('faqs.getAllCategories', params);
  }

  
}

export default {
  name: 'new-faq',
  components,
  computed,
  methods,
  watch,
  directives,
  data () {
    return {
      loading: false,
      submit_loading: false,
      errors: {},
      language:null,
      form: {
        question: null,
        answer: '',
        faq_category_id: null,

      }
    }
  },
  mounted () {
  },
  created () {
    this.loading = true;
    this.$store.dispatch('multilingual.getLangs').then(() => {
      this.loading = false;
    })
  }
}
