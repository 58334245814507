var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "func-modal",
    {
      ref: "modal",
      attrs: { title: "View FAQ Category" },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function(props) {
            return [
              _c(
                "button",
                {
                  staticClass: "btn btn-default",
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      props.close()
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ]
          }
        }
      ])
    },
    [
      _c("template", { slot: "body" }, [
        _vm.faqCateogry
          ? _c(
              "table",
              {
                staticClass:
                  "table table-bordered faq-category-modal--table margin-top-2x"
              },
              [
                _c("tbody", [
                  _c("tr", [
                    _c("th", [_vm._v("Name")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.faqCateogry.name) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Language")]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.faqCateogry.language.name) +
                          "\n          "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", [_vm._v("Order")]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(_vm.faqCateogry.order_number + 1))])
                  ])
                ])
              ]
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-12619194", { render: render, staticRenderFns: staticRenderFns })
  }
}