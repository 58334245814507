import { http } from 'services'
// initial state
const state = () => ({
  langs: [],
  regions: [],
  errors: null
})

// getters
const getters = {
}

// actions
const actions = {
  ['multilingual.getLangs'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    if (state.langs.length) {
      return commit('GET_ALL_LANGS');
    }

    return (http.get('langs?_all=true')).then ((res) => {
      commit('GET_ALL_LANGS', res);
    })
    .catch ((res) => {
      commit('LANGS_ERROR', res);
    })
  },

  ['multilingual.getRegions'] ({ commit, state }) {
    commit('CLEAR_ERRORS')

    if (state.regions.length) {
      return commit('GET_ALL_LANGS');
    }

    return (http.get('regions?_all=true'))
    .then ((res) => {
      commit('GET_ALL_REGIONS', res);
    })
    .catch ((res) => {
      commit('LANGS_ERROR', res);
    })
  },
}

// mutations
const mutations = {
  GET_ALL_LANGS (state, res) {
    if (res)
      state.langs = res.data;
  },

  GET_ALL_REGIONS (state, res) {
    if (res)
      state.regions = res.data;
  },

  //ERRORS
  LANGS_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
