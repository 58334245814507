import { http } from 'services'

// initial state
const state = () => ({
  all: [],
  active: null,
  errors: null
})

// getters
const getters = {

}

// actions
const actions = {
  //get all ports (params: {_q: search name, page})
  ['faqCategories.getAll'] ({ commit, state }, params) {
    if (!params._q) params._q = null
    if (!params._with_deleted) params._with_deleted = null
    if (!params._only_deleted) params._only_deleted = null

    commit('CLEAR_ERRORS')

    return (http.get('faq_categories?_all=true&_sort=order_number', {
      params
    })).then ((res) => {
      commit('GET_ALL_FAQ_CATEGORIES', res);
    }).catch ((res) => {
      commit('FAQ_CATEGORY_ERROR', res);
    })
  },

  ['faqCategories.get'] ({commit, state}, id) {
    commit('CLEAR_ERRORS')

    return (http.get(`faq_categories/${id}`)).then ((res) => {
      commit('GET_FAQ_CATEGORY', res);
    }).catch ((res) => {
      commit('FAQ_CATEGORY_ERROR', res);
    })
  },


  //update faq
  ['faqCategories.edit'] ({dispatch, commit, state}, {id, data}) {
    commit('CLEAR_ERRORS');

    return (http.put(`faq_categories/${id}`, data)).then ((res) => {
      commit('EDIT_FAQ_CATEGORY', res);
    })
    .catch ((res) => {
      console.log(res)
      commit('FAQ_CATEGORY_ERROR', res);
    })
  },

  ['faqCategories.updateAll'] ({dispatch, commit, state}, {data}) {
    commit('CLEAR_ERRORS');
    
    return (http.post(`faq_categories/update-all?_all=true&_sort=order_number`, {items: data})).then ((res) => {
      commit('GET_ALL_FAQ_CATEGORIES', res);
    }).catch ((res) => {
      console.log(res)
      commit('FAQ_CATEGORY_ERROR', res);
    })
  },

  ['faqCategories.remove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`faq_categories/${id}`)).then ((res) => {
      commit('REMOVE_FAQ_CATEGORY', res);
    }).catch ((res) => {
      commit('FAQ_CATEGORY_ERROR', res);
    })
  },

  ['faqCategories.undoRemove'] ({commit}, id) {
    commit('CLEAR_ERRORS');

    return (http.delete(`faq_categories/${id}?_undelete=true`)).then ((res) => {
      commit('UNDO_REMOVE_FAQ_CATEGORY', res);
    }).catch ((res) => {
      commit('FAQ_CATEGORY_ERROR', res);
    })
  },

  ['faqCategories.add'] ({commit}, {data}) {
    commit('CLEAR_ERRORS');

    return (http.post(`faq_categories`, data)).then ((res) => {
      commit('NEW_FAQ_CATEGORY', res);
    }).catch ((res) => {
      commit('FAQ_CATEGORY_ERROR', res);
    })
  }
}

// mutations
const mutations = {
  GET_ALL_FAQ_CATEGORIES (state, res) {
    let sorted = _.sortBy(res.data, ['order_number']);
    state.all = sorted;
    state.all = _.cloneDeep(state.all);
  },

  REMOVE_FAQ_CATEGORY (state, res) {
    console.log('resxxx remove', res);
    let data = _.filter(state.all, (item) => item.id != res.data.id);
    state.all = _.cloneDeep(data);
  },

  UNDO_REMOVE_FAQ_CATEGORY (state, res) {
    let indx = _.findIndex(state.all, (item) => item.id == res.data.id);

    if (indx > -1) {
      state.all[indx] = _.assign(state.all[indx], res.data);
      state.all = _.cloneDeep(state.all);
    }
  },

  GET_FAQ_CATEGORY (state, res) {
    state.active = _.assign(state.active, res.data);

    state.active = _.cloneDeep(state.active);
  },

  EDIT_FAQ_CATEGORY (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  NEW_FAQ_CATEGORY (state, res) {
    state.active = _.assign(state.active, res.data);
    state.active = _.cloneDeep(state.active);
  },

  //ERRORS
  FAQ_CATEGORY_ERROR (state, err) {
    try {
      state.errors = err.response.data;
    } catch (e) {
      state.errors = err;
    }
  },

  CLEAR_ERRORS (state) {
    state.errors = null
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
